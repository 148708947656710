<template>
    <div class="NoticeDetail">
        <van-nav-bar title="通知详情" left-text="返回" left-arrow @click-left="onClickLeft" />
        <div class="notice-title">{{ notice_info.title }}</div>
        <div class="notice-content">{{ notice_info.content }}</div>
        <div class="notice-create_time">通知时间：{{ notice_info.create_time }}</div>
    </div>
</template>
<script>
import { NoticeDetail } from '@/api/home'

export default {
    name: 'NoticeDetail',
    data() {
        return {
            notice_info: [],
            notice_id: 0
        }
    },
    methods: {
        async onClickLeft() {
            history.back()
        },
        to_detail(item) {
            this.$router.push({ name: 'NoticeDetail', query: { notice_id: item.id } })
        },
        async NoticeDetail() {
            try {
                const user = JSON.parse(localStorage.getItem('user'))
                if (user && user.sid) {
                    const response = await NoticeDetail({
                        sid: user.sid,
                        id: this.notice_id,
                    })
                    this.notice_info = response.data
                    console.log(this.notice_info)
                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        }
    },
    mounted() {
        // 在组件挂载后立即获取任务数据
        this.notice_id = this.$route.query.notice_id;
        this.NoticeDetail()
    }
}
</script>

<style scoped>
.NoticeDetail {
    width: 100vw;
    height: 100vh;
    background-color: #F8F8F8;
}

.notice-title {
    width: 96vw;
    height: 20px;
    margin:  0 auto;
    padding-top: 10px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

.notice-content {
    width: 90vw;
    margin: 0 auto;
    padding-top: 10px;
    font-size: 14px;
    text-indent: 2em;
    text-align: left;
}

.notice-create_time {
    padding-top: 10px;
    font-size: 12px;
    text-align: right;
    padding-right: 20px;
}
</style>