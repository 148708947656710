<!-- src/layouts/TabBarLayout.vue -->

<template>
    <div>
        <!-- 初始状态下显示的图片，点击可切换显示 -->
        <img v-if="!is_show_ai" src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/index/wyt.gif"
            mode="widthFix" @click="change_show_ai" class="wyt" />
        <!-- 显示 AI 伴学功能的主要界面 -->
        <div class="check-main-ai" v-if="is_show_ai">
            <div class="guanbi" @click="change_show_ai">
                <van-icon name="cross" color="#606060" />
            </div>
            <img src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/index/wyt.gif" mode="widthFix"
                class="wyt-say" />
            <div class="say-text">嗨，你好，试试慧学外语这些伴学关键词</div>
            <!-- 功能选项按钮 -->
            <div class="fast-parent">
                <div class="fast-box" @click="chat('independent_writing_language')">
                    <span>
                        Expression
                    </span>
                </div>
                <div class="fast-box" @click="chat('independent_writing_content')">
                    <span>
                        Content
                    </span>
                </div>
                <div class="fast-box" @click="chat('independent_writing_structure')">
                    <span>
                        Text structure
                    </span>
                </div>
            </div>
            <!-- 输入框和录音按钮 -->
            <div class="input-box" v-show="is_speak == 1">
                <input class="question" maxlength="-1" v-model="stu_content" @input="inputText"
                    placeholder-class="placeholder" :placeholder="stu_placeholder" type="text" />
            </div>
            <div class="input-box" v-show="is_speak != 1">
                <button class="button-default"
                    :style="speaking == true ? 'background: linear-gradient(134deg, #4499ee 0%, #9ce4ff 100%);color: #fff;' : ''"
                    @touchstart="recStart" @touchend="recStop">{{ stu_placeholder }}</button>
            </div>
            <!-- 发送信息和切换语音输入的按钮 -->
            <div class="say-box" id="end" v-if="speaking === false">
                <img v-if="stu_content !== '' && is_speak === 1" @click="to_chat" class="send-img"
                    src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/send_msg.png" mode="aspectFill" />
                <img v-if="stu_content === '' && is_speak === 1" @click="chang_speak" data-is-speak="0"
                    class="other-img" src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/audio.png"
                    mode="scaleToFill" />
                <img v-if="stu_content === '' && is_speak === 0 && speaking === false" @click="chang_hand"
                    data-is-speak="1" class="other-img"
                    src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/hand.png" mode="scaleToFill" />
            </div>
        </div>
    </div>
</template>

<script>
//必须引入的核心
import Recorder from 'recorder-core'

//引入mp3格式支持文件；如果需要多个格式支持，把这些格式的编码引擎js文件放到后面统统引入进来即可
import 'recorder-core/src/engine/mp3'
import 'recorder-core/src/engine/mp3-engine'
//录制wav格式的用这一句就行
import 'recorder-core/src/engine/wav'

//可选的插件支持项，这个是波形可视化插件
import 'recorder-core/src/extensions/waveview'
//ts import 提示：npm包内已自带了.d.ts声明文件（不过是any类型）
export default {
    name: 'WriteAi',
    props: {
        resources_id: {
            type: String,
            reqired: true
        }
    },
    data() {
        return {
            listData: [],
            is_show_ai: false,
            stu_placeholder: '请输入你的疑问...',
            stu_content: '',
            is_speak: 1,
            canuse: 1,
            type: 0,
            lang_time: 0,
            speaking: false,
            recorder: null,
            mediaStream: null,
            audioChunks: [],
            audioDetails: null // 新增用于存储音频文件详细信息的状态
        }
    },
    watch: {
        '$route.path': function (newPath) {
            this.active = newPath // 路由变化时更新激活的路由
        }
    },
    methods: {
        async inputText() {
            console.log(this.stu_content)
            this.type = 0
        },
        async chat(item) {
            this.$router.push({ name: 'ChatIndex', query: { project_id: 0, is_history: 0, res_id: this.resources_id, lang_time: this.lang_time, filePath: this.filePath, type: 0, stype: item, from: 'write', chat_title: 'E-Write', system: this.subject_name, content: item.name } })
        },
        async to_chat() {
            this.$router.push({ name: 'ChatIndex', query: { project_id: 0, is_history: 0, res_id: this.resources_id, lang_time: this.lang_time, filePath: this.filePath, type: this.type, stype: 'write', from: 'write', chat_title: 'E-Write', system: this.subject_name, content: this.stu_content } })
        },
        async chang_speak() {
            await this.recOpen();
            this.is_speak = 0;
            this.stu_placeholder = "按住说话...";
            // 确保更新视图
            this.$forceUpdate();
        },

        async chang_hand() {
            this.is_speak = 1;
            this.stu_placeholder = "请输入你的疑问...";
            // 确保更新视图
            this.$forceUpdate();
        },

        change_show_ai() {
            this.is_show_ai = !this.is_show_ai
        },
        async recOpen() {
            //创建录音对象
            this.rec = Recorder({
                type: "mp3" //录音格式，可以换成wav等其他格式
                , sampleRate: 16000 //录音的采样率，越大细节越丰富越细腻
                , bitRate: 16 //录音的比特率，越大音质越好
                , onProcess: (buffers, powerLevel, bufferSampleRate) => {
                    //录音实时回调，大约1秒调用12次本回调
                    //可实时绘制波形，实时上传（发送）数据
                    if (this.wave) this.wave.input(buffers[buffers.length - 1], powerLevel, bufferSampleRate);
                }
            });

            //打开录音，获得权限
            this.rec.open(() => {
                console.log("录音已打开");
                if (this.$refs.recwave) {//创建音频可视化图形绘制对象
                    this.wave = Recorder.WaveDiv({ elem: this.$refs.recwave });
                }
            }, (msg, isUserNotAllow) => {
                //用户拒绝了录音权限，或者浏览器不支持录音
                console.log((isUserNotAllow ? "UserNotAllow，" : "") + "无法录音:" + msg);
            });
            // this.startRecognition(); // 启动语音识别
        },
        async recStart() {
            if (!this.rec) { console.error("未打开录音"); return }
            this.rec.start();
            this.stu_placeholder = '正在说话';
            this.speaking = true;
            console.log("已开始录音");
            if (this.recognition) {
                this.recognition.start(); // 启动语音识别
            }
        },
        async recStop() {
            if (!this.rec) { console.error("未打开录音"); return }
            this.rec.stop((blob, duration) => {
                //blob就是我们要的录音文件对象，可以上传，或者本地播放
                this.recBlob = blob;
                //简单利用URL生成本地文件地址，此地址只能本地使用，比如赋值给audio.src进行播放，赋值给a.href然后a.click()进行下载（a需提供download="xxx.mp3"属性）
                var localUrl = (window.URL).createObjectURL(blob);
                console.log("录音成功", blob, localUrl, "时长:" + duration + "ms");
                this.stu_placeholder = '按住说话';
                this.lang_time = (duration / 1000).toFixed()
                this.type = 1
                this.speaking = false;
                this.upload(blob);//把blob文件上传到服务器
                if (this.recognition) {
                    this.recognition.stop(); // 停止语音识别
                }
                // this.rec.close();//关闭录音，释放录音资源，当然可以不释放，后面可以连续调用start
                // this.rec = null;
            }, (err) => {
                console.error("结束录音出错：" + err);
                // this.rec.close();//关闭录音，释放录音资源，当然可以不释放，后面可以连续调用start
                // this.rec = null;
            });
        },
        startRecognition() {
            const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            if (SpeechRecognition) {
                this.recognition = new SpeechRecognition();
                // 配置和使用 recognition 对象
            } else {
                console.error('该浏览器不支持语音识别');
            }

            this.recognition.continuous = true;
            this.recognition.interimResults = true;
            this.recognition.lang = 'en-US';

            this.recognition.onresult = (event) => {
                let interimTranscript = '';
                let finalTranscript = '';

                for (let i = event.resultIndex; i < event.results.length; i++) {
                    if (event.results[i].isFinal) {
                        finalTranscript += event.results[i][0].transcript;
                    } else {
                        interimTranscript += event.results[i][0].transcript;
                    }
                }

                this.content = finalTranscript || interimTranscript;
            };

            this.recognition.onerror = (event) => {
                console.error('识别错误: ', event.error);
            };

            this.recognition.onend = () => {
                console.log('识别结束' + this.content);
                this.type = 1; //消息类型

                this.to_chat()
            };
        },
        async upload(blob) {
            try {
                const user = JSON.parse(localStorage.getItem('user'))
                if (user && user.sid) {
                    var form = new FormData();
                    form.append("file", blob, user.sid + "-" + new Date().getTime() + ".mp3"); //和普通form表单并无二致，后端接收到upfile参数的文件，文件名为recorder.mp3
                    form.append("key", "value"); //其他参数

                    var xhr = new XMLHttpRequest();
                                        // xhr.open("POST", "https://elang.zju.edu.cn:8082/mobileupload/upload");
                    xhr.open("POST", "https://zju.3dease.com/fcapi/mobileupload/upload");
                    xhr.onreadystatechange = () => {
                        if (xhr.readyState === 4) {
                            if (xhr.status === 200) {
                                // 请求成功，解析响应
                                var response = JSON.parse(xhr.responseText);
                                console.log("上传成功，服务器返回的数据:", response);
                                this.filePath = response.data.url
                                // 在这里处理服务器返回的数据
                                // this.startRecognition()
                                this.type = 1; //消息类型
                                this.chat()
                            } else {
                                // 请求失败，处理错误
                                console.error("上传失败，状态码:", xhr.status);
                                console.error("错误信息:", xhr.statusText);
                            }
                        }
                    };
                    xhr.send(form);

                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        },
        async recPlay() {
            //本地播放录音试听，可以直接用URL把blob转换成本地播放地址，用audio进行播放
            var localUrl = URL.createObjectURL(this.recBlob);
            var audio = document.createElement("audio");
            audio.controls = true;
            document.body.appendChild(audio);
            audio.src = localUrl;
            audio.play(); //这样就能播放了

            //注意不用了时需要revokeObjectURL，否则霸占内存
            setTimeout(function () { URL.revokeObjectURL(audio.src) }, 5000);
        },
    },
    mounted() {
        // 在组件挂载后立即获取任务数据
        this.recOpen();
    }
}
</script>


<style scoped>
.check-main-ai {
    width: 100vw;
    height: auto;
    max-height: 400px;
    overflow-y: auto;
    background: linear-gradient(134deg, #eff7fe, #e3f3ff);
    padding-bottom: 25px;
    position: fixed;
    bottom: 0px;
    border-radius: 40px 40px 0 0;
    z-index: 200;
}

.guanbi {
    width: auto;
    height: auto;
    position: fixed;
    right: 10px;
    text-align: center;
    padding: 8px 15px;
    font-weight: bold;
    /* background-color: rgba(236, 221, 10, 0.3); */
    border-radius: 40px;
}

.wyt-say {
    width: 75px;
    height: 75px;
    vertical-align: top;
    display: inline-block;
}

.say-text {
    width: 300px;
    height: 75px;
    vertical-align: top;
    display: inline-block;
    line-height: 60px;
    font-size: 14px;
}

.fast-parent {
    padding-left: 10vw;
}

.fast-box {
    width: 80px;
    height: 55px;
    background: #ffffff;
    border-radius: 0px 20px 0px 20px;
    border: 1px solid #acc5ff;
    margin-left: 5px;
    color: #686868;
    font-size: 12px;
    text-align: center;
    padding: 8px 9px;
    text-align: center;
    margin-bottom: 110px;
    align-items: center;
    display: flex;
    float: left;
}

.fast-box:last-child {
    margin-right: 0;
}

.input-box {
    width: 80vw;
    height: 42px;
    background: #ffffff;
    border-radius: 16px;
    position: fixed;
    bottom: 45px;
    left: 10vw;
    border: none;
}

.question {
    padding: 10px 60px 10px 15px;
    font-size: 14px;
    color: #000;
    border: none;
}

.placeholder {
    font-size: 14px;
    color: #989898;
    padding: 3px 15px;
}

.say-box {
    width: 60px;
    height: 42px;
    background: #ffffff;
    border-radius: 15px;
    position: fixed;
    bottom: 45px;
    left: 293px;
}

.say-img {
    width: 10px;
    height: 15px;
    padding: 7px 25px;
}

.send-img {
    width: 53px;
    height: 30px;
    position: fixed;
    bottom: 50px;
    left: 293px;
}

.other-img {
    width: 25px;
    height: 25px;
    position: fixed;
    bottom: 53px;
    left: 326px;
}

.button-default {
    background: #fff;
    /* color: #000000; */
    height: 42px;
    width: 323px;
    line-height: 25px;
    text-align: center;
    border-radius: 21;
    font-size: 14px;
    font-weight: bold;
    border: none;
    position: fixed;
    bottom: 45px;
    left: 10vw;
    border-radius: 16px;
}

.button-speaking {
    background: linear-gradient(134deg, #4499ee 0%, #9ce4ff 100%);
    color: #fff;
    height: 42px;
    width: 333px;
    line-height: 25px;
    /* text-align: center; */
    border-radius: 21px;
    font-size: 14px;
    font-weight: bold;
    z-index: 100;
    border: none;
    position: fixed;
    bottom: 45px;
    left: 10vw;
    border-radius: 16px;
}

.wyt {
    position: fixed;
    bottom: 60px;
    right: 5px;
    width: 75px;
    height: 75px;
}

.btn-test {
    position: absolute;
    top: 0;
}

.btn-test2 {
    position: absolute;
    left: 0;
    top: 0;
}
</style>
