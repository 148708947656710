<template>
  <div class="ai">
    <van-nav-bar title="能力自测" left-text="返回" left-arrow @click-left="onClickLeft" />
    <div class="exam-top">
      <div class="progress">
        <div class="progress-select"></div>
      </div>
      <div class="alarm-box">
        <div class="alarm-time">
          <van-count-down :time="limitTime" />
        </div>
      </div>
    </div>
    <div class="test-box">
      <div class="test-orderNo">Part{{ part }}</div>
      <div class="test-type" v-if="part == 1">听力</div>
      <div class="test-type" v-if="part == 2">阅读</div>
      <div class="test-detail" v-if="part == 1">
        <div class="detail-tltle">{{ count_load }}. <span v-if="serialNumber != ''">{{ serialNumber }}</span></div>
        <div class="praxis-info">
          <div class="quest-item">
            <div class="quest-option">
              <div class="answer-title">1</div>
              <div class="answer-desc">{{ check_short_listen[0].option1 }}</div>
            </div>
            <div class="quest-option">
              <div class="answer-title">2</div>
              <div class="answer-desc">{{ check_short_listen[0].option2 }}</div>
            </div>
            <div class="quest-option">
              <div class="answer-title">3</div>
              <div class="answer-desc">{{ check_short_listen[0].option3 }}</div>
            </div>
            <div class="quest-option">
              <div class="answer-title">4</div>
              <div class="answer-desc">{{ check_short_listen[0].option4 }}</div>
            </div>
            <div class="quest-option">
              <div class="answer-title">5</div>
              <div class="answer-desc">{{ check_short_listen[0].option5 }}</div>
            </div>
            <div class="quest-option">
              <div class="answer-title">6</div>
              <div class="answer-desc">{{ check_short_listen[0].option6 }}</div>
            </div>
            <div class="quest-option">
              <div class="answer-title">7</div>
              <div class="answer-desc">{{ check_short_listen[0].option7 }}</div>
            </div>
          </div>
          <div class="quest-item" v-for="(item, index) in check_short_listen" :key="index">
            <div class="now-check">{{ item.title }}</div>
            <div class="quest-option1" @click="select_short_listen(item, 1)">
              <div class="answer-title" :class="{ 'checked': item.is_check == '1' }">1</div>
            </div>
            <div class="quest-option1" @click="select_short_listen(item, 2)">
              <div class="answer-title" :class="{ 'checked': item.is_check == '2' }">2</div>
            </div>
            <div class="quest-option1" @click="select_short_listen(item, 3)">
              <div class="answer-title" :class="{ 'checked': item.is_check == '3' }">3</div>
            </div>
            <div class="quest-option1" @click="select_short_listen(item, 4)">
              <div class="answer-title" :class="{ 'checked': item.is_check == '4' }">4</div>
            </div>
            <div class="quest-option1" @click="select_short_listen(item, 5)">
              <div class="answer-title" :class="{ 'checked': item.is_check == '5' }">5</div>
            </div>
            <div class="quest-option1" @click="select_short_listen(item, 6)">
              <div class="answer-title" :class="{ 'checked': item.is_check == '6' }">6</div>
            </div>
            <div class="quest-option1" @click="select_short_listen(item, 7)">
              <div class="answer-title" :class="{ 'checked': item.is_check == '7' }">7</div>
            </div>
          </div>
        </div>
      </div>
      <div class="test-detail" v-if="part == 2">
        <div style="font-size: 24rpx;margin-bottom: 32rpx;">{{ context }}</div>
        <div class="detail-tltle">{{ count_load }}. <text v-if="serialNumber != ''">{{ serialNumber }}</text></div>
        <div class="praxis-info">
          <div class="quest-item">
            <div class="quest-option" bind:tap="check_short_conver">
              <div class="answer-title" :class="{ 'checked': paper_info.is_check == 'A' }">A</div>
              <div class="answer-desc">{{ paper_info.optionA }}</div>
            </div>
            <div class="quest-option" bind:tap="check_short_conver">
              <div class="answer-title" :class="{ 'checked': paper_info.is_check == 'B' }">B</div>
              <div class="answer-desc">{{ paper_info.optionB }}</div>
            </div>
            <div class="quest-option" bind:tap="check_short_conver">
              <div class="answer-title" :class="{ 'checked': paper_info.is_check == 'C' }">C</div>
              <div class="answer-desc">{{ paper_info.optionC }}</div>
            </div>
            <div class="quest-option" bind:tap="check_short_conver">
              <div class="answer-title" :class="{ 'checked': paper_info.is_check == 'D' }">D</div>
              <div class="answer-desc">{{ paper_info.optionD }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="audio-box" v-if="audio_status == 1 && part == 1">
        <img src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/audio.png" mode="widthFix" />
        <span>温馨提示：听力自动播放，只播放一次</span>
      </div>
      <div class="audio-box" v-if="audio_status == 2 && part == 1">
        <img src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/audio_finish.png" mode="widthFix" />
        <span>温馨提示：播放完毕，请点击下一题</span>
      </div>
      <div class="wall"></div>
    </div>
    <div class="btn-box">
      <div class="next-btn" @click="next_paper" v-if="count_load != 6">下一题</div>
      <div class="sumbit-btn" @click="submit_paper" v-if="count_load == 6">提交</div>
    </div>
  </div>
</template>
<script>
import { getMiniPaperByListen, getMiniPaperByRead, ListenMiniSubmit, ReadMiniSubmit } from '@/api/exam'
export default {
  name: 'Ai',
  data() {
    return {
      audio_status: 1,
      audio_url: '',
      check_short_listen: [],
      play: false,
      count_load: 0,
      serialNumber: '',
      limitTime: 1800000,
      part: 1,
      paper_info: []
    }
  },
  methods: {
    async getMiniPaperByListen() {
      try {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user && user.sid) {
          const response = await getMiniPaperByListen(user.sid)
          const data = response.data
          if (response.code == 100) {
            data.questions.forEach(item => {
              item.is_check = 0
            })
            this.audio_url = data.listeningNewConversation.audio
            this.check_short_listen = data.questions.reverse()
            this.audio_status = 1
            this.serialNumber = data.listeningNewConversation.content
            console.log(this.check_short_listen)
          }

        } else {
          console.error('用户未登录或没有找到sid')
        }
      } catch (error) {
        console.error('Login Failed:', error)
      }
    },
    async getMiniPaperByRead() {
      try {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user && user.sid) {
          const response = await getMiniPaperByRead(user.sid)
          const data = response.data
          data.questions.is_check = ''
          this.paper_info = data.questions
          this.serialNumber = data.questions.questions
          this.context = data.readingPassage.context
          this.part = 2
        } else {
          console.error('用户未登录或没有找到sid')
        }
      } catch (error) {
        console.error('Login Failed:', error)
      }
    },
    async select_short_listen(item, check_index) {
      var check_short_listen = this.check_short_listen
      var check_item = item
      check_short_listen.forEach((item) => {
        if (item.id == check_item.id) {
          item.is_check = check_index
        }
      })
      this.check_short_listen = check_short_listen
    },
    async check_short_conver(item, check_index) {
      var paper_info = this.paper_info
      paper_info.is_check = check_index
      this.paper_info = paper_info
    },
    async next_paper() {
      this.count_load = this.count_load * 1 + 1
      const user = JSON.parse(localStorage.getItem('user'))
      if (this.count_load < 4) {
        this.getMiniPaperByListen()
      } else {
        this.part = 2
        this.getMiniPaperByRead()
      }
      if (this.part == 1) {
        // 提交听力题目
        await this.submitListening(user.sid)
      } else {
        // 提交阅读题目
        await this.submitReading(user.sid)
      }

      // 确保数据已经更新
      this.$forceUpdate()
      // 确保路由参数正确


      this.$router.push({ name: 'MiniPaper', query: { paper_id: this.paper_id, count_load: this.count_load, timestamp: Date.now() } }).then(() => {
        this.$forceUpdate(); // 强制刷新组件
      })
    },
    async submitListening(sid) {
      var check_str = this.check_short_listen.map(item => ({
        question_id: item.id,
        answer: item.is_check * 1
      }))
      await ListenMiniSubmit({ sid, pc_id: this.paper_id, result: JSON.stringify({ shortConversationList: check_str }) })

    },

    async submitReading(sid) {
      var submit_data1 = {
        readingPassageList: [{
          question_id: this.paper_info.id,
          answer: this.paper_info.is_check
        }]
      }
      await ReadMiniSubmit({ sid, pc_id: this.paper_id, result: JSON.stringify(submit_data1) })

    },
    async submit_paper() {
      var submit_data1 = {
        readingPassageList: [{
          question_id: this.paper_info.id,
          answer: this.paper_info.is_check
        }]
      }
      const user = JSON.parse(localStorage.getItem('user'))
      var sid = user.sid
      const response = await ReadMiniSubmit({ sid, pc_id: this.paper_id, result: JSON.stringify(submit_data1) })
      if(response.code ==100){
        this.$router.push('/exam')
      }
    },
    async onClickLeft() {
      history.back()
    },
  },
  mounted() {
    // 在组件挂载后立即获取任务数据
    this.paper_id = this.$route.query.paper_id;
    this.count_load = this.$route.query.count_load;
    this.limitTime = this.$route.query.has_time;
    // 确保更新视图

    console.log(this.count_load)
    if (this.count_load < 4) {
      this.getMiniPaperByListen()
    } else {
      this.part = 2
      this.getMiniPaperByRead()
    }

  }
}
</script>
<style scoped>
.exam-top {
  width: 325px;
  height: 30px;
  padding: 10px 25px;
  box-shadow: 0px 2px 6px 1px rgba(80, 143, 206, 0.1);
}

.progress {
  width: 160px;
  height: 5px;
  background: #EBEBEB;
  border-radius: 3px;
  vertical-align: top;
  display: inline-block;
  margin-top: 10px;
}

.progress-select {
  height: 5px;
  background: linear-gradient(270deg, #E2CCA6 0%, #B29360 100%);
}

.alarm-box {
  width: 130px;
  height: 25px;
  vertical-align: top;
  display: inline-block;
  margin-left: 17px;
}

.alarm-icon {
  width: 15px;
  height: 25px;
  vertical-align: top;
  display: inline-block;
  padding-right: 5px;
  padding-top: 4px;
}

.alarm-time {
  /* width: 100rpx; */
  height: 25px;
  vertical-align: top;
  display: inline-block;
  font-size: 14px;
  color: #8B8B8B;
  line-height: 25px;
}

.test-box {
  width: 325px;
  height: 30px;
  padding: 10px 25px;
}

.test-orderNo {
  font-size: 26px;
  font-weight: bold;
}

.test-type {
  color: #979797;
  font-size: 16px;
}

.test-detail {
  margin-top: 20px;
}

.praxis-info {
  width: 325px;
  height: auto;
  background: #FFFFFF;
  border-radius: 0px;
  margin-top: 15px;
}

.now-check {
  width: 325px;
  background-color: aliceblue;
  padding: 7px 5px;
  margin: 5px 0;
  text-align: left;
}

.quest-option {
  width: 325px;
  margin: 7px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quest-option1 {
  width: 45px;
  margin: 7px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: top;
  display: inline-block;
}

.answer-title {
  vertical-align: top;
  display: inline-block;
  width: 35px;
  height: 35px;
  background-color: lightgray;
  border-radius: 35px;
  line-height: 35px;
  text-align: center;
}

.checked {
  background: linear-gradient(180deg, #4499EE 0%, #9CE4FF 100%);
}

.answer-desc {
  padding-left: 10px;
  font-size: 14px;
  vertical-align: top;
  display: inline-block;
  width: 300px;
}

.audio-box {
  width: 325px;
  height: 63px;
  background: #F6F6F6;
  border-radius: 10px;
}

.audio-box>img {
  width: 23px;
  height: 27px;
  padding: 18px 14px 18px 25px;
  vertical-align: top;
  display: inline-block;
}

.audio-box>span {
  line-height: 63px;
  font-size: 14px;
}

.wall {
  width: 100%;
  height: 100px;
  margin-top: 15px;
}

.btn-box {
  width: 100vw;
  height: 101px;
  position: fixed;
  bottom: 0;
}

.next-btn {
  width: 320px;
  height: 45px;
  border-radius: 23px;
  border: 1px solid #003F88;
  margin: 0 auto;
  text-align: center;
  line-height: 45px;
  color: #003F88;
  font-size: 14px;
  background-color: #FFF;
}

.sumbit-btn {
  width: 320px;
  height: 45px;
  border-radius: 23px;
  border: 1px solid #003F88;
  background-color: #003F88;
  color: #FFF;
  margin: 0 auto;
  text-align: center;
  line-height: 45px;
  font-size: 14px;
}
</style>