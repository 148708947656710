<template>
  <div class="plant">
    <van-nav-bar title="关于慧学" left-text="返回" left-arrow @click-left="onClickLeft" />
    <div class="main">
      <div class="top">
        <div class="top-title">AI赋能 创新育人</div>
        <div class="top-second-title">智慧测评、智慧伴学、自主学习、因材施教</div>
      </div>
      <div class="ptld">
        <div class="ptld-title">
          <div class="title-lable">“</div>
          <div class="title">平台亮点</div>
          <div class="title-lable">”</div>
        </div>
        <div class="ptld-item">
          <div class="ptld-item-title">
            <div class="text-item">智</div>
            <div class="text-item">慧</div>
            <div class="title-pretty">
              <img style="width: 34px;padding-top: 4px;"
                src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/mine/teach.png" mode="widthFix" />
            </div>
          </div>
          <div class="ptld-content">
            <div class="content-title">变革教学模式</div>
            <div class="content-main">
              个性教学设计：教材话题针对性适配、AI
              口语对话教学、AI写作指导、情景式远程
              教学互动、在线写作预约指导。
            </div>
          </div>
        </div>

        <div class="ptld-item">
          <div class="ptld-content1">
            <div class="content-title">赋能自主学习</div>
            <div class="content-main">
              学习路径规划：启发式学习计划、探索式知
              识图谱、个性化学习资料推荐、趣味闯关、
              AI伴学听说读写、校园社区型学习MATE。
            </div>
          </div>
          <div class="ptld-item-title">
            <div class="text-item">智</div>
            <div class="text-item">慧</div>
            <div class="title-pretty">
              <img style="width: 34px;padding-top: 4px;"
                src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/mine/learn.png" mode="widthFix" />
            </div>
          </div>

        </div>

        <div class="ptld-item">
          <div class="ptld-item-title">
            <div class="text-item">智</div>
            <div class="text-item">慧</div>
            <div class="title-pretty">
              <img style="width: 34px;padding-top: 4px;"
                src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/mine/ping.png" mode="widthFix" />
            </div>
          </div>
          <div class="ptld-content">
            <div class="content-title">改革评价方式</div>
            <div class="content-main">
              权威测评体系：新国标英语能力等级测试、
              听说读写个性化诊断报告、立体交叉式训
              练提高计划、测评统计分析。
            </div>
          </div>
        </div>

        <div class="ptld-item">
          <div class="ptld-content1">
            <div class="content-title">践行科教融合</div>
            <div class="content-main">
              数智教学研究：学生学习行为数据集、教学 行为数据集、测评考试数据分析挖掘、AI学 术探究助手。
            </div>
          </div>
          <div class="ptld-item-title">
            <div class="text-item">智</div>
            <div class="text-item">慧</div>
            <div class="title-pretty">
              <img style="width: 34px;padding-top: 4px;"
                src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/mine/yan.png" mode="widthFix" />
            </div>
          </div>

        </div>
      </div>

      <div class="dtys">
        <div class="ptld-title">
          <div class="title-lable">“</div>
          <div class="title">独特优势</div>
          <div class="title-lable">”</div>
        </div>
        <div class="dtys-content">
          <div class="dtys-lable">一个中心：</div>
          ● 以AI赋能创新育人，促进大规模因材施教全面落地为中心
          <div class="dtys-lable">两个基座：</div>
          ● AI数字化基座：浙江大学计算机系统机构实验室自研的教
          育大模型“观止大模型”，测试评分唯一接近Chat-gpt 4.0
          国内高校自研大模型，智能、安全、可靠
          ● 业务基座：浙江大学外国语学院基于新国标量表研发的英
          语等级能力测试体系，配套一体化诊断、练习题库，权威、
          领先、唯一
          <div class="dtys-lable">三个智能体：</div>
          ● 浙江大学计算机系统机构实验室OPEN BUDY模型研发的
          “智慧教AI AGENT”，为学生提供个性化的教学设计，伴
          侣式的AI口语对话、写作教学，以及情景式的听力、阅读训
          练，教学有趣、有效，让大规模因材施教成为可能
          ● 浙江大学计算机系统机构实验室OPEN BUDY模型研发的
          “智慧学 AI AGENT”，为学生提供最佳的学习路径规划，
          匹配数字人启发式的引导互动，促进学生自主探索，让学
          习更有趣、个性化
          ● 基于慧学外语自研的大数据引擎，为师校提供完善的“学
          生数据集、教学行为数据集、教材题库数据集”，配套“智
          慧研AI AGENT”，让教学和学术研究更便捷、创新
          <div class="dtys-lable">N多智能应用：</div>
          ● 智能口语对话、智能写作伴侣、智能阅读伴侣、智能听力
          伴侣、智能教学助手、智能研究助手、智能诊断测评
        </div>
      </div>

      <div class="add-our">
        <div class="ptld-title">
          <div class="title-lable">“</div>
          <div class="title">加入我们</div>
          <div class="title-lable">”</div>
        </div>
        <div class="add-our-content">
          <div class="apply-use">
            <div class="add-our-title">申请试用</div>
            <div class="add-our-desc">
              如果您对慧学外语平台有试用需求，请点击下方试用申请，
              提交表单信息后，我们会第一时间与您联系，商量试用事
              宜。
            </div>
            <div class="apply-btn">申请试用 →</div>
          </div>
          <div class="apply-zixun">
            <div class="add-our-title">合作咨询</div>
            <div class="add-our-desc">
              如果您对慧学外语平台有试用需求，请点击下方试用申请，
              提交表单信息后，我们会第一时间与您联系，商量试用事
              宜。
            </div>

            <div class="lxr">
              <div class="lxr-item">
                <div class="tea-name">浙江大学闵老师</div>
                <div class="tea-phone">0571-888888888</div>
                <image class="lxr-img" src="" mode="" />
              </div>
              <div class="lxr-item">
                <div class="tea-name">浙江大学闵老师</div>
                <div class="tea-phone">0571-888888888</div>
                <image class="lxr-img" src="" mode="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom"></div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Plant',
  data() {
    return {

    }
  },
  methods: {

    async onClickLeft() {
      history.back()
    },
  },
  mounted() {
    // 在组件挂载后立即获取任务数据

  }
}
</script>
<style scoped>
.top {
  background: url('https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/mine/ai-funeng.png');
  width: 100%;
  height: 256px;
  background-repeat: no-repeat;
  background-size: cover;
}

.top-title {
  font-size: 30px;
  color: #000;
  font-weight: bold;
  padding: 26px 0 0 33px;
}

.top-second-title {
  font-size: 14px;
  color: #000;
  padding: 8px 0 0 33px;
}

.ptld {
  width: 348px;
  height: 577px;
  background: #FFFFFF;
  border-radius: 20px;
  margin: -20px 13px 0 13px;
}

.ptld-title {
  width: 348px;
  height: 90px;
  /* padding-left: 80px; */
}

.title-lable {
  width: 40px;
  height: auto;
  vertical-align: top;
  display: inline-block;
  font-size: 35px;
  font-weight: bold;
  color: #6FDBFF;
  line-height: 100px;
  text-align: center;
}

.title {
  width: 100px;
  height: 60px;
  vertical-align: top;
  display: inline-block;
  padding: 0 5px;
  font-size: 24px;
  font-weight: bold;
  border-bottom: #6FDBFF 6px solid;
  line-height: 100px;
}

.ptld-item {
  width: 301px;
  height: 100px;
  background: #EEF8FF;
  border-radius: 10px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.ptld-item-title {
  width: 50px;
  height: 86px;
  background: #CFE8FE;
  border-radius: 10px;
  text-align: center;
  padding-top: 14px;
  vertical-align: top;
  display: inline-block;
}

.title-pretty {
  width: 50px;
  height: 36px;
  background: #003F88;
}

.ptld-content {
  vertical-align: top;
  display: inline-block;
  width: 216px;
  height: 86px;
  padding-top: 14px;
  padding-left: 18px;
}

.ptld-content1 {
  vertical-align: top;
  display: inline-block;
  width: 228px;
  height: 86px;
  padding-top: 14px;
  padding: 14px 8px 0 15px;
}

.content-title {
  font-size: 16px;
  text-align: left;
}

.content-main {
  color: #555056;
  font-size: 12px;
  padding-top: 5px;
  text-align: left;
}

.dtys {
  width: 348px;
  height: 620px;
  background: #FFFFFF;
  border-radius: 20px;
  margin: 27px 13px 26px 13px;
}

.dtys-content {
  width: 306px;
  margin: 0 auto;
  font-size: 12px;
  color: #707070;
  line-height: 20px;
  text-align: left;
}

.dtys-lable {
  font-size: 12px;
  color: #000;
  font-weight: bold;
  text-align: left;
}

.add-our {
  width: 348px;
  height: 550px;
  background: #FFFFFF;
  border-radius: 20px;
  margin: 0 13px;
}

.apply-use {
  padding: 0 23px 0 19px;
}

.add-our-title {
  font-weight: bold;
  font-size: 16px;
}

.add-our-desc {
  color: #707070;
  font-size: 12px;
  padding-top: 7px;
}

.apply-btn {
  text-align: center;
  width: 300px;
  height: 45px;
  line-height: 45px;
  color: #FFF;
  background-color: #003F88;
  border-radius: 31px;
  margin: 15px 0 0 6px;
}

.apply-zixun {
  margin-top: 39px;
  padding: 0 23px 0 19px;
}

.lxr {
  width: 280px;
  height: auto;
  margin: 26px auto;
}

.lxr-item {
  width: 140px;
  height: auto;
  font-size: 14px;
  text-align: center;
  vertical-align: top;
  display: inline-block;
}

.lxr-img {
  margin-top: 17px;
  width: 76px;
  height: 76px;
  border: 5px solid #67A9FF;
}

.bottom {
  width: 100%;
  height: 40px;
}
</style>