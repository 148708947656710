<template>
    <div class="NoticeExamList">
        <van-nav-bar title="考试通知" left-text="返回" left-arrow @click-left="onClickLeft" />
        <div class="other-notice" v-for="(item, index) in notice_list" :key="index" @click="to_detail(item)">
            <div class="notice-time">{{ item.create_time }}</div>
            <div class="notice-box">
                <div class="notice-top">
                    <div class="notice-title">{{ item.title }}</div>
                    <div :class="{ 'has-read': item.is_read == 1, 'no-read1': item.is_read == 0 }"></div>
                </div>
                <div class="notice-desc">{{ item.content }}</div>
                <div class="line"></div>
                <div class="bottom">
                    <div class="detail-btn">查看详情</div>
                    <div class="all-right1">
                        <t-icon name="chevron-right" size="18" color="#BFBFBF" data-name="chevron-right"
                            bind:click="onIconTap" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { NoticeList } from '@/api/home'

export default {
    name: 'NoticeExamList',
    data() {
        return {
            notice_list: []
        }
    },
    methods: {
        async onClickLeft() {
            history.back()
        },
        to_detail(item) {
            this.$router.push({ name: 'NoticeDetail', query: { notice_id: item.id } })
        },
        async NoticeExamList() {
            try {
                const user = JSON.parse(localStorage.getItem('user'))
                if (user && user.sid) {
                    const response = await NoticeList({
                        sid: user.sid,
                        page: 1,
                        size: 10,
                        type: 'test',
                    })
                    this.notice_list = response.data.list
                    console.log(this.notice_list)
                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        }
    },
    mounted() {
        // 在组件挂载后立即获取任务数据
        this.NoticeExamList()
    }
}
</script>

<style scoped>
.NoticeExamList {
    width: 100vw;
    height: 100vh;
    background-color: #F8F8F8;
}

.exam-notice {
    width: 330px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 5px;
    margin: 12px auto;
}

.exam-title {
    font-size: 14px;
    line-height: 40px;
    padding-left: 22px;
    vertical-align: top;
    display: inline-block;
}

.no-read {
    width: 6px;
    height: 6px;
    background: #FF3D3D;
    vertical-align: top;
    display: inline-block;
    margin: 17px 5px;
    border-radius: 6px;
}

.has-read {
    background-color: #FFF !important;
}

.all-right {
    width: 4px;
    height: 8px;
    vertical-align: top;
    display: inline-block;
    margin: 16px 5px;
    padding-left: 217px;
}

.notice-box {
    width: 330px;
    height: auto;
}

.notice-time {
    color: #BFBFBF;
    text-align: center;
    font-size: 10px;
}

.other-notice {
    padding: 10px 0;
    background-color: #FFF;
    width: 330px;
    margin: 7px auto 20px auto;
    border-radius: 10px;
}

.notice-title {
    vertical-align: top;
    display: inline-block;
    padding: 18px 0 7px 21px;
    border-radius: 6px;
    font-size: 14px;
}

.no-read1 {
    width: 6px;
    height: 6px;
    background: #FF3D3D;
    vertical-align: top;
    display: inline-block;
    margin: 27px 0 0 5px;
    border-radius: 6px;
}

.notice-desc {
    color: #888888;
    font-size: 12px;
    padding: 0 21px;
}

.line {
    width: 320px;
    height: 0px;
    border: 1px solid #E4E4E4;
    margin: 13px auto 7px auto;
}

.detail-btn {
    vertical-align: top;
    display: inline-block;
    color: #BFBFBF;
    font-size: 10px;
    padding-left: 21px;
}

.all-right1 {
    width: 4px;
    height: 8px;
    vertical-align: top;
    display: inline-block;
    margin: 3px 5px;
    padding-left: 245px;
}
</style>