<template>
    <div class="active_list">
        <van-nav-bar title="活动列表" left-text="返回" left-arrow @click-left="onClickLeft" />
        <van-tabs color="#6FDBFF">
            <van-tab title="全部">
                <div class="acitve-item" v-for="(item, index) in active_list_all" :key="index" @click="to_active(item)">
                    <van-image fit="cover" class="micra-img" :src="item.cover" />
                    <div class="micra-info">
                        <div class="micra-title">{{ item.name }}</div>
                        <div class="active-time">{{ item.start_date }}~{{ item.end_date }}</div>
                        <div class="active-status" v-if="item.attachment !== ''">已上传：作文题目</div>
                        <div class="btn-status-cancel" v-if="item.my_status == 1" @click="cancel_active(item)">取消报名</div>
                        <div class="btn-status" v-if="item.my_status == 2">已取消</div>
                        <div class="btn-status" v-if="item.my_status == 3">已结束</div>
                    </div>
                </div>
            </van-tab>
            <van-tab title="已报名">
                <div class="acitve-item" v-for="(item, index) in active_list_has" :key="index" @click="to_active(item)">
                    <van-image fit="cover" class="micra-img" :src="item.cover" />
                    <div class="micra-info">
                        <div class="micra-title">{{ item.name }}</div>
                        <div class="active-time">{{ item.start_date }}~{{ item.end_date }}</div>
                        <div class="active-status" v-if="item.attachment !== ''">已上传：作文题目</div>
                        <div class="btn-status-cancel" v-if="item.my_status == 1" @click="cancel_active(item)">取消报名</div>
                        <div class="btn-status" v-if="item.my_status == 2">已取消</div>
                        <div class="btn-status" v-if="item.my_status == 3">已结束</div>
                    </div>
                </div>
            </van-tab>
            <van-tab title="已取消">
                <div class="acitve-item" v-for="(item, index) in active_list_cancel" :key="index"
                    @click="to_active(item)">
                    <van-image fit="cover" class="micra-img" :src="item.cover" />
                    <div class="micra-info">
                        <div class="micra-title">{{ item.name }}</div>
                        <div class="active-time">{{ item.start_date }}~{{ item.end_date }}</div>
                        <div class="active-status" v-if="item.attachment !== ''">已上传：作文题目</div>
                        <div class="btn-status-cancel" v-if="item.my_status == 1" @click="cancel_active(item)">取消报名</div>
                        <div class="btn-status" v-if="item.my_status == 2">已取消</div>
                        <div class="btn-status" v-if="item.my_status == 3">已结束</div>
                    </div>
                </div>
            </van-tab>
            <van-tab title="已结束">
                <div class="acitve-item" v-for="(item, index) in active_list_finish" :key="index"
                    @click="to_active(item)">
                    <van-image fit="cover" class="micra-img" :src="item.cover" />
                    <div class="micra-info">
                        <div class="micra-title">{{ item.name }}</div>
                        <div class="active-time">{{ item.start_date }}~{{ item.end_date }}</div>
                        <div class="active-status" v-if="item.attachment !== ''">已上传：作文题目</div>
                        <div class="btn-status-cancel" v-if="item.my_status == 1" @click="cancel_active(item)">取消报名</div>
                        <div class="btn-status" v-if="item.my_status == 2">已取消</div>
                        <div class="btn-status" v-if="item.my_status == 3">已结束</div>
                    </div>
                </div>
            </van-tab>
        </van-tabs>
    </div>
</template>
<script>
import { getMyActivityList } from '@/api/user'
export default {
    name: 'ActiveList',
    data() {
        return {
            active_list_all: [],
            active_list_has: [],
            active_list_cancel: [],
            active_list_finish: [],
        }
    },

    methods: {
        async onClickLeft() {
            history.back()
        },
        async to_active(item) {
            this.$router.push({ name: 'ActiveDetail', query: { active_id: item.active_id } })
        },
        async getActiveList() {

            try {
                const user = JSON.parse(localStorage.getItem('user'))
                console.log(user)
                if (user && user.sid) {
                    const response = await getMyActivityList({ sid: user.sid, status: 0 })
                    const data = response.data
                    this.active_list_all = data
                    this.userInfo = user

                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        },
        async getActiveList1() {

            try {
                const user = JSON.parse(localStorage.getItem('user'))
                console.log(user)
                if (user && user.sid) {
                    const response = await getMyActivityList({ sid: user.sid, status: 1 })
                    const data = response.data
                    this.active_list_has = data
                    this.userInfo = user

                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        },
        async getActiveList2() {

            try {
                const user = JSON.parse(localStorage.getItem('user'))
                console.log(user)
                if (user && user.sid) {
                    const response = await getMyActivityList({ sid: user.sid, status: 2 })
                    const data = response.data
                    this.active_list_cancel = data
                    this.userInfo = user

                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        },
        async getActiveList3() {

            try {
                const user = JSON.parse(localStorage.getItem('user'))
                console.log(user)
                if (user && user.sid) {
                    const response = await getMyActivityList({ sid: user.sid, status: 3 })
                    const data = response.data
                    this.active_list_finish = data
                    this.userInfo = user

                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        },

    },
    mounted() {

        this.getActiveList()
        this.getActiveList1()
        this.getActiveList2()
        this.getActiveList3()
    }
}
</script>
<style scoped>
.acitve-item {
  width: 100vw;
  height: 127px;
  background: #FFFFFF;
  border-radius: 0px;
  margin:10px 0;
}

.micra-img {
  width: 109px;
  height: 110px;
  background: #FFFFFF;
  border-radius: 20px;
  vertical-align: top;
  display: inline-block;
  margin: 9px 11px 0 25px;
}

.micra-info {
  width: 230px;
  padding-top: 15px;
  vertical-align: top;
  display: inline-block;
}

.micra-title {
  height: 20px;
  font-size: 14px;
  font-weight: bold;
}

.active-time {
  width: 230px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  color: #979797;
}

.active-status {
  vertical-align: top;
  display: inline-block;
  line-height: 20px;
  font-size: 12px;
  font-size: 12px;
  color: #979797;
}

.btn-status-cancel {
  font-size: 12px;
  width: 81px;
  height: 29px;
  background: #003F88;
  border-radius: 15px;
  text-align: center;
  line-height: 29px;
  color: #FFF;
  margin: 7px 0 0 141px;
}
.btn-status {
  width: 81px;
  height: 29px;
  font-size: 12px;
  border-radius: 15px;
  border: 1px solid #707070;
  text-align: center;
  line-height: 29px;
  color: #000;
  margin: 7px 0 0 141px;
}
</style>