<template>
    <div class="active_list">
        <van-nav-bar title="活动列表" left-text="返回" left-arrow @click-left="onClickLeft" />
        <van-tabs v-model="active" color="#6FDBFF">
            <van-tab title="全部">
                <div class="acitve-item" v-for="(item, index) in active_list" :key="index" @click="to_active(item)">
                    <van-image fit="cover" class="micra-img" :src="item.cover" />
                    <div class="micra-info">
                        <div class="micra-title">{{ item.name }}</div>
                        <div class="active-time">
                            <div class="time-info">{{ item.start_date }}~{{ item.end_date }}</div>
                            <div class="active-status">报名中</div>
                        </div>
                    </div>
                </div>
            </van-tab>
            <van-tab title="报名中">
                <div class="acitve-item" v-for="(item, index) in active_list" :key="index" @click="to_active(item)">
                    <van-image fit="cover" class="micra-img" :src="item.cover" />
                    <div class="micra-info">
                        <div class="micra-title">{{ item.name }}</div>
                        <div class="active-time">
                            <div class="time-info">{{ item.start_date }}~{{ item.end_date }}</div>
                            <div class="active-status">报名中</div>
                        </div>
                    </div>
                </div>
            </van-tab>
            <van-tab title="已结束">
                <div class="acitve-item" v-for="(item, index) in active_list" :key="index" @click="to_active(item)">
                    <van-image fit="cover" class="micra-img" :src="item.cover" />
                    <div class="micra-info">
                        <div class="micra-title">{{ item.name }}</div>
                        <div class="active-time">
                            <div class="time-info">{{ item.start_date }}~{{ item.end_date }}</div>
                            <div class="active-status">报名中</div>
                        </div>
                    </div>
                </div>
            </van-tab>
        </van-tabs>
    </div>
</template>
<script>
import { getActiveList } from '@/api/home'
export default {
    name: 'ActiveList',
    data() {
        return {
            active_list: [],
        }
    },

    methods: {
        async onClickLeft() {
            history.back()
        },
        async to_active(item) {
            this.$router.push({ name: 'ActiveDetail', query: { active_id: item.active_id } })
        },
        async getActiveList() {
            try {
                const user = JSON.parse(localStorage.getItem('user'))
                console.log(user)
                if (user && user.sid) {
                    const response = await getActiveList(user.sid)
                    const data = response.data
                    this.active_list = data
                    this.userInfo = user

                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        },

    },
    mounted() {

        this.getActiveList()
    }
}
</script>
<style scoped>
.acitve-item {
    width: 330px;
    height: 232px;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px 1px rgba(80, 143, 206, 0.1);
    border-radius: 20px;
    margin: 10px auto;
}

.micra-img {
    width: 330px;
    height: 140px;
    background: #FFFFFF;
    border-radius: 20px 20px 0 0;
    vertical-align: top;
    display: inline-block;
}

.micra-info {
    width: 171px;
    padding-left: 14px;
    padding-top: 15px;
}

.micra-title {
    height: 30px;
    font-size: 14px;
    font-weight: bold;
}

.active-time {
    width: 302px;
    height: 32px;
    background: #F6F6F6;
    border-radius: 5px;
    line-height: 32px;
    font-size: 12px;
}

.time-info {
    width: 180px;
    vertical-align: top;
    display: inline-block;
    padding-left: 9px;
    text-align: left;
    color: #979797;
}

.active-status {
    vertical-align: top;
    display: inline-block;
    padding-left: 65px;
    color: #2FBE5A;
}
</style>