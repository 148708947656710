<template>
    <div class="MicraList">
        <div class="main">
            <van-nav-bar :title="weike_info.name" left-text="返回" left-arrow @click-left="onClickLeft" />
            <div class="video-info">
                <div class="video-box">
                    <video :src="weike_info.url" :autoplay="autoplay" :poster="weike_info.cover" object-fit="contain" />
                </div>
                <div class="micra-title">{{ weike_info.name }}</div>
                <div class="visit-info">{{ weike_info.read_num }}人正在看 | {{ weike_info.create_date }}</div>
            </div>

            <div class="micra-other">
                <div class="micra-item" style="margin-right: 10rpx;" v-for="(item, index) in weike_list"
                    @click="to_micra(item)" :key="index">
                    <div class="item-lable">{{ item.type }}</div>
                    <div class="item-title">{{ item.name }}</div>
                </div>
                <div class="all-btn">
                    <div class="all-info" bind:tap="to_all">全部</div>
                    <van-icon class="click-icon" name="arrow" size="10" color="#606060" />
                </div>
            </div>
        </div>
        <HotList />
    </div>
</template>
<script>
import { getMicraList, getMicraDetail } from '@/api/micra'
import HotList from "../../components/HotList";
export default {
    name: 'MicraList',
    components: { HotList },
    data() {
        return {
            weike_info: [],
            weike_list: [],
            autoplay: true,
            video_id: 0
        }
    },
    methods: {
        async onClickLeft() {
            history.back()
        },
        async to_micra(item) {
            console.log(item)
            var video_id = item.id
            this.video_id = video_id
            this.getDetail()
        },
        async getDetail() {
            try {
                const user = JSON.parse(localStorage.getItem('user'))
                if (user && user.sid) {
                    const response = await getMicraDetail({ sid: user.sid, id: this.video_id })
                    const data = response.data
                    this.weike_info = data;
                    this.getList()
                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        },
        async getList() {
            try {
                const user = JSON.parse(localStorage.getItem('user'))
                if (user && user.sid) {
                    const response = await getMicraList({ sid: user.sid, type: this.weike_info.type })
                    const data = response.data
                    this.weike_list = data.slice(0, 2);
                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        },
    },
    mounted() {
        // 在组件挂载后立即获取任务数据
        this.video_id = this.$route.query.video_id;
        this.getDetail()

    }
}
</script>
<style scoped>
.MicraList {
    width: 100vw;
    height: 100vh;
}

.video-box {
    width: 100vw;
    height: 251px;
    padding-top: 39px;
    background-color: #000;
}

.video-box>video {
    width: 100vw;
    height: 251px;
}

.micra-title {
    padding: 12px 0 8px 24px;
    font-size: 16px;
}

.visit-info {
    padding-left: 24px;
    color: #979797;
    font-size: 12px;
}

.micra-other {
    width: 352px;
    margin-left: 23px;
    margin-top: 5px;
    height: 51px;
}

.micra-item {
    width: 160px;
    height: 51px;
    background: #F6F6F6;
    border-radius: 5px;
    vertical-align: top;
    display: inline-block;
    padding: 10px 0;
    margin-right: 10px;
}

.item-lable {
    color: #A2A2A2;
    font-size: 12px;
    padding: 3px 0 0 15px;
}

.item-title {
    font-size: 12px;
    padding: 3px 0 0 15px;
}

.all-btn {
    width: 69px;
    height: 71px;
    position: fixed;
    top: 355px;
    left: 306px;
    background-image: url('https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/all-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.all-info {
    vertical-align: top;
    display: inline-block;
    width: 10px;
    padding: 0 5px 0 40px;
    font-size: 12px;
    padding-top: 15px;
    color: #A2A2A2;
}

.click-icon {
    vertical-align: top;
    display: inline-block;
    padding-top: 24px;
    color: #A2A2A2;
}
</style>