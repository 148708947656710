<!-- src/layouts/TabBarLayout.vue -->

<template>
    <div class="about-send">
        <div class="model-title">推荐学</div>
        <div class="model-more" @click="to_study_list(1)" data-index="1">全部</div>
        <div class="model-icon" @click="to_study_list(1)" data-index="1">
            <van-icon name="arrow" color="#606060" />
        </div>
        <div class="about-item" v-for="(item, index) in RelList" :key="index">
            <img :src="item.cover" mode="aspectFill" class="item-cover" />
            <div class="item-info">
                <div class="info-title">
                    <span v-if="item.category_id == 1">[听力]</span>
                    <span v-if="item.category_id == 2">[阅读]</span>
                    <span v-if="item.category_id == 4">[写作]</span>
                    {{ item.name }}
                </div>
                <div class="info-time">{{ item.create_time }}</div>
            </div>
            <div class="con-to-study" @click="to_praxis(item)" :data-index="item" v-if="item.status == 1">继续学习</div>
            <div class="con-to-study" @click="to_report(item)" :data-index="item" v-if="item.status == 2">已完成</div>
            <div class="con-to-study" @click="to_praxis(item)" :data-index="item" v-if="item.status == 0">进入学习</div>
        </div>
    </div>
</template>

<script>
import { getHotList, saveUserStudyResource } from '@/api/user'
export default {
    name: 'HotList',
    data() {
        return {
            RelList: [],
            active: this.$route.path, // 基于当前路径设置激活的路由,
            resource_id: this.$route.query.resource_id
        }
    },
    watch: {
        '$route.path': function (newPath) {
            this.active = newPath; // 路由变化时更新激活的路由
        }
    },
    methods: {
        async to_study_list(index) {
            this.$router.push({ name: 'StudyList', query: { now_status: index } })
        },
        async getRelList() {
            try {
                const user = JSON.parse(localStorage.getItem('user'))
                if (user && user.sid) {
                    const response = await getHotList({ sid: user.sid })
                    const data = response.data
                    this.RelList = data
                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        },
        async to_praxis(item) {
            try {
                const user = JSON.parse(localStorage.getItem('user'))
                if (user && user.sid) {
                    const response = await saveUserStudyResource({ sid: user.sid, resource_id: item.id })
                    if (response.code == 100) {
                        if (item.category_id == 1) {
                            this.$router.push({ name: 'Praxis', query: { log_id: response.data, resources_id: item.id } })
                        }
                        if (item.category_id == 2) {
                            this.$router.push({ name: 'ReadPraxis', query: { log_id: response.data, resources_id: item.id } })
                        }
                        if (item.category_id == 4) {
                            this.$router.push({ name: 'WritePraxis', query: { log_id: response.data, resources_id: item.id } })
                        }
                    }

                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        }
    },
    mounted() {
        // 在组件挂载后立即获取任务数据
        this.getRelList()
    }
}
</script>

<style scoped>
.tab-bar-layout {
    position: relative;
    height: 100%;
}

.about-send {
    width: 330px;
    height: auto;
    margin: 28px auto 0 auto;
    text-align: left;
}

.title {
    font-size: 16px;
    padding-bottom: 7px;
}

.about-item {
    width: 100%;
    height: 85px;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px 1px rgba(80, 143, 206, 0.1);
    border-radius: 20px;
    margin: 10px 0;
}

.item-cover {
    vertical-align: top;
    display: inline-block;
    width: 58px;
    height: 58px;
    border-radius: 10px;
    margin: 14px 6px 0 12px;
}

.item-info {
    width: 180px;
    vertical-align: top;
    display: inline-block;
    padding-top: 21px;
}

.info-title {
    font-size: 14px;
    font-weight: bold;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.info-time {
    color: #979797;
    font-size: 12px;
    padding-top: 15px;
}

.con-to-study {
    width: 60px;
    height: 85px;
    vertical-align: top;
    display: inline-block;
    line-height: 85px;
    font-size: 12px;
    color: #003F88;
    text-align: center;
}

.model-more {
    vertical-align: top;
    display: inline-block;
    width: 200px;
    text-align: right;
    font-size: 12px;
}

.model-icon {
    vertical-align: top;
    display: inline-block;
}

.model-title {
    vertical-align: top;
    display: inline-block;
    width: 110px;
    text-align: left;
    font-size: 16px;
}

/* 可以在这里添加 TabBar 的样式 */
</style>