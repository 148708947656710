<template>
  <div id="app">

    <router-view />
  </div>
</template>

<script>
import Vue from 'vue';
import 'vant/es/toast/style';

import { Tabbar, TabbarItem, Icon, Popup, Search, Tab, Tabs, Progress, NavBar, Swipe, SwipeItem, Image, Uploader, NoticeBar, CountDown, PullRefresh  } from 'vant';

Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Icon);
Vue.use(Popup);
Vue.use(Search);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Progress);
Vue.use(NavBar);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Image);
Vue.use(Uploader);
Vue.use(NoticeBar);
Vue.use(CountDown);
Vue.use(PullRefresh);

export default {
  data() {
    return {
      active: 0,
    };
  },
}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}



#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
