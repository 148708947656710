<template>
  <div class="praxis">
    <van-nav-bar title="听力练习" left-text="返回" left-arrow @click-left="onClickLeft" />
    <van-tabs v-model="activeTitle" color="#6FDBFF">
      <van-tab>
        <template #title>做题 </template>
        <van-tabs v-model="active" color="#6FDBFF" scrollspy sticky>
          <van-tab v-for="(item, index) in jobList" :key="index">
            <template #title> {{ index + 1 }}题 </template>
            <div class="praxis-item">
              <div class="praxis-title">{{ index + 1 }}题</div>
              <div class="praxis-desc">
                <div class="wrap-text" v-html="item.title"></div>
              </div>
              <div class="praxis-info">
                <div class="answer" v-for="(itemChild, indexChild) in item.optionsArray" :key="indexChild">
                  <div :class="{ 'checked': itemChild.is_check == 1 }" class="answer-title"
                    @click="check_answer(index, indexChild)">{{ itemChild.label }}</div>
                  <div class="answer-desc">{{ itemChild.title }}</div>
                </div>
              </div>
            </div>
          </van-tab>
        </van-tabs>
        <div class="wall"></div>
        <div class="praxis-bottom">
          <div class="new-audio">
            <audio ref="audioPlayer" @timeupdate="updateTime" @loadedmetadata="loadMetadata" @canplay="canPlay"
              :src="src">
            </audio>
            <img v-if="audio_status == 1"
              src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/listen/start.png" @click="audioPlay"
              mode="" class="start-img" />
            <img v-if="audio_status == 2"
              src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/listen/zanting.png" @click="audioPause"
              mode="" class="start-img" />
          </div>
          <div class="audio-info">
            <div class="progress">
              <van-progress :percentage="percentage" track-color="#E3E3E3" color="#6EE6FF" :show-pivot="false" />
            </div>
            <div class="now-time">{{ currentTime }}</div>
          </div>
          <div class="answer-has">
            <div class="jindu"> 进度({{ has_answer }}/{{ jobList.length }})</div>
            <div class="btn-ok-cn" @click="change_card">提交</div>
          </div>
        </div>
        <van-popup v-model="show" position="bottom" style="border-radius: 30px 30px 0 0;">
          <div class="card-title">答题卡</div>
          <div class="main-answer-card">
            <div :class="{ 'card-item-empty': item.is_check == 0 }" class="card-item" v-for="(item, index) in jobList"
              :key="index">
              {{ index + 1 }}
            </div>
          </div>
          <div class="btn-cancel" @click="change_card">取消</div>
          <div class="btn-ok" @click="to_submit">确定</div>

        </van-popup>
      </van-tab>
      <van-tab>
        <template #title>原文 </template>
        <div class="wrap-text" v-html="resources.resources.content"></div>
      </van-tab>
    </van-tabs>
    <ListenPraxisAi :resources_id="resources_id"  allow="microphone" />
  </div>
</template>
<script>
import { getJobList, submitData, updateUserStudy } from '@/api/listen'
import ListenPraxisAi from "@/components/ListenPraxisAi";
export default {
  components: { ListenPraxisAi },
  name: 'Praxis',
  data() {
    return {
      show: false,
      activeTitle: 0,
      active: 0,
      resources: [],
      jobList: [],
      src: [],
      log_id: 0,
      resources_id: 0,
      percentage: 0,
      has_answer: 0,
      currentTime: '0:00',
      audio_status: 1
    }
  },
  methods: {
    async onClickLeft() {
      history.back()
    },
    async getJobList() {
      try {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user && user.sid) {
          const response = await getJobList({ sid: user.sid, resource_id: this.resources_id })
          const data = response.data
          var jobList = response.data.jobList;
          // console.log(jobList)
          jobList.forEach(item => {
            item.is_check = 0;
            if (item.optionsArray == null) {
              item.optionsArray = JSON.parse(item.options)
              console.log(item.optionsArray)
            }
            item.optionsArray.forEach(child => {
              child.is_check = 0;
            });
          });
          this.resources = data
          console.log(this.resources.resources.content)
          this.jobList = jobList
          this.src = data.resources.file_path
        } else {
          console.error('用户未登录或没有找到sid')
        }
      } catch (error) {
        console.error('Login Failed:', error)
      }
    },
    async check_answer(itemIndex, indexChild) {
      var jobList = this.jobList
      console.log(itemIndex)
      jobList.forEach((val, key) => {
        if (key == itemIndex) {
          val.is_check = 1;
          val.optionsArray.forEach((child, index) => {
            if (indexChild == index) {
              child.is_check = 1
            } else {
              child.is_check = 0
            }
          });
        }
      })
      var has_answer = 0
      jobList.forEach(val => {
        val.optionsArray.forEach(child => {
          if (child.is_check == 1) {
            has_answer++
          }
        });

      })
      this.jobList = jobList
      this.has_answer = has_answer
    },
    async change_card() {
      this.show = !this.show
      console.log(this.jobList)
    },
    async to_submit() {
      var JobList = this.jobList;
      var submit_data = [];
      const user = JSON.parse(localStorage.getItem('user'))
      JobList.forEach((item) => {
        var user_answer = ''; // 用户选择的答案
        var is_right = 0;
        item.optionsArray.forEach((child) => {
          if (child.is_check == 1) {
            user_answer = child.label; // 保存用户选择的答案
            if (item.options_answer === child.label) {
              is_right = 1; // 设置正确答案标记
            }
          }
        });

        submit_data.push({
          user_id: user.sid,
          job_id: item.id,
          answer: user_answer, // 使用用户选择的答案
          is_right: is_right,
          resource_id: item.resource_id
        });
      });

      console.log(submit_data);

      submit_data = JSON.stringify(submit_data)
      const response = await submitData({
        params: submit_data
      })
      if (response.code == 100) {
        const resp = await updateUserStudy({ id: this.log_id })
        if (resp.code == 100) {
          this.$router.push({ name: 'Report', query: { resource_id: this.resources_id } })
        }
      }
    },
    async audioPlay() {
      this.audio_status = 2
      this.$refs.audioPlayer.play();
    },
    async audioPause() {
      this.$refs.audioPlayer.pause();
      this.audio_status = 1
    },
    updateTime(event) {
      const audio = event.target;
      this.currentTime = this.formatTime(audio.currentTime);
      console.log(audio.currentTime)
      console.log(audio.duration)
      this.percentage = Math.floor((audio.currentTime / audio.duration) * 100);
    },
    loadMetadata(event) {
      const audio = event.target;
      this.duration = this.formatTime(audio.duration);
    },
    canPlay() {
      console.log('Audio can play through.');
    },
    formatTime(seconds) {
      const m = Math.floor(seconds / 60) % 60;
      const s = Math.floor(seconds) % 60;
      return `${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
    },
  },
  mounted() {
    // 在组件挂载后立即获取任务数据
    // 在组件挂载后立即获取任务数据
    this.log_id = this.$route.query.log_id;
    this.resources_id = this.$route.query.resources_id;
    this.getJobList()
  }
}
</script>
<style scoped>
.praxis-item {
  width: 90%;
  height: auto;
  margin: 0 auto;
  text-align: left;
}

.praxis {
  padding-bottom: 200px;
}

.praxis-title {
  font-size: 14px;
  color: #030303;
  padding: 35px 0 10px 0;
  /* width: 50px; */
  vertical-align: top;
  display: inline-block;
}

.praxis-desc {
  font-size: 14px;
  width: 90%;
  height: auto;
  padding: 10px 0;
  vertical-align: top;
  display: inline-block;
  font-weight: normal;
}

.praxis-info {
  width: 90%;
  height: auto;
  background: #FFFFFF;
}

.answer {
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.answer-title {
  vertical-align: top;
  display: inline-block;
  width: 35px;
  height: 35px;
  background-color: lightgray;
  border-radius: 35px;
  line-height: 35px;
  text-align: center;
}

.checked {
  background: linear-gradient(180deg, #4499EE 0%, #9CE4FF 100%);
}

.answer-desc {
  padding-left: 10px;
  font-size: 14px;
  vertical-align: top;
  display: inline-block;
  width: 300px;
}

.wrap-text {
  width: 90%;
  height: auto;
  margin: 0 auto;
  text-align: left;
  padding: 10px 0;
}

.praxis-bottom {
  width: 100%;
  height: 126px;
  background: #FFFFFF;
  box-shadow: 0px -2px 6px 1px rgba(80, 143, 206, 0.1);
  border-radius: 0px;
  position: fixed;
  bottom: 0;
}

.new-audio {
  width: 50px;
  height: 50px;
  vertical-align: top;
  display: inline-block;
  padding-left: 15px;
}

.start-img {
  width: 50px;
  height: 50px;
  vertical-align: top;
  display: inline-block;

}

.audio-info {
  width: 300px;
  height: 50px;
  vertical-align: top;
  display: inline-block;
  line-height: 50px;
  text-align: left;
  color: #979797;
  font-size: 12px;
}

.progress {
  width: 225px;
  vertical-align: top;
  display: inline-block;
  padding-top: 22px;
}

.now-time {
  vertical-align: top;
  display: inline-block;
  line-height: 50px;
}

.answer-has {
  width: 325px;
  height: 60px;
  padding-left: 25x;
}

.jindu {
  width: 205px;
  vertical-align: top;
  display: inline-block;
  line-height: 60px;
  font-size: 14px;
  color: #979797;
  /* text-align: left; */

}

.btn-ok-cn {
  margin-top: 12px;
  width: 118px;
  height: 36px;
  line-height: 36px;
  background: #003F88;
  border-radius: 23px;
  /* margin: 0 auto; */
  text-align: center;
  color: #FFF;
  vertical-align: top;
  display: inline-block;
}

.card-title {
  width: 100vw;
  height: 75px;
  text-align: center;
  line-height: 75px;
  border-bottom: #E4E4E4 1px solid;
  margin: 0 auto;
  color: #000000;
  font-weight: bold;
  font-size: 16px;
}

.main-answer-card {
  padding: 30px 40px 80px 40px;
  text-align: left;
}

.card-item {
  width: 34px;
  height: 34px;
  background: linear-gradient(180deg, #4499EE 0%, #9CE4FF 100%);
  border-radius: 34px;
  line-height: 34px;
  text-align: center;
  color: #FFF;
  vertical-align: top;
  display: inline-block;
  margin: 10px 5px;
}

.card-item-empty {
  background: #CBCBCB;
}

.btn-cancel {
  position: absolute;
  top: 26px;
  right: 25px;
}

.btn-ok {
  width: 288px;
  height: 45px;
  background: #003F88;
  border-radius: 23px;
  margin: 0 auto;
  line-height: 45px;
  text-align: center;
  color: #FFF;
  margin: 0 auto;
}
</style>