<template>
  <div class="exam">
    <div class="head">
      <div class="head-left">
        <div class="head-left-title">测试</div>
        <div class="head-left-content">上次测试：{{ exam_list.paper_name }} </div>
      </div>

      <div class="head-right">
        <img src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/exam/study.png" mode="widthFix"
          class="head-right-img" />
      </div>
    </div>
    <div class="content">

      <div class="content-top">
        <!-- 测试模块开始 -->
        <div class="content-top-title">
          测试模块
        </div>
        <div class="exam-model">
          <div class="model-left" @click="PaperNotice" data-index="1">
            <div class="model-left-title-cn">能力自测</div>
            <div class="model-left-title-en">Ability self-test</div>
            <div class="lable-model">
              <div class="lable-model-item">
                <div class="lable-model-item-cicle"></div>
                <div class="lable-model-item-title">准确性高</div>
              </div>
              <div class="lable-model-item">
                <div class="lable-model-item-cicle"></div>
                <div class="lable-model-item-title">人工智能分析</div>
              </div>
              <div class="lable-model-item">
                <div class="lable-model-item-cicle"></div>
                <div class="lable-model-item-title">强大算法支撑</div>
              </div>
            </div>
            <div class="next-exam" v-if="self_exam == 0">下次测试：{{ self_exam_time }}</div>
          </div>
          <div class="model-right">
            <div class="right-top" data-index="1">
              <div class="right-top-title-cn">入学测试</div>
              <div class="right-top-title-en">Entrance test</div>
              <div class="model-right-lable-model">
                <div class="lable-model-item" style="padding-left: 50rpx;">
                  <div class="lable-model-item-cicle" style="background-color: #DB8D7A;"></div>
                  <div class="lable-model-item-title" style="color: #DB8D7A;">因材施教</div>
                </div>
                <div class="lable-model-item" style="padding-left: 50rpx;">
                  <div class="lable-model-item-cicle" style="background-color: #DB8D7A;"></div>
                  <div class="lable-model-item-title" style="color: #DB8D7A;">人工智能分析</div>
                </div>
                <div class="lable-model-item" style="padding-left: 50rpx;">
                  <div class="lable-model-item-cicle" style="background-color: #DB8D7A;"></div>
                  <div class="lable-model-item-title" style="color: #DB8D7A;">强大算法支撑</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 测试模块结束 -->
      <!-- 最新测试 -->
      <div class="learn-model" style="height: 200rpx;">
        <div class="model-start">
          <div class="model-title">最新测试</div>
          <div class="model-more" bind:tap="to_all_exam">全部</div>
          <div class="model-icon">
            <van-icon name="arrow" color="#606060" />
          </div>
          <div class="exam-item">
            <div class="exam-item-info">
              <div class="exam-item-info-title">{{ exam_list.paper_name }}</div>
              <div class="exam-item-info-time">{{ exam_list.create_time }}</div>
            </div>
            <div class="btn-exam">待阅卷</div>
          </div>
        </div>
      </div>
      <!-- 最新测试 -->
      <!-- 推荐学 -->
      <HotList />
      <!-- 推荐学 -->

      <div class="wall"></div>
    </div>
  </div>
</template>
<script>
import { getPaperList, StartMiniPaper } from '@/api/exam'
import { HotResouceList } from '@/api/home'
import HotList from "@/components/HotList";
export default {
  components: { HotList },
  name: 'Exam',
  data() {
    return {
      exam_list: [],
    }
  },
  methods: {
    async getPaperList() {
      try {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user && user.sid) {
          const response = await getPaperList(user.sid)
          const data = response.data
          this.exam_list = data[data.length - 1]
        } else {
          console.error('用户未登录或没有找到sid')
        }
      } catch (error) {
        console.error('Login Failed:', error)
      }
    },
    async getHotResouceList() {
      try {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user && user.sid) {
          const response = await HotResouceList(user.sid)
          const data = response.data
          this.HotResouceList = data
        } else {
          console.error('用户未登录或没有找到sid')
        }
      } catch (error) {
        console.error('Login Failed:', error)
      }
    },
    async PaperNotice() {
      try {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user && user.sid) {
          const response = await StartMiniPaper(user.sid)
          const data = response.data
          console.log(data)
          var timestamp = Date.parse(new Date());
          if (data.is_allow == 0 && data.count == 6) {
            alert('不在时间范围')
            return
          }
          if (data.end_time > timestamp || data.is_allow == 1) {
            if (data.row == null) {
              this.$router.push({ name: 'PaperNotice', query: { paper_id: data.row.id, count_load: this.count_load, has_time: 1800000 } })
            }else{
              var has_time = data.end_time - timestamp
              this.$router.push({ name: 'PaperNotice', query: { paper_id: data.row.id, count_load: this.count_load, has_time: has_time } })
            }
          }
           //超时
           if (data.end_time < timestamp && data.is_allow == 1) {
            alert(timestamp)
            this.$router.push({ name: 'PaperNotice', query: { paper_id: data.row.id, count_load: this.count_load, has_time: 1800000 } })
          }
        } else {
          console.error('用户未登录或没有找到sid')
        }
      } catch (error) {
        console.error('Login Failed:', error)
      }


    }
  },
  mounted() {
    // 在组件挂载后立即获取任务数据
    this.getPaperList()
    this.getHotResouceList()
  }
}
</script>
<style scoped>
.head {
  width: 100%;
  height: 180px;
  background-color: #E2F2FF;
  text-align: left;
}

.head-left {
  width: 169px;
  padding: 49px 0 0 25px;
  vertical-align: top;
  display: inline-block;
}

.head-left-title {
  width: 169px;
  color: #030303;
  font-weight: bold;
  font-size: 22px;
  height: 29px;
  line-height: 29px;
  margin-bottom: 5px;
}

.head-left-content {
  width: 169px;
  font-size: 12px;
  color: #707070;
  /* height: 16px; */
  line-height: 20px;
}

.head-right {
  padding-top: 53rpx;
  width: 154px;
  height: 106px;
  vertical-align: top;
  display: inline-block;
}

.head-right-img {
  width: 154px;
  height: 106px;
}

.content {
  width: 100%;
  height: auto;
  border-radius: 32px;
  background-color: #FFF;
  position: relative;
  top: -30px;
}

.content-top {
  width: 330px;
  height: auto;
  padding-top: 30px;
  margin: 0 auto;
  font-size: 16px;
}

.content-top-title {
  font-size: 16px;
  padding-bottom: 16px;
}

.exam-model {
  width: 330px;
  height: 189px;
}

.model-left {
  vertical-align: top;
  display: inline-block;
  width: 128px;
  height: 145px;
  background-size: cover;
  background-image: url('https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/exam/zsy-bg.png');
  padding: 22px 16px;
}

.model-left-title-cn {
  font-weight: bold;
  font-size: 16px;
  color: #5847A0;
  margin-bottom: 5px;
}

.model-left-title-en {
  font-size: 14px;
  color: #7662C9;
}

.next-exam {
  text-align: center;
  font-size: 10px;
  color: #5847A0;
  font-weight: 400;
  line-height: 20px;
}

.lable-model {
  margin: 30px 0 0 33px;
}

.lable-model-item {
  width: 100px;
  height: 20px;
  text-align: left;
}

.lable-model-item-cicle {
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #7662C9;
  vertical-align: top;
  display: inline-block;
  margin: 6px 5px 0 0;
}

.lable-model-item-title {
  color: #7662C9;
  vertical-align: top;
  display: inline-block;
  font-size: 12px;
  height: 16px;
  line-height: 16px;
}

.model-right {
  vertical-align: top;
  display: inline-block;
  width: 161px;
  height: 189px;
  background-size: cover;
  margin-left: 9px;
  background-image: url('https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/exam/rxcsbg.png');
}

.model-right-lable-model {
  margin: 30px 0 0 50px;
}

.right-top-title-cn {
  color: #884722;
  font-size: 16px;
  font-weight: bold;
  padding: 22px 0 0 16px;
}

.right-top-title-en {
  color: #884722;
  font-size: 14px;
  padding: 5px 0 0 16px;
}


.model-start {
  width: 330px;
  height: 40px;
  margin: 0 auto;
  line-height: 40px;
  margin-top: 30px;
}

.model-title {
  vertical-align: top;
  display: inline-block;
  width: 110px;
  text-align: left;
  font-size: 16px;
}

.model-more {
  vertical-align: top;
  display: inline-block;
  width: 200px;
  text-align: right;
  font-size: 12px;
}

.model-icon {
  vertical-align: top;
  display: inline-block;
}

.learn-model {
  width: 330px;
  height: auto;
  margin: 0 auto;
}

.exam-item {
  width: 308px;
  height: 36px;
  background: #F6F6F6;
  border-radius: 20px;
  padding: 14px 0 13px 22px;
}

.exam-item-info {
  width: 250px;
  height: 36px;
  vertical-align: top;
  display: inline-block;
}

.exam-item-info-title {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 5px;
  height: 16px;
  text-align: left;
}

.exam-item-info-time {
  font-size: 12px;
  line-height: 15px;
  color: #a1a1a1;
  text-align: left;
}

.btn-exam {
  width: 58px;
  vertical-align: top;
  display: inline-block;
  font-size: 12px;
  color: #D13515;
}

.learn-model {
  width: 330px;
  height: auto;
  margin: 0 auto;
}

.model-start {
  width: 330px;
  height: auto;
  margin: 0 auto;
  line-height: 40px;
  margin-top: 30px;
}

.model-title {
  vertical-align: top;
  display: inline-block;
  width: 110px;
  text-align: left;
  font-size: 16px;
}

.model-more {
  vertical-align: top;
  display: inline-block;
  width: 200px;
  text-align: right;
  font-size: 12px;
}

.model-icon {
  vertical-align: top;
  display: inline-block;
}

.learn-item {
  width: 330px;
  height: 85px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 1px rgba(80, 143, 206, 0.1);
  border-radius: 20px 20px 20px 20px;
  margin-bottom: 11px;
}

.learn-img {
  vertical-align: top;
  display: inline-block;
  width: 58px;
  height: 58px;
  margin: 14px 10px 13px 13px;
}

.learn-item-info {
  vertical-align: top;
  display: inline-block;
  width: 170px;
  height: 50px;
  line-height: 25px;
  padding: 10px 0;
}

.learn-item-title {
  width: 150px;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 20px;
}

.learn-item-update-time {
  font-size: 12px;
  color: #979797;
  padding-top: 15px;
}

.btn-learn {
  vertical-align: top;
  display: inline-block;
  color: #003F88;
  font-size: 12px;
  height: 85px;
  line-height: 85px;
}
</style>