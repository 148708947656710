import request from './index'

// 获取统计信息
export function getUserStudy(sid) {
    return request({
        url: '/user/getUserStudy',
        method: 'get',
        params: { sid }
    })
}

// 获取统计信息
export function getDateList(data) {
    return request({
        url: '/user_sign/getDateList',
        method: 'post',
        data
    })
}

// 获取首页快捷菜单
export function getMenuList() {
    return request({
        url: '/subject/getCategoryList',
        method: 'get',
    })
}


// 获取首页正在学
export function getNewUserResourceList(sid) {
    return request({
        url: '/resources/getNewUserResourceList',
        method: 'get',
        params: { sid }
    })
}

// 获取首页推荐学
export function HotResouceList(sid) {
    return request({
        url: '/resources/getHotUserResourceList',
        method: 'get',
        params: { sid }
    })
}

export function UploadAudio(data) {
    return request({
        url: '/mobileupload/upload',
        method: 'post',
        data
    })
}

// activity/listall
// 获取活动列表
export function getActiveList(sid) {
    return request({
        url: '/activity/listall',
        method: 'get',
        params: { sid }
    })
}

// 获取活动详情
export function getActiveDetail(data) {
    return request({
        url: '/activity/activitydetail',
        method: 'post',
        data
    })
}
// http://localhost:8090/zhedaAi/chat?sid=1&system=假如你是一名语文老师&content=说说三国演义的读后感
export function toChat(data) {
    return request({
        // url: '/zhedaAi/speak_fetch',
        // url: '/banxue/doubao_speak_fetch',
        url: '/zhedaOutAi/speak_fetch',
        method: 'post',
        data
    })
}

export function en2cn(data) {
    return request({
        // url: '/zhedaAi/chat',
        url: '/zhedaAi/en2cn',
        method: 'post',
        data
    })
}

export function NoticeList(data) {
    return request({
        url: '/notice/getList',
        method: 'get',
        params: data
    })
}

export function NoticeDetail(data) {
    return request({
        url: '/notice/get',
        method: 'get',
        params: data
    })
}


export function text2voice(data) {
    return request({
        url: '/tts/text2voice',
        method: 'post',
        data
    })
}

export function longtext2voice(data) {
    return request({
        url: '/tts/longtext2voice',
        method: 'post',
        data
    })
}

export function longtextSearch(data) {
    return request({
        url: '/tts/longtext_search',
        method: 'post',
        data
    })
}

export function voice2text(data) {
    return request({
        url: '/asr/voice2text',
        method: 'post',
        data
    })
}

export function voice2textSearch(data) {
    return request({
        url: '/asr/voice2text_search',
        method: 'post',
        data
    })
}