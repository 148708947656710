import request from './index'

// 获取微课列表
export function getMicraList(params) {
  return request({
    url: '/weike/listbytype',
    method: 'get',
    params: params
  })
}


// 获取微课详情信息
export function getMicraDetail(params) {
    return request({
      url: '/weike/getdetail',
      method: 'get',
      params: params
    })
  }