<template>
    <div class="learn">
        <van-nav-bar title="主题阅读" left-text="返回" left-arrow @click-left="onClickLeft" />
        <img class="top-img" src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/listen/2.png" mode="" />
        <div class="main-top">
            <div class="reback" bind:tap="go_back">
                <van-icon name="arrow-left" color="#606060" />
            </div>
            <div class="pagetitle">主题阅读</div>
        </div>
        <div class="main-content">
            <div class="content-mian">
                <div class="listen-title">[阅读]{{ subject_name }}</div>
                <div class="listen-item" v-for="(item, index) in resources" :key="index" @click="to_praxis(item)">
                    <div class="listen-left">
                        <div class="listen-name">{{ item.name }}</div>
                        <div class="listen-time">{{ item.create_time }}</div>
                    </div>
                    <div class="listen-right">
                        <div class="listen-status" v-if="item.status == 0">未完成</div>
                        <div class="listen-status listen-status3" v-if="item.status == 1">上次未学完</div>
                        <div class="listen-status listen-status3" v-if="item.status == 2">已完成</div>
                    </div>
                </div>
            </div>
        </div>
        <ReadLearnAi allow="microphone" />
    </div>
</template>
<script>
import { getResources, saveUserStudyResource } from '@/api/listen'
import ReadLearnAi from "@/components/ReadLearnAi";
export default {
    components: { ReadLearnAi },
    name: 'Learn',
    data() {
        return {
            subject_id: 0,
            subject_name: '',
            resources: []
        }
    },
    methods: {
        async onClickLeft() {
            history.back()
        },
        async getList() {
            try {
                const user = JSON.parse(localStorage.getItem('user'))
                if (user && user.sid) {
                    const response = await getResources({ sid: user.sid, subject_id: this.subject_id })
                    const data = response.data
                    this.resources = data
                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        },
        async to_praxis(item) {
            try {
                const user = JSON.parse(localStorage.getItem('user'))
                if (user && user.sid) {
                    const response = await saveUserStudyResource({ sid: user.sid, resource_id: item.id })
                    const data = response.data
                    this.$router.push({ name: 'ReadPraxis', query: { log_id: data, resources_id: item.id } })
                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        }
    },
    mounted() {
        // 在组件挂载后立即获取任务数据
        this.subject_id = this.$route.query.subject_id;
        this.subject_name = this.$route.query.subject_name;
        this.getList()
    }
}
</script>
<style scoped>
.top-img {
    width: 100vw;
    height: 425px;
    position: absolute;
    top: 0;
    left: 0;
}

.main-top {
    width: 100vw;
    height: 425px;
    position: absolute;
    top: 0;
    left: 0;
    text-align: left;
}

.reback {
    padding: 20px 0 0 12px;
    vertical-align: top;
    display: inline-block;
}

.pagetitle {
    padding: 20px 0 0 150px;
    vertical-align: top;
    display: inline-block;
    font-size: 16px;
    color: #030303;
}

.main-content {
    width: 100vw;
    height: 532px;
    background: #FFFFFF;
    border-radius: 32px;
    position: relative;
    top: 252px;
}

.content-mian {
    width: 90vw;
    height: 532px;
    margin: 0 auto;
}

.listen-title {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    padding-top: 23px;
    text-align: center;
}

.listen-item {
    width: 100%;
    border-bottom: solid 1px #E4E4E4;
    padding: 10px 0;
}

.listen-left {
    vertical-align: top;
    display: inline-block;
    width: 210px;
}

.listen-name {
    font-size: 14px;
    font-weight: bold;
    margin: 16px 0 8px 0;
}

.listen-time {
    font-size: 12px;
}

.listen-right {
    width: 100px;
    text-align: right;
    vertical-align: top;
    display: inline-block;
}

.listen-status {
    padding-top: 16px;
    font-size: 14px;
    color: #919191;
}

.listen-status3 {
    color: #51BF23;
}

.listen-status1,
.listen-status2,
.listen-status0 {
    color: #919191;
}
</style>