<template>
  <div class="home">
    <div class="main">
      <img src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/collect/login.png" mode="widthFix"
        class="login-top" />
      <div class="login-box">
        <div class="account">
          <div class="lable-name">学号</div>
          <div class="input-box">
            <input type="text" bindinput='getInputValue' v-model="user_name" />
          </div>
        </div>
        <div class="password">
          <div class="lable-name">姓名</div>
          <div class="input-box">
            <input type="text" bindinput='getInputValue' v-model="user_pwd" />
          </div>
        </div>
      </div>

      <div class="login-btn" @click="handleLogin">一键登录</div>
      <div style="text-align: center;width: 100%;height: 20px;font-size: 12px;">以学号，姓名为账号密码，登录开启自己的学习提升之旅</div>
      <!-- <div class="check-arggen" @click="agree">
        <div v-if="checked" class="is_checked"></div>
        <div v-else class="check-arggen-div"></div>
        <span>同意并遵守隐私政策及用户服务协议</span>
      </div> -->
    </div>
  </div>
</template>

<script>
import { login, getUserSign } from '@/api/user'

export default {
  name: 'Home',
  data() {
    return {
      user_name: '',
      user_pwd: '',
      checked: true,
    }
  },
  methods: {
    async handleLogin() {
      try {
        const response = await login({ user_no: this.user_name, name: this.user_pwd })
        if(response.code !==100){
          alert('账号或密码错误')
          return
        }
        // 在 localStorage 中存储用户信息
        localStorage.setItem('user', JSON.stringify(response.data))
        this.userSign(response.data.sid)
        // 跳转到另一个页面
        this.$router.push('/home')
      } catch (error) {
        console.error('Login Failed:', error)
      }
    },
    async agree() {
      console.log(this.checked)
      this.checked = !this.checked

    },
    async userSign(sid) {
      const now = new Date()
      var day = now.getDate()
      var now_month = now.getMonth() + 1;
      if (now_month < 10) {
        now_month = '0' + now_month
      }
      if (day < 10) {
        day = '0' + day
      }
      let now_date = now.getFullYear() + '-' + now_month + '-' + day
      await getUserSign({ sid: sid, dateline: now_date })
    },
  }
}
</script>

<style scoped>
.login-top {
  width: 100vw;
  height: 367px;
}

.login-box {
  width: 250px;
  height: auto;
  margin: 0 auto;
  color: #B4B4B4;
  padding-top: 20px;
}

.account,
.password {
  border-bottom: 1px solid #B4B4B4;
  padding: 10px 6px;
  text-align: left;
}

input {
  border: none;
}

.lable-name {
  width: 50px;
  vertical-align: top;
  display: inline-block;
}

.input-box {
  width: 150px;
  vertical-align: top;
  display: inline-block;
  padding-left: 6px;
}


.login-btn {
  width: 288px;
  height: 45px;
  background: #003F88;
  border-radius: 23px;
  color: #FFF;
  margin: 32px auto 19px auto;
  font-size: 14px;
  text-align: center;
  line-height: 45px;
}

.check-arggen {
  /* width: 414rpx; */
  height: 25px;
  font-size: 12px;
  color: #4A4A4A;
  position: fixed;
  bottom: 49px;
  left: 84px;
  line-height: 15px;
}


.check-arggen-div {
  width: 15px;
  height: 15px;
  display: block;
  border: 1px solid #bebebe;
  border-radius: 15px;
  margin-right: 5px;
  position: relative;
  float: left;
}

.is_checked {
  width: 15px;
  height: 15px;
  display: block;
  border-radius: 15px;
  position: relative;
  background-color: #1A72B2;
  border: 1px solid #1A72B2 !important;
}
</style>