<!-- src/layouts/TabBarLayout.vue -->

<template>
    <div class="tab-bar-layout">
      <!-- 主内容区域 -->
      <router-view></router-view>
  
      <!-- 底部 TabBar -->
      <van-tabbar v-model="active" @change="onTabChange">
        <van-tabbar-item
          :icon="getIcon('/home')"
          replace
          to="/home">首页</van-tabbar-item>
        <van-tabbar-item
          :icon="getIcon('/exam')"
          replace
          to="/exam">测试</van-tabbar-item>
        <van-tabbar-item
          :icon="getIcon('/ai')"
          replace
          to="/ai">AI伴学</van-tabbar-item>
        <van-tabbar-item
          :icon="getIcon('/plant')"
          replace
          to="/plant">计划</van-tabbar-item>
        <van-tabbar-item
          :icon="getIcon('/mine')"
          replace
          to="/mine">我的</van-tabbar-item>
      </van-tabbar>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        active: this.$route.path // 基于当前路径设置激活的路由
      }
    },
    watch: {
      '$route.path': function(newPath) {
        this.active = newPath; // 路由变化时更新激活的路由
      }
    },
    methods: {
      getIcon(route) {
        return this.active === route 
          ? `https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/menu_tab/${route.split('/').pop()}-check.png` 
          : `https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/menu_tab/${route.split('/').pop()}.png`;
      },
      onTabChange(route) {
        this.$router.push(route);
      }
    }
  }
  </script>
  
  <style scoped>
  .tab-bar-layout {
    position: relative;
    height: 100%;
  }
  
  /* 可以在这里添加 TabBar 的样式 */
  </style>
  