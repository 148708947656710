<template>
    <div class="active_detail">
        <div class="main">
            <div class="main-top">
                <van-nav-bar title="活动详情" left-text="返回" left-arrow @click-left="onClickLeft" />
            </div>
            <div class="swiper-info">
                <van-swipe class="my-swipe" :autoplay="3000" lazy-render>
                    <van-swipe-item>
                        <van-image fit="contain" :src="active_info.cover" />
                    </van-swipe-item>
                </van-swipe>
            </div>
            <div class="content">
                <div class="top-content" bind:tap="to_list">
                    <img class="all-icon"
                        src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/active/all.png"
                        mode="widthFix" />
                    <div class="all-desc" @click="all_active">全部活动</div>
                    <van-icon name="arrow" class="all-chevron-right" color="#606060" />
                </div>
                <div class="active-name">{{ active_info.name }}</div>
                <div class="active-time">{{ active_info.start_date }}-{{ active_info.end_date }}</div>
                <div class="active-status">
                    <div class="user-list">
                        <img src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/mine/default_header.png"
                            mode="widthFix" class="user-header" />
                        <img src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/mine/default_header.png"
                            mode="widthFix" class="user-header" />
                        <img src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/mine/default_header.png"
                            mode="widthFix" class="user-header" />
                        <img src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/mine/default_header.png"
                            mode="widthFix" class="user-header" />
                        <img src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/mine/default_header.png"
                            mode="widthFix" class="user-header" />
                    </div>
                    <div class="now-status">仅剩{{ active_info.total_num - active_info.attend_num }}名额</div>
                    <div class="now-status-box">
                        <div class="now-status-progress">
                            <div class="has-add" style="width: 10%;"></div>
                        </div>
                        <div class="baifenbi">{{ active_info.attend_num }}/{{ active_info.total_num }}</div>
                    </div>
                </div>
                <div class="active-add">
                    <div class="add-item">
                        <img class="add-user"
                            src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/active/user.png"
                            mode="widthFix" />
                        <div class="add-item-info">参与人群</div>
                        <div class="add-desc">本科生</div>
                    </div>
                    <div class="in-line"></div>
                    <div class="add-item">
                        <img class="add-user"
                            src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/active/user.png"
                            mode="widthFix" />
                        <div class="add-item-info">参与方式</div>
                        <div class="add-desc">上传文件</div>
                    </div>
                </div>
                <div class="active-detail">
                    <div class="lable">活动详情：</div>
                    <div class="active-desc">
                        {{ active_info.content }}
                    </div>
                </div>

                <div class="add-btn" @click="onVisibleChange">报名</div>
            </div>
        </div>
        <van-popup v-model="show" style="border-radius: 20px;">
            <div class="check-main">
                <div class="popup-title">报名确认</div>
                <div class="btn-cancel" @click="onVisibleChange">
                    <van-icon name="arrow" color="#606060" />
                </div>
                <div class="user-info">
                    <div class="info-item">
                        <div class="info-lable">姓名</div>
                        <div class="info-detail">{{ userInfo.name }}</div>
                    </div>
                    <div class="info-item">
                        <div class="info-lable">学号</div>
                        <div class="info-detail">{{ userInfo.userno }}</div>
                    </div>
                    <div class="info-item">
                        <div class="info-lable">联系方式</div>
                        <div class="info-detail">
                            <input type="text" v-model="userPhone" placeholder="填入你的联系方式" name="" id="" />
                        </div>
                    </div>
                    <div class="info-item">
                        <div class="info-lable">邮箱</div>
                        <div class="info-detail">
                            <input type="text" placeholder="填入你的电子邮箱" v-model="userEmail" name="" id="" />
                        </div>
                    </div>

                </div>
                <div class="fujian">
                    <van-uploader :after-read="afterRead">
                        <div class="fujian-chirld">
                            <img src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/write/upload.png"
                                mode="" class="upload-img" />
                            <div
                                style="float: right;padding-right: 15px;line-height: 70px;font-size: 24rpx;color: #9D9D9D;">
                                上传附件</div>
                        </div>
                    </van-uploader>
                </div>
                <div class="btn-ok" @click="submit_info">确认无误，提交</div>
            </div>
        </van-popup>
    </div>
</template>
<script>
import { getActiveDetail } from '@/api/home'
import { Submitactivity } from '@/api/user'
export default {
    name: 'ActiveDetail',
    data() {
        return {
            active_id: 0,
            active_info: [],
            show: false,
            userInfo: [],
            filePath: '',
            userPhone: '',
            userEmail: ''
        }
    },

    methods: {
        async onVisibleChange() {
            this.show = !this.show
        },
        async onClickLeft() {
            history.back()
        },
        async all_active() {
            this.$router.push({ name: 'ActiveList' })
        },
        async afterRead(file) {
            console.log(file)
            try {
                const user = JSON.parse(localStorage.getItem('user'))
                if (user && user.sid) {
                    var form = new FormData();
                    form.append("file", file.file, user.sid + "-" + new Date().getTime() + file.file.name); //和普通form表单并无二致，后端接收到upfile参数的文件，文件名为recorder.mp3
                    var xhr = new XMLHttpRequest();
                    xhr.open("POST", "https://zju.3dease.com/fcapi/mobileupload/upload");
                    xhr.onreadystatechange = () => {
                        if (xhr.readyState === 4) {
                            if (xhr.status === 200) {
                                // 请求成功，解析响应
                                var response = JSON.parse(xhr.responseText);
                                console.log("上传成功，服务器返回的数据:", response);
                                this.filePath = response.data.url

                            } else {
                                // 请求失败，处理错误
                                console.error("上传失败，状态码:", xhr.status);
                                console.error("错误信息:", xhr.statusText);
                            }
                        }
                    };
                    xhr.send(form);
                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }

        },
        async submit_info() {
            this.show = false
            try {
                const user = JSON.parse(localStorage.getItem('user'))
                this.userInfo = user
                if (user && user.sid) {
                    const response = await Submitactivity({
                        sid: user.sid,
                        activity_id: this.active_id,
                        name: user.name,
                        user_no: user.userno,
                        phone: this.userPhone,
                        email: this.userEmail,
                        attachment: this.filePath
                    })
                    const data = response.data
                    if (data.code == 100) {

                        // 确保更新视图
                        this.$forceUpdate();
                        this.getActiveDetail()
                    }
                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        },
        async getActiveDetail() {
            try {
                const user = JSON.parse(localStorage.getItem('user'))
                this.userInfo = user
                console.log(user)
                if (user && user.sid) {
                    const response = await getActiveDetail({ sid: user.sid, id: this.active_id })
                    const data = response.data
                    this.active_info = data
                    this.userInfo = user

                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        },

    },
    mounted() {
        // 在组件挂载后立即获取任务数据
        this.active_id = this.$route.query.active_id;
        this.getActiveDetail()
    }
}
</script>
<style scoped>
/* otherPage/pages/active/detail.wxss */
.main-top {
    width: 100vw;
    height: 125px;
    position: fixed;
    top: 0;
    z-index: 100;
}

.reback {
    padding: 56px 0 0 32px;
    vertical-align: top;
    display: inline-block;
}

.pagetitle {
    padding: 56px 0 0 100px;
    vertical-align: top;
    display: inline-block;
    font-size: 32rpx;
    color: #030303;
}

.swiper-info {
    width: 100vw;
    height: 252px;
    position: fixed;
}

.swiper-info>image {
    width: 100vw;
    height: 252px !important;
}

.content {
    width: 100vw;
    height: 586px;
    background-image: url(https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/active/2.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    top: 226px;
}

.top-content {
    margin-left: 275px;
}

.all-icon {
    width: 15px;
    height: 12px;
    padding: 9px 5px 0 0;
    vertical-align: top;
    display: inline-block;
}

.all-desc {
    vertical-align: top;
    display: inline-block;
    color: #606060;
    font-size: 12px;
    padding-top: 6px;
}

.all-chevron-right {
    vertical-align: top;
    display: inline-block;
    color: #606060;
    padding-top: 7px;
}

.active-name {
    font-size: 20px;
    font-weight: bold;
    padding-left: 26px;
    padding-top: 15px;
    text-align: left;
}

.active-time {
    color: #808080;
    font-size: 12px;
    padding-left: 26px;
    padding-top: 6px;
    text-align: left;
}

.active-status {
    width: 325px;
    height: 28px;
    margin: 13px auto;
}

.user-list {
    width: 110px;
    height: 26px;
    vertical-align: top;
    display: inline-block;
    margin-right: 5px;
    overflow: hidden;
}

.user-header {
    width: 26px;
    height: 26px;
    background: #FFFFFF;
    border: 1px solid #707070;
    border-radius: 26px;
    margin-left: -8px;
}

.user-header:first-child {
    margin-left: 0;
}

.now-status {
    width: 100px;
    height: 26px;
    vertical-align: top;
    display: inline-block;
    font-size: 12px;
    color: #979797;
    line-height: 26px;
    margin-right: 5px;
}

.now-status-box {
    vertical-align: top;
    display: inline-block;
    width: 105px;
    height: 26px;
    /* margin-top: 21rpx; */
}

.now-status-progress {
    width: 105px;
    height: 5px;
    background: #EBEBEB;
    border-radius: 3px;
}

.has-add {
    height: 5px;
    background: linear-gradient(42deg, #4499EE 0%, #9CE4FF 100%);
    border-radius: 3px;
}

.baifenbi {
    color: #979797;
    font-size: 12px;
    text-align: right;
}

.active-add {
    width: 330px;
    height: 64px;
    background: #F8F8F8;
    border-radius: 10px;
    margin: 16px auto;
}

.add-item {
    padding: 7px 0 7px 15px;
}

.add-user {
    vertical-align: top;
    display: inline-block;
    width: 13px;
    height: 11px;
    padding-right: 5px;
    padding-top: 5px;
}

.add-item-info {
    vertical-align: top;
    display: inline-block;
    font-size: 14px;
}

.add-desc {
    color: #808080;
    vertical-align: top;
    display: inline-block;
    font-size: 14px;
    width: 60px;
    text-align: right;
    padding-left: 169px;
}

.in-line {
    width: 320px;
    height: 1px;
    background-color: #E4E4E4;
    margin-left: 5px;
}

.active-detail {
    width: 330px;
    height: auto;
    margin: 0 auto;
}

.lable {
    font-size: 14px;
    margin-bottom: 5px;
    text-align: left;
}

.active-desc {
    font-size: 14px;
    color: #808080;
    text-align: left;
}

.add-btn {
    width: 288px;
    height: 45px;
    background: #003F88;
    border-radius: 23px;
    position: fixed;
    bottom: 19px;
    left: 43px;
    text-align: center;
    line-height: 45px;
    color: #FFF;
    font-size: 14px;
}

.check-main {
    width: 300px;
    height: 367px;
    border-radius: 20px;
    background: linear-gradient(180deg, #E2F2FF 0%, rgba(255, 255, 255, 0) 100%);
}

.popup-title {
    font-size: 16px;
    font-weight: bold;
    color: #003F88;
    padding: 17px 0 0 31px;
}

.btn-cancel {
    width: 18px;
    height: 18px;
    text-align: center;
    position: absolute;
    right: 18px;
    top: 18px;
    margin-left: 22px;
}

.user-info {
    width: 230px;
    height: auto;
    padding-left: 35px;
    margin-top: 35px;
}

.info-item {
    width: 240px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
}

.info-lable {
    width: 60px;
    height: 25px;
    vertical-align: top;
    display: inline-block;
}

.info-detail {
    width: 140px;
    padding-left: 10px;
    height: 25px;
    vertical-align: top;
    display: inline-block;
    text-align: left;
}

.info-detail>input {
    width: 140px;
    height: 25px;
    border: none;
}

.fujian {
    width: 260px;
    height: 70px;
    background: #F6F6F6;
    border-radius: 10px;
    margin: 20px auto;
}


.fujian-chirld {
    width: 200px;
    height: 60px;
    /* margin-left: 15px; */
}

.upload-img {
    width: 17px;
    height: 13px;
    position: relative;
    left: 28px;
    top: 25px;
}

.btn-ok {
    width: 250px;
    height: 39px;
    border-radius: 20px;
    color: #FFF;
    background-color: #003F88;
    text-align: center;
    line-height: 39px;
    margin: 0 25px;
}
</style>