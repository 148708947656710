<template>
  <div class="ai">
    <van-nav-bar title="能力自测" left-text="返回" left-arrow @click-left="onClickLeft" />

    <div class="main-content">
      <div class="content-title">考试说明</div>
      <div class="paper-desc">
        <div>
          1、本次测试限时 30 分钟，在测试过程中，
          中途不允许退出。<br>
          2、此次测试共涵盖听力、阅读两个部分。每部分
          的题目只能依序作答，既不可跳过，也不能回退。<br>
          3、听力部分的每段语音仅会朗读一遍，请务必保
          证您的手机未处于静音状态，且音量已调整至适宜
          大小。<br>
          4、在正式测试开始之前，您可以点击下方的语音
          图标来测试手机音量。<br>
          5、倘若您已做好准备，请点击“开始测试”。
        </div>
      </div>
      <div class="audio-test">
        <audio ref="audioPlayer" @timeupdate="updateTime" @loadedmetadata="loadMetadata" @canplay="canPlay"
          src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/test.mp3">
        </audio>
        <img src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/listen/last.png" class="next-img" />
        <img v-if="audio_status == 1"
          src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/listen/start.png" @click="audioPlay"
          class="start-img" />
        <img v-if="audio_status == 2"
          src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/listen/zanting.png" @click="audioPause"
          class="start-img" />
        <img src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/listen/next.png" class="next-img" />
      </div>
      <div class="start-test" @click="to_paper">开始测试</div>
    </div>
  </div>
</template>
<script>
import { StartMiniPaperTo } from '@/api/exam'
export default {
  name: 'Ai',
  data() {
    return {
      audio_status: 1,
      play: false,
      count_load: 0
    }
  },
  methods: {
    async to_paper() {
      try {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user && user.sid) {
          const response = await StartMiniPaperTo(user.sid)
          const data = response.data
          this.$router.push({ name: 'MiniPaper', query: { paper_id: data, count_load: 1, has_time: 1800000 } })
        } else {
          console.error('用户未登录或没有找到sid')
        }
      } catch (error) {
        console.error('Login Failed:', error)
      }


    },
    async audioPlay() {
      this.audio_status = 2
      this.$refs.audioPlayer.play();
    },
    
    async audioPause() {
      this.$refs.audioPlayer.pause();
      this.audio_status = 1
    },
    updateTime(event) {
      const audio = event.target;
      this.currentTime = this.formatTime(audio.currentTime);
      console.log(audio.currentTime)
      console.log(audio.duration)
      this.percentage = Math.floor((audio.currentTime / audio.duration) * 100);
    },
    loadMetadata(event) {
      const audio = event.target;
      this.duration = this.formatTime(audio.duration);
    },
    canPlay() {
      console.log('Audio can play through.');
    },
    formatTime(seconds) {
      const m = Math.floor(seconds / 60) % 60;
      const s = Math.floor(seconds) % 60;
      return `${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
    },
  },
  mounted() {
    // 在组件挂载后立即获取任务数据

  }
}
</script>
<style scoped>
.main-content {
  width: 325px;
  height: auto;
  margin: 0 auto;
  text-align: left;
}

.content-title {
  font-size: 26px;
  font-weight: bold;
}

.paper-desc {
  color: #979797;
  font-size: 14px;
  line-height: 30px;
}

.audio-test {
  width: 115px;
  height: 50px;
  margin: 30px auto 80px auto;
}

.start-img {
  width: 50px;
  height: 50px;
  vertical-align: top;
  display: inline-block;
  margin: 0 12px;
}

.next-img {
  width: 20px;
  height: 20px;
  vertical-align: top;
  display: inline-block;
  padding-top: 13px;
}

.start-test {
  width: 320px;
  height: 45px;
  background: #003F88;
  border-radius: 23px;
  position: fixed;
  bottom: 47px;
  color: #FFFFFF;
  left: 30px;
  text-align: center;
  line-height: 45px;
}
</style>