<template>
  <div class="mine">
    <div class="head">
      <div class="head-left">
        <div class="head-left-title">个人中心</div>
        <div class="head-left-content"></div>
      </div>
    </div>
    <div class="mine-info">
      <div class="stu-info">
        <img src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/mine/default_header.png" mode="widthFix"
          class="user-header" />
        <div class="stu-detail">
          <div class="stu-name">{{ userInfo.name }}</div>
          <div class="stu-Id">学号：{{ userInfo.userno }}</div>
        </div>
        <div class="sys-setting">
          <!-- <img src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/mine/sys.png" mode="aspectFill" class="sys-setting-img" /> -->
          <img src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/mine/setting.png"
            style="width: 50rpx;height: 44rpx;margin-top: -5rpx;" mode="aspectFill" class="sys-setting-img" />
          <div class="stu-status">
            提升计划已制定
            <van-icon name="arrow" color="#606060" />
          </div>
        </div>
      </div>
      <!-- 
      <div class="mine-list">
        <div class="list-item" bind:tap="to_new_words">
          <div class="item-num">99+</div>
          <div class="item-name">生词本</div>
        </div>
        <div class="list-item" bind:tap="to_correction_notebook">
          <div class="item-num">3</div>
          <div class="item-name">错题本</div>
        </div>
        <div class="list-item">
          <div class="item-num">{{ has_count }}%</div>
          <div class="item-name">任务进度</div>
        </div>
        <div class="list-item">
          <div class="item-num">3</div>
          <div class="item-name">成就勋章</div>
        </div>
      </div> -->
    </div>
    <div class="gongneng">
      <div class="gongneng-list">
        <!-- <div class="gongneng-item" bind:tap="to_collect">
          <img src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/mine/collect.png" mode="widthFix"
            class="gongneng-item-img" />
          <div class="gongneng-item-name">收藏夹</div>
        </div>
        <div class="gongneng-item">
          <img src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/mine/exam_score.png" mode="widthFix"
            class="gongneng-item-img" />
          <div class="gongneng-item-name">考试成绩</div>
        </div> -->
        <div class="gongneng-item" @click="to_active">
          <img src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/mine/mine-active.png" mode="widthFix"
            class="gongneng-item-img" />
          <div class="gongneng-item-name">我的活动</div>
        </div>
        <div class="gongneng-item" bind:tap="to_active">
          <div class="gongneng-item-name"></div>
        </div>
        <div class="gongneng-item" bind:tap="to_active">
          <div class="gongneng-item-name"></div>
        </div>
      </div>
    </div>
    <div class="radar">
      <div class="radar-top">
        <img src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/mine/level3.png" mode="widthFix"
          class="level" />
        <div class="wall-level">|</div>
        <div class="level-desc">初级，搞定日常对话</div>
      </div>
      <div class="ec-box">
        <div id="main" class="main-ec"></div>
      </div>
      <div class="change-lable">
        <div class="lable-item">
          <div class="cicle cicle-check"></div>
          <div class="lable-item-name">我的水平</div>
        </div>
        <div class="lable-item">
          <div class="cicle"></div>
          <div class="lable-item-name">目标水平</div>
        </div>
      </div>

      <div class="btn-learn">
        <div class="rsjy">
          提升建议：你的语法，口语，发音多项能力薄弱，
          建议学习以下课程及资料。
        </div>
        <div class="to-kecheng">查看详情</div>
      </div>
    </div>


    <div class="about" @click="to_about">
      <img class="about-img" mode="widthFix"
        src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/mine/about.png" />
      <span style="color: #888;padding-left: 20rpx;font-size: 28rpx;">关于慧学</span>
    </div>
    <div class="wall"></div>
  </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
  name: 'Mine',
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem('user')),
      has_count: 0,
    }
  },
  methods: {
    async myChart() {
      var myChart = echarts.init(document.getElementById('main'));
      // 绘制图表
      let option = {
        radar: [{
          indicator: [
            { text: '听力', max: 7 },
            { text: '阅读', max: 7 },
            { text: '写作', max: 7 },
            { text: '口语', max: 7 }
          ],
          radius: 65,
          shape: 'circle' // 将最外层变成圆形
        }],
        series: [{
          type: 'radar',
          tooltip: {
            trigger: 'item'
          },
          areaStyle: {}, // 默认的区域样式
          data: [
            {
              value: [1, 2, 3, 4],
              name: '我的水平',
              itemStyle: { // 数据点的样式
                color: '#2A63FF', // 点的颜色，
                opacity: 0,

              },
              lineStyle: { // 线条的样式
                color: 'rgb(188,228,255)' // 线条的颜色
              },
              areaStyle: { // 区域的样式
                color: '#2A63FF' // 填充的颜色
              }
            },
            {
              value: [5, 5, 5, 5],
              name: '目标水平',
              itemStyle: { // 数据点的样式
                color: '#2A63FF', // 点的颜色，
                opacity: 0,

              },
              lineStyle: { // 线条的样式
                color: 'rgb(188,228,255,0.42)' // 线条的颜色
              },
              areaStyle: { // 区域的样式
                color: 'rgb(188,228,255,0.42)' // 填充的颜色
              }
            },
            {
              value: [7, 7, 7, 7],
              name: '目标水平',
              itemStyle: { // 数据点的样式
                color: '#565656', // 点的颜色
                opacity: 0,
              },
              lineStyle: { // 线条的样式
                color: 'rgb(255,255,255,0.1)' // 线条的颜色
              },
              areaStyle: { // 区域的样式
                color: 'rgb(255,255,255,0.1)' // 填充的颜色，带透明度
              }
            }
          ]
        }]
      };

      myChart.setOption(option);
    },
    async to_about() {
      this.$router.push({ name: 'About' })
    },
    async to_active() {
      this.$router.push({ name: 'MyActiveList' })
    }
  },
  mounted() {
    // 在组件挂载后立即获取任务数据
    this.myChart()
  }
}
</script>
<style scoped>
.mine {
  background-color: #F8F8F8;
  width: 750rpx;
  height: auto;
  padding-bottom: 200rpx;
}

.head {
  width: 100vw;
  height: 231px;
  background-color: #E2F2FF;
}

.head-left {
  width: 169px;
  padding: 49px 0 0 25px;
  vertical-align: top;
  display: inline-block;
}

.head-left-title {
  width: 169px;
  color: #030303;
  font-weight: bold;
  font-size: 22px;
  height: 29px;
  line-height: 29px;
  margin-bottom: 5px;
}

.head-left-content {
  width: 169px;
  font-size: 12px;
  color: #707070;
  height: 16px;
  line-height: 16px;
}

.mine-info {
  width: 93%;
  /* height: 174px; */
  height: 100px;
  background-color: #FFF;
  margin: -135px 13px 0 13px;
  border-radius: 32px;
}

.user-header {
  width: 50px;
  height: 50px;
  vertical-align: top;
  display: inline-block;
  padding: 29px 15px 0 29px;
}

.stu-detail {
  vertical-align: top;
  display: inline-block;
  padding: 33px 0px 0 0px;
  text-align: left;
}

.stu-name {
  font-size: 18px;
}

.stu-Id {
  font-size: 12px;
  color: #828282;
  padding-top: 3px;
}

.sys-setting {
  vertical-align: top;
  display: inline-block;
  padding: 17px 0 0 0px;
}

.sys-setting-img:first-child {
  padding-left: 113px;
  padding-right: 11px;
  width: 20px;
  height: 20px;
}

.sys-setting-img {
  vertical-align: top;
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: top;
  display: inline-block;
}

.stu-status {
  background-color: #EFEFEF;
  width: 103px;
  height: 22px;
  font-size: 10px;
  color: #4E4E4E;
  line-height: 22px;
  text-align: center;
  border-radius: 12px;
  margin-left: 45px;
  margin-top: 19px;
}

.list-item {
  width: 87px;
  height: 50px;
  vertical-align: top;
  display: inline-block;
  text-align: center;
  margin-top: 23px;
}

.item-num {
  font-size: 14px;
  font-weight: bold;
}

.item-name {
  padding-top: 7px;
  font-size: 12px;
  color: #4E4E4E;
}

.gongneng {
  width: 93%;
  height: auto;
  background-color: #FFF;
  border-radius: 32px;
  margin: 9px auto;
}

.gongneng-title {
  padding: 21px 0 19px 24px;
  font-size: 16px;
}

.gongneng-list {
  width: 302px;
  height: auto;
}

.gongneng-item {
  width: 76px;
  height: 60px;
  vertical-align: top;
  display: inline-block;
  text-align: center;
  margin: 15px 0;
}

.gongneng-item-img {
  width: 31px;
  height: 31px;
}

.gongneng-item-name {
  margin-top: 9px;
  font-size: 12px;
  color: #8A8A8A;
}

.radar {
  width: 93%;
  height: auto;
  background-color: #FFF;
  border-radius: 32px;
  margin: 12px auto;
  padding-bottom: 23px;
}

.radar-top {
  width: 100%;
  height: 45px;
}

.level {
  width: 60px;
  height: 20px;
  padding: 26px 10px 0 31px;
  vertical-align: top;
  display: inline-block;
}

.wall-level {
  vertical-align: top;
  display: inline-block;
  padding-top: 29px;
  font-size: 12px;
  color: #8A8A8A;
}

.level-desc {
  vertical-align: top;
  display: inline-block;
  padding-top: 29px;
  font-size: 12px;
  color: #8A8A8A;
}

.ec-box {
  width: 100%;
  height: 240px;
  margin: 0 auto;
}

.main-ec {
  width: 100%;
  height: 220px;
}

.change-lable {
  width: 170px;
  height: 40px;
  margin: 0 auto;
}

.lable-item {
  width: 85px;
  height: 40px;
  vertical-align: top;
  display: inline-block;
  text-align: center;
}

.cicle {
  width: 9px;
  height: 9px;
  background-color: rgb(188, 228, 255, 0.42);
  border-radius: 9px;
  display: inline-block;
  text-align: center;
}

.cicle-check {
  background-color: #2A63FF;
}

.lable-item-name {
  font-size: 12px;
  color: #A2A2A2;
  display: inline-block;
  text-align: center;
  padding-left: 5px;
}

.btn-learn {
  background-color: #F6F6F6;
  width: 310px;
  height: 149px;
  border-radius: 10px;
  margin: 0 auto;
}

.rsjy {
  padding: 27px 23px 16px 23px;
  font-size: 12px;
  color: #A2A2A2;
}

.to-kecheng {
  background-color: #003F88;
  width: 264px;
  height: 45px;
  border-radius: 23px;
  text-align: center;
  line-height: 45px;
  font-size: 14px;
  color: #FFF;
  margin: 0 auto;
}

.about {
  width: 88%;
  height: 45px;
  margin: 10px;
  background-color: #FFF;
  padding-left: 25px;
  line-height: 45px;
  text-align: left;
  border-radius: 20px;
}

.about-img {
  width: 17px;
}

.wall {
  width: 100%;
  height: 80px;
}
</style>