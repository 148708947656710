<template>
    <div class="praxis">
        <van-nav-bar title="写作编辑" left-text="返回" left-arrow @click-left="onClickLeft" />
        <div style="color: red;text-align: center;">（本页面不能粘贴）</div>
        <div style="text-align: left;margin-left: 5vw;">Word Count: {{ wordCount }}</div>
        <textarea v-model="writing" @input="countWords" onpaste="alert('禁止粘贴！'); return false;" maxlength="8000"
            placeholder="请输入写作内容" />
        <div class="btn-box">
            <div class="pc-img">
                <image style="width: 25rpx;" src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/pc.png"
                    mode="widthFix" />
            </div>
            <span>电脑端</span>
            <div class="clear-img" bind:tap="clear_input">
                <image style="width: 50rpx;" src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/clear.png"
                    mode="widthFix" />
            </div>
            <span @click="clear_input">一键清空</span>
            <div class="submit-btn" @click="reback">保存</div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Praxis',
    data() {
        return {
            wordCount: 0,
            writing:''
        }
    },
    methods: {
        async onClickLeft() {
            history.back()
        },
        async clear_input(){
            this.wordCount = 0;
            this.writing = ''
        },
        async reback(){
            localStorage.setItem('userWriting', this.writing)
            this.$router.push({ name: 'WritePraxis', query: { resources_id: this.resources_id, log_id:this.log_id, timestamp: Date.now() } })
        },
        async countWords() {
            // 使用正则表达式将字符串分割为单词数组，并过滤掉空字符串
            const words = this.writing.trim().split(/\s+/).filter(Boolean);
            this.wordCount = words.length;
        },
    },
    mounted() {
        // 在组件挂载后立即获取任务数据
        // 在组件挂载后立即获取任务数据
        this.log_id = this.$route.query.log_id;
        this.resources_id = this.$route.query.resources_id;
        this.writing = localStorage.getItem('userWriting')
    }
}
</script>
<style scoped>
textarea {
    width: 86vw;
    height: 600px;
    margin: 2% auto;
    font-size: 14px;
    border: 1px solid lightgray;
    padding: 10px 2vw;
}

.btn-box {
    width: 90vw;
    padding: 0 5vw;
    height: 55px;
    box-shadow: 0rpx -2px 6px 1px rgba(80, 143, 206, 0.1);
    position: fixed;
    bottom: 0;
    padding-top: 27px;
}

span {
    padding: 30px 25px 0 7px;
    font-size: 12px;
    height: 25px;
    line-height: 25px;
    color: #A1A1A1;
}

.pc-img {
    width: 25px;
    height: 25px;
    background: #B2D9FF;
    border-radius: 25px;
    vertical-align: top;
    display: inline-block;
    text-align: center;
    line-height: 25px;
}

.clear-img {
    width: 25px;
    height: 25px;
    background: #FFEAA7;
    border-radius: 25px;
    vertical-align: top;
    display: inline-block;
    text-align: center;
    line-height: 25px;
}

.submit-btn {
    width: 118px;
    height: 36px;
    background: #003F88;
    border-radius: 20px;
    color: #FFF;
    text-align: center;
    line-height: 36px;
    vertical-align: top;
    display: inline-block;
}
</style>