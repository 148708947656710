<template>
    <div class="ai">
        <van-nav-bar title="主题列表" left-text="返回" left-arrow @click-left="onClickLeft" />
        <div class="cate-item" v-for="(item, index) in EtalkCateList" :key="index" @click="to_ai('speak_subject', item.id)">
            <div class="cate-level">{{ item.level }}</div>
            <div class="first-item">
                <img class="first-item-img" :src="item.icon_img" mode="widthFix" />
                <div class="cate-title">{{ item.name }}</div>
            </div>
            <div class="second-item">
                {{ item.desc }}
            </div>
        </div>
    </div>
</template>
<script>
import { getEtalkCateList } from '@/api/user'
export default {
    name: 'Ai',
    data() {
        return {
            EtalkCateList: []
        }
    },
    methods: {
        async onClickLeft() {
            history.back()
        },
        async to_ai(stype, project_id) {
            this.$router.push({ name: 'ChatIndex', query: { project_id: project_id, is_history: 0, lang_time: 0, filePath: '', type: 0, stype: stype, from: 'speak_subject', chat_title: '主题对话', system: '假如你是一名语文老师', content: '' } })
        },
        async getEtalkCateList() {
            try {
                const user = JSON.parse(localStorage.getItem('user'))
                if (user && user.sid) {
                    const response = await getEtalkCateList({ sid: user.sid })
                    const data = response.data
                    this.EtalkCateList = data
                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        },
    },
    mounted() {
        // 在组件挂载后立即获取任务数据
        this.getEtalkCateList()
    }
}
</script>
<style scoped>
/* pages/etalk/index.wxss */
/* otherPage/pages/etalk/project.wxss */

.ai {
    width: 90vw;
    height: 100vh;
    margin: 20px auto;
    background: linear-gradient(to bottom, #FFFFFF, #E2F2FF);
    padding: 0 5vw;
    align-items: left;
}

.cate-item {
    width: 39vw;
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px 1px rgba(80, 143, 206, 0.1);
    border-radius: 10px;
    float: left;
    margin-bottom: 15px;
    padding: 15px 0 5px 12px;
}

.cate-item:nth-child(2n) {
    margin-left: 4vw;
}

.first-item {
    width: 37vw;
    height: 24px;
    border-bottom: 1rpx solid #E4E4E4;
}

.first-item-img {
    width: 14px;
    height: 15px;
    vertical-align: top;
    display: inline-block;
    margin-right: 5px;

}

.cate-title {
    width: 80px;
    height: 15px;
    vertical-align: top;
    display: inline-block;
    font-size: 12px;
}

.cate-level {
    width: 35px;
    height: 16px;
    float: right;
    font-size: 10px;
    background: linear-gradient(to right, #FF7220, #FFDE92);
    text-align: center;
    line-height: 16px;
    color: #FFF;
    border-radius: 4px 0px 0px 4px;
}

.second-item {
    width: 37vw;
    height: auto;
    padding-top: 5px;
    line-height: 14px;
    color: #878787;
    font-size: 11px;
}
</style>