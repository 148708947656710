<template>
    <div class="MicraList">
        <van-nav-bar title="微课列表" left-text="返回" left-arrow @click-left="onClickLeft" />
        <div class="top">
            <div class="top-item" :class="{ 'active': this_check == 'all' }" @click="changeTab('all')">全部</div>
            <div class="top-item" :class="{ 'active': this_check == 'writing' }" @click="changeTab('writing')">写作</div>
            <div class="top-item" :class="{ 'active': this_check == 'listening' }" @click="changeTab('listening')">听力
            </div>
            <div class="top-item" :class="{ 'active': this_check == 'reading' }" @click="changeTab('reading')">阅读</div>
            <div class="top-item" :class="{ 'active': this_check == 'speaking' }" @click="changeTab('speaking')">口语
            </div>
        </div>
        <div class="wall"></div>
        <div class="micra-item" v-for="(item, index) in weike_list" @click="to_micra(item)" :key="index">
            <img class="micra-img" :src="item.cover" mode="aspectFill" />
            <div class="micra-info">
                <div class="micra-title">{{ item.name }}</div>
                <div class="lable">类型：{{ item.type }}</div>
                <div class="lable">{{ item.create_date }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import { getMicraList } from '@/api/micra'
export default {
    name: 'MicraList',
    data() {
        return {
            weike_list: [],
            this_check: 'all'
        }
    },
    methods: {
        async onClickLeft() {
            history.back()
        },
        async changeTab(type) {
            this.this_check = type
            this.getList()
        },
        async to_micra(item) {
            console.log(item)
            var video_id = item.id
            this.$router.push({ name: 'MicraDetail', query: { video_id: video_id } })
        },
        async getList() {
            try {
                const user = JSON.parse(localStorage.getItem('user'))
                if (user && user.sid) {
                    const response = await getMicraList({ sid: user.sid, type: this.this_check })
                    const data = response.data
                    this.weike_list = data;
                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        },

    },
    mounted() {
        // 在组件挂载后立即获取任务数据
        this.getList()
    }
}
</script>
<style scoped>
.MicraList {
    width: 100vw;
    height: 100vh;
}

.top {
    width: 96vw;
    height: 30px;
    margin: 10px 2vw;
    position: fixed;
    top: 30;
}

.top-item {
    width: 9vw;
    height: 30px;
    vertical-align: top;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    margin: 0 5vw;
}

.active {
    border-bottom: #6FDBFF 4px solid;
    font-weight: 900;
}

.wall {
    width: 100vw;
    height: 60px;
}

.micra-item {
    width: 330px;
    height: 78px;
    margin: 10px 20px 20px 25px;
}

.micra-img {
    width: 135px;
    height: 78px;
    background: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #707070;
    vertical-align: top;
    display: inline-block;
}

.micra-info {
    width: 171px;
    vertical-align: top;
    display: inline-block;
    padding-left: 11px;
}

.micra-title {
    height: 50px;
    font-size: 14px;
}

.lable {
    font-size: 12px;
    color: #979797;
    margin-bottom: 2px;
}
</style>