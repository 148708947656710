import request from './index'

// 用户登录
export function login(data) {
  return request({
    url: '/login/usernamelogin',
    method: 'post',
    data
  })
}

// 获取用户信息
export function getInfo(token) {
  return request({
    url: '/user/info',
    method: 'get',
    params: { token }
  })
}

// 用户登出
export function logout() {
  return request({
    url: '/user/logout',
    method: 'post'
  })
}


// 获取推荐学信息1
export function getHotList(params) {
  return request({
    url: '/resources/getHotUserResourceList',
    method: 'get',
    params: params
  })
}

// 获取推荐学信息2
export function getRelList(params) {
  return request({
    url: '/resources/getRelList',
    method: 'get',
    params: params
  })
}


export function saveUserStudyResource(data){
  return request({
    url: '/resources/saveUserStudyResource',
    method: 'post',
    data
  })
}

export function Submitactivity(data){
  return request({
    url: '/activity/submitactivity',
    method: 'post',
    data
  })
}

// user_task/getList
export function getTaskList(data){
  return request({
    url: '/user_task/getList',
    method: 'post',
    data
  })
}

// user_sign/getDateList
export function getDateList(data){
  return request({
    url: '/user_sign/getDateList',
    method: 'post',
    data
  })
}

// user_task/getList
export function getUserStudy(data){
  return request({
    url: '/user/getUserStudy',
    method: 'post',
    data
  })
}

// user_sign
export function getUserSign(data){
  return request({
    url: '/user_sign/save',
    method: 'post',
    data
  })
}

//正在学
export function getNewUserResourceList(params){
  return request({
    url: '/resources/getNewUserResourceList',
    method: 'get',
    params: params
  })
}

//推荐学
export function getHotUserResourceList(params){
  return request({
    url: '/resources/getHotUserResourceList',
    method: 'get',
    params: params
  })
}


//写作练习交卷
// paper/writeSubmit
export function writeSubmit(data){
  return request({
    url: '/paper/writeSubmit',
    method: 'post',
    data
  })
}

//我的活动列表
// paper/writeSubmit
export function getMyActivityList(data){
  return request({
    url: '/activity/getMyActivityList',
    method: 'get',
    params: data
  })
}

export function getEtalkCateList(data){
  return request({
    url: '/banxue/getEtalkCateList',
    method: 'get',
    params: data
  })
}

export function getNewInfoList(data){
  return request({
    url: '/banxue/getNewInfoList',
    method: 'get',
    params: data
  })
}

export function getOldInfoList(data){
  return request({
    url: '/banxue/getOldInfoList',
    method: 'get',
    params: data
  })
}

export function getAIHistory(data){
  return request({
    url: '/banxue/getAiHistoryList',
    method: 'get',
    params: data
  })
}

export function getUserMapLevel(data){
  return request({
    url: '/user/getUserMapLevel',
    method: 'get',
    params: data
  })
}