import request from './index'

// 获取上次学习
export function UserResource(data) {
    return request({
        url: '/resources/getNewUserResource',
        method: 'get',
        params: data
    })
}

// 获取听力主题
export function getList(data) {
    return request({
        url: '/subject/gets',
        method: 'get',
        params: data
    })
}

// 获取资源包
export function getResources(data) {
    return request({
        url: '/resources/gets',
        method: 'get',
        params: data
    })
}


// 记录学习资源插入学习日志
export function saveUserStudyResource(data) {
    return request({
        url: '/resources/saveUserStudyResource',
        method: 'post',
        data
    })
}

// 获取练习题内容
export function getJobList(params) {
    return request({
        url: '/job/gets',
        method: 'get',
        params: params
    })
}

//提交练习答案
export function submitData(data) {
    return request({
        url: '/jobAnswer/save',
        method: 'post',
        data
    })
}

//修改记录学习时间
export function updateUserStudy(data) {
    return request({
        url: '/resources/updateUserStudy',
        method: 'post',
        data
    })
}

//修改记录学习时间
export function jobAnswer(data) {
    return request({
        url: '/jobAnswer/gets',
        method: 'get',
        params: data
    })
}