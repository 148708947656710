<template>
  <div class="praxis">
    <van-nav-bar title="阅读练习" left-text="返回" left-arrow @click-left="onClickLeft" />
    <div class="article" :class="{ 'article-change': praxis_show == true }" v-html="resources.resources.content"></div>
    <div class="wall"></div>
    <div class="answer-box">
      <div class="answer-btn" @click="change_praxis_show">展 开</div>
      <van-tabs v-model="active" color="#6FDBFF" animated>
        <van-tab v-for="(item, index) in jobList" :key="index">
          <template #title> {{ index + 1 }}题 </template>
        </van-tab>
      </van-tabs>
    </div>
    <van-popup lock-scroll="true" v-model="praxis_show" position="bottom"
      style="border-radius: 30px 30px 0 0;height: 60vh;overflow: auto;">
      <div class="close-btn" @click="change_praxis_show">收 起</div>
      <van-tabs v-model="active" color="#6FDBFF" animated>
        <van-tab v-for="(item, index) in jobList" :key="index">
          <template #title> {{ index + 1 }}题 </template>
          <div class="praxis-item">
            <div class="praxis-title">{{ index + 1 }}题</div>
            <div class="praxis-desc" v-if="item.type_id == 1">
              <div class="wrap-text" v-html="item.title"></div>
            </div>
            <div class="praxis-desc" v-else @click="change_card">
              <div class="wrap-text" v-html="item.title"></div>
            </div>
            <div class="praxis-info" v-if="item.type_id == 1">
              <div class="answer" v-for="(itemChild, indexChild) in item.optionsArray" :key="indexChild">
                <div :class="{ 'checked': itemChild.is_check == 1 }" class="answer-title"
                  @click="check_answer(index, indexChild)">{{ itemChild.label }}</div>
                <div class="answer-desc">{{ itemChild.title }}</div>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
      <div class="praxis-bottom">
        <div class="answer-has">
          <div class="jindu"> 进度({{ has_answer }}/{{ total_num }})</div>
          <div class="btn-ok-cn" @click="change_card">提交</div>
        </div>
      </div>
    </van-popup>
    <van-popup lock-scroll="true" v-model="card_show" position="bottom"
      style="border-radius: 30px 30px 0 0;width:100px;height: 500px;overflow-y: scroll;position: fixed;bottom: 0;width: 100%;">
      <div class="card-title">答题卡</div>
      <div class="main-answer-card">
        <span v-for="(item, index) in jobList" :key="index">
          <div :class="{ 'card-item': item.type_id == 1, 'card-item-empty': item.is_check == 0 }"
            v-if="item.type_id == 1">
            {{ index + 1 }}
          </div>

          <div class="card-item1" v-else>
            <div class="lable-name">第{{ index + 1 }}题</div>
            <div class="aaa">
              <div v-for="(child, subIndex) in item.optionsArray" :key="subIndex" class="input-box">
                <input type="text" class="input-word" v-model="child.stu_answer"
                  @input="onAnswerInput(index, subIndex, child)" />
              </div>
            </div>
          </div>
        </span>
      </div>
      <div class="btn-cancel" @click="change_card">取消</div>
      <div class="btn-ok" @click="to_submit">确定</div>

    </van-popup>
    <ReadPraxisAi :resources_id="resources_id"  allow="microphone" />
  </div>
</template>
<script>
import { getJobList, submitData, updateUserStudy } from '@/api/listen'
import ReadPraxisAi from "@/components/ReadPraxisAi";
export default {
  components: { ReadPraxisAi },
  name: 'Praxis',
  data() {
    return {
      show: false,
      card_show: false,
      activeTitle: 0,
      active: 0,
      resources: [],
      jobList: [],
      src: [],
      log_id: 0,
      resources_id: 0,
      percentage: 0,
      has_answer: 0,
      currentTime: '0:00',
      praxis_show: false,
      total_num: 0,
      audio_status: 1
    }
  },
  methods: {
    async onClickLeft() {
      history.back()
    },
    async getJobList() {
      try {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user && user.sid) {
          const response = await getJobList({ sid: user.sid, resource_id: this.resources_id })
          const data = response.data
          var jobList = response.data.jobList;
          // console.log(jobList)
          var total_num = 0;
          jobList.forEach(item => {
            item.is_check = 0;
            if (item.optionsArray == null) {
              item.optionsArray = JSON.parse(item.options)
              console.log(item.optionsArray)
              item.optionsArray.forEach(child => {
                child.is_check = 0;
                total_num++
              });
            } else {
              total_num++
            }

          });
          this.resources = data
          this.total_num = total_num
          console.log(this.resources.resources.content)
          this.jobList = jobList
          this.src = data.resources.file_path
        } else {
          console.error('用户未登录或没有找到sid')
        }
      } catch (error) {
        console.error('Login Failed:', error)
      }
    },
    async check_answer(itemIndex, indexChild) {
      var jobList = this.jobList
      console.log(itemIndex)
      jobList.forEach((val, key) => {
        if (key == itemIndex) {
          val.is_check = 1;
          val.optionsArray.forEach((child, index) => {
            if (indexChild == index) {
              child.is_check = 1
            } else {
              child.is_check = 0
            }
          });
        }
      })
      var has_answer = 0
      jobList.forEach(val => {
        val.optionsArray.forEach(child => {
          if (child.is_check == 1) {
            has_answer++
          }
        });

      })
      this.jobList = jobList
      this.has_answer = has_answer
    },

    async to_submit() {
      var JobList = this.jobList;
      var submit_data = [];
      const user = JSON.parse(localStorage.getItem('user'))
      JobList.forEach((item) => {
        if (item.type_id == 1) {
          var singleAnswerAdded = false; // 标记单选题是否已添加到提交数据中
          var user_answer = ''; // 默认用户选择的答案为空字符串

          item.optionsArray.forEach((child) => {
            if (child.is_check == 1 && !singleAnswerAdded) {
              user_answer = child.label || child.stu_answer; // 保存用户选择的答案

              var is_right = 0; // 默认设置为错误
              if (item.options_answer === child.label) {
                is_right = 1; // 设置正确答案标记
              }

              submit_data.push({
                user_id: user.sid,
                job_id: item.id,
                type_id: item.type_id,
                lable: child.label,
                answer: user_answer, // 使用用户选择的答案
                is_right: is_right,
                resource_id: item.resource_id
              });

              singleAnswerAdded = true;
            }
          });

          // 即使用户未选择选项，也要添加一条错误答案
          if (!singleAnswerAdded) {
            submit_data.push({
              user_id: user.sid,
              job_id: item.id,
              type_id: item.type_id,
              lable: '',
              answer: '', // 答案为空
              is_right: 0, // 设置为错误
              resource_id: item.resource_id
            });
          }
        } else if (item.type_id == 2) {
          item.optionsArray.forEach((child) => {
            var user_answer = child.stu_answer || ''; // 默认为空

            // 检查是否填写了答案
            if (child.is_check == 1 && user_answer !== '') {
              var is_right = 0; // 默认设置为错误

              // 处理填空题
              var answer = child.title.split('&'); // 将标准答案分割成数组
              var check_res = answer.includes(child.stu_answer); // 检查用户答案是否在标准答案数组中
              if (check_res) {
                is_right = 1; // 设置正确答案标记
              }

              submit_data.push({
                user_id: user.sid,
                job_id: item.id,
                type_id: item.type_id,
                lable: child.label,
                answer: user_answer, // 使用用户填写的答案
                is_right: is_right,
                resource_id: item.resource_id
              });
            } else {
              // 未填写的部分处理
              submit_data.push({
                user_id: user.sid,
                job_id: item.id,
                type_id: item.type_id,
                lable: child.label,
                answer: '', // 答案为空
                is_right: 0, // 设置为错误
                resource_id: item.resource_id
              });
            }
          });
        }
      });

      console.log(submit_data);

      submit_data = JSON.stringify(submit_data)
      const response = await submitData({
        params: submit_data
      })
      if (response.code == 100) {
        const resp = await updateUserStudy({ id: this.log_id })
        if (resp.code == 100) {
          this.$router.push({ name: 'ReadReport', query: { resource_id: this.resources_id } })
        }
      }
    },
    async audioPlay() {
      this.$refs.audioPlayer.play();
    },
    async audioPause() {
      this.$refs.audioPlayer.pause();
      this.audio_status = 1
    },
    updateTime(event) {
      const audio = event.target;
      this.currentTime = this.formatTime(audio.currentTime);
      console.log(audio.currentTime)
      console.log(audio.duration)
      this.percentage = Math.floor((audio.currentTime / audio.duration) * 100);
    },
    loadMetadata(event) {
      const audio = event.target;
      this.duration = this.formatTime(audio.duration);
    },
    canPlay() {
      console.log('Audio can play through.');
    },
    formatTime(seconds) {
      const m = Math.floor(seconds / 60) % 60;
      const s = Math.floor(seconds) % 60;
      return `${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
    },
    change_praxis_show() {
      this.praxis_show = !this.praxis_show;
      if (this.praxis_show) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    },
    change_card() {
      this.card_show = !this.card_show;
      if (this.card_show) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    },
    onAnswerInput(item, subIndex, value) {
      // 确保传入的是正确的索引和数据
      var jobList = this.jobList;

      // 找到正确的题目
      jobList.forEach((currentItem, itemIndex) => {
        if (itemIndex === item) {
          // 标记该题目为已回答
          currentItem.is_check = 1;

          // 找到正确的选项并更新用户输入
          currentItem.optionsArray.forEach((option, optionIndex) => {
            if (optionIndex === subIndex) {
              if (value != '') {
                option.is_check = 1
              }

              option.stu_answer = value.stu_answer; // 更新用户输入的答案
            }
          });
        }
      });

      // 更新回答计数
      var has_answer = 0;
      jobList.forEach((currentItem) => {
        currentItem.optionsArray.forEach((option) => {
          if (option.is_check === 1) {
            has_answer++;
          }
        });
      });

      this.jobList = jobList; // 更新组件的数据
      this.has_answer = has_answer; // 更新已回答的题目数量

      console.log(jobList)
      console.log('当前题目:', item);  // 当前的题目
      console.log('当前索引:', subIndex);  // 当前选项索引
      console.log('当前答案:', value.stu_answer);  // 用户输入的答案
    }
  },
  mounted() {
    // 在组件挂载后立即获取任务数据
    // 在组件挂载后立即获取任务数据
    this.log_id = this.$route.query.log_id;
    this.resources_id = this.$route.query.resources_id;
    this.getJobList()
  }
}
</script>
<style scoped>
.praxis-item {
  width: 90%;
  height: auto;
  margin: 0 auto;
  text-align: left;
}

.praxis {
  width: 100vw;
  height: 100vh;
}

.praxis-title {
  font-size: 14px;
  color: #030303;
  padding: 35px 0 10px 0;
  /* width: 50px; */
  vertical-align: top;
  display: inline-block;
}

.praxis-desc {
  font-size: 14px;
  width: 90%;
  height: auto;
  padding: 10px 0;
  vertical-align: top;
  display: inline-block;
  font-weight: normal;

}

.praxis-info {
  width: 90%;
  height: auto;
  background: #FFFFFF;

}

.answer {
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.answer-title {
  vertical-align: top;
  display: inline-block;
  width: 35px;
  height: 35px;
  background-color: lightgray;
  border-radius: 35px;
  line-height: 35px;
  text-align: center;
}

.checked {
  background: linear-gradient(180deg, #4499EE 0%, #9CE4FF 100%);
}

.answer-desc {
  padding-left: 10px;
  font-size: 14px;
  vertical-align: top;
  display: inline-block;
  width: 300px;
}

.article {
  width: 90vw;
  height: auto;
  margin: 8px auto;
  padding-bottom: 60px;
}

.article-change {
  padding-bottom: 70vh;
}

.wrap-text {
  width: 90%;
  height: auto;
  margin: 0 auto;
  text-align: left;
  padding: 10px 0;
}

.praxis-bottom {
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  box-shadow: 0px -2px 6px 1px rgba(80, 143, 206, 0.1);
  border-radius: 0px;
  position: fixed;
  bottom: 0;
}

.new-audio {
  width: 50px;
  height: 50px;
  vertical-align: top;
  display: inline-block;
  padding-left: 15px;
}

.start-img {
  width: 50px;
  height: 50px;
  vertical-align: top;
  display: inline-block;

}

.audio-info {
  width: 300px;
  height: 50px;
  vertical-align: top;
  display: inline-block;
  line-height: 50px;
  text-align: left;
  color: #979797;
  font-size: 12px;
}

.progress {
  width: 225px;
  vertical-align: top;
  display: inline-block;
  padding-top: 22px;
}

.now-time {
  vertical-align: top;
  display: inline-block;
  line-height: 50px;
}

.answer-has {
  width: 325px;
  height: 60px;
  padding-left: 25x;
}

.jindu {
  width: 205px;
  vertical-align: top;
  display: inline-block;
  line-height: 60px;
  font-size: 14px;
  color: #979797;
  /* text-align: left; */

}

.btn-ok-cn {
  margin-top: 12px;
  width: 118px;
  height: 36px;
  line-height: 36px;
  background: #003F88;
  border-radius: 23px;
  /* margin: 0 auto; */
  text-align: center;
  color: #FFF;
  vertical-align: top;
  display: inline-block;
}

.card-title {
  width: 100vw;
  height: 75px;
  text-align: center;
  line-height: 75px;
  border-bottom: #E4E4E4 1px solid;
  margin: 0 auto;
  color: #000000;
  font-weight: bold;
  font-size: 16px;
}

.main-answer-card {
  padding: 30px 40px 80px 40px;
  text-align: left;
}

.card-item {
  width: 34px;
  height: 34px;
  background: linear-gradient(180deg, #4499EE 0%, #9CE4FF 100%);
  border-radius: 34px;
  line-height: 34px;
  text-align: center;
  color: #FFF;
  vertical-align: top;
  display: inline-block;
  margin: 10px 5px;
}

.card-item-empty {
  background: #CBCBCB;
}

.btn-cancel {
  position: absolute;
  top: 26px;
  right: 25px;
}

.btn-ok {
  width: 288px;
  height: 45px;
  background: #003F88;
  border-radius: 23px;
  margin: 0 auto;
  line-height: 45px;
  text-align: center;
  color: #FFF;
  position: fixed;
  bottom: 0;
  left: 40px;
}

.answer-box {
  position: fixed;
  bottom: 0px;
  width: 100vw;
  height: 45px;
  background-color: #56B6FF;
}

.answer-btn {
  position: fixed;
  bottom: 25px;
  left: 40vw;
  width: 20vw;
  height: 40px;
  line-height: 25px;
  text-align: center;
  background-color: #FFF;
  border-radius: 15px 15px 0 0;
  color: rgb(148, 147, 147);
  font-size: 12px;
}

.close-btn {
  position: fixed;
  bottom: 56vh;
  left: 40vw;
  width: 20vw;
  height: 55px;
  line-height: 25px;
  text-align: center;
  background-color: #FFF;
  border-radius: 15px 15px 0 0;
  color: #8F8F8F;
  font-size: 12px;
}

/* 填空题样式 */
.card-item1 {
  width: 100px;
  height: auto;
  vertical-align: top;
  display: inline-block;
}

.lable-name {
  font-size: 16px;
  margin: 10px 0;
  text-align: center;
}

.aaa {
  width: 100px;
  height: 40px;
  text-align: left;
}

.input-box {
  width: 80px;
  height: 40px;
  padding-bottom: 50px;
}

.input-word {
  border: none;
  border-bottom: 1px solid gray;
  font-size: 14px;
}
</style>