<template>
  <div class="home">
    <div class="main">
      <div class="head">
        <div class="head-left">
          <div class="head-left-title">学习</div>
          <div class="head-left-content">已学习{{ countSign }}天，继续加油！</div>
        </div>
        <div class="head-right">
          <img src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/index/study.png" mode="widthFix"
            class="head-right-img" />
        </div>
      </div>
      <div class="content">
        <!-- 统计数据开始 -->
        <div class="content-today-count">
          <img src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/index/tree.png" mode="widthFix"
            class="tree-img" />
          <div class="today-count-item">
            <div class="count-one">{{ countTask.total_finish_count }}</div>
            <div class="count-two">/{{ countTask.total_task_count }}个</div>
            <div class="count-title">今日任务</div>
          </div>
          <div class="today-count-item">
            <div class="count-one">{{ countTask.today_minutes }}</div>
            <div class="count-two">/{{ countTask.plan_minutes }}min</div>
            <div class="count-title">今日时长</div>
          </div>
          <div class="today-count-item today-count-item-last" @click="to_calendar">
            <div class="count-one">{{ countSign }}</div>
            <div class="count-two">天</div>
            <div class="count-title">
              <div class="sign-text">本月打卡</div>
            </div>
          </div>
          <div class="icon-sign">
            <van-icon name="arrow" color="#606060" size="12px"/>
          </div>
        </div>
        <!-- 统计数据结束 -->
        <!-- 快速模块开始 -->
        <div class="fast-click">
          <div class="fast-item" :data-index="item.page_url" v-for="(item, index) in fast_list" :key="index" replace
            @click="to_page(item.link_url)">
            <img :src="item.img_url" mode="widthFix" class="fast-img" />
            <div class="fast-title">{{ item.name }}</div>
          </div>
          <div class="fast-item">
            <img class="fast-img-empty" />
            <div class="fast-title"></div>
          </div>
        </div>
        <!-- 快速模块结束 -->
        <!-- 通知开始 -->
        <div class="notice">
          <img src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/index/notice.png" mode="widthFix"
            class="notice-img" />
          <div class="notice-title">
            <van-notice-bar background="#FFF" color="#000" :text="notice_text" />
          </div>
          <div class="more-info" @click="to_notice_list">全部</div>
          <div class="more-icon">
            <van-icon name="arrow" color="#606060" />
          </div>
        </div>
        <!-- 通知结束 -->
        <!-- 写作大赛 -->
        <div class="xzds" @click="to_active(active_info.id)">
          <div class="xzds-title">{{ active_info.name }}</div>
          <div class="xzds-time">{{ active_info.start_date }}~{{ active_info.end_date }}</div>
        </div>
        <!-- 写作大赛 -->
        <!-- 正在学 -->
        <div class="learn-model" v-if="NewResouceList.length > 0">
          <div class="model-start">
            <div class="model-title">正在学</div>
            <div class="model-more" @click="to_study_list(0)" data-index="1">全部</div>
            <div class="model-icon" @click="to_study_list(0)" data-index="1">
              <van-icon name="arrow" color="#606060" />
            </div>

            <div class="learn-item" v-for="(item, index) in NewResouceList" :key="index">
              <img :src="item.cover" mode="aspectFill" class="learn-img" />
              <div class="learn-item-info">
                <div class="learn-item-title">
                  <span v-if="item.category_id == 1">[听力]</span>
                  <span v-if="item.category_id == 2">[阅读]</span>
                  <span v-if="item.category_id == 4">[写作]</span>
                  {{ item.name }}
                </div>
                <div class="learn-item-update-time">{{ item.create_time }}更新</div>
              </div>
              <div class="btn-learn" @click="to_praxis(item)" :data-index="item">继续学习</div>
            </div>
          </div>
        </div>
        <!-- 正在学 -->
        <!-- 推荐学 -->
        
        <HotList />
        <!-- 推荐学 -->
        <div class="wall"></div>
      </div>
    </div>
    <Ai allow="microphone" />
  </div>
</template>

<script>
import { getUserStudy, getDateList, getMenuList, getNewUserResourceList, HotResouceList, getActiveList, NoticeList } from '@/api/home'
import { saveUserStudyResource } from '@/api/user'
import Ai from "@/components/Ai";
import HotList from "@/components/HotList";
export default {
  components: { Ai, HotList },
  name: 'Home',
  data() {
    return {
      countSign: 10,
      fast_list: [],
      NewResouceList: [],
      HotResouceList: [],
      active_info: [],
      notice_text: '暂无通知',
      countTask: {
        total_finish_count: 1,
        total_task_count: 1,
        today_minutes: 0,
        plan_minutes: 0
      }
    }
  },
  methods: {
    async to_praxis(item) {
      try {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user && user.sid) {
          const response = await saveUserStudyResource({ sid: user.sid, resource_id: item.id })
          if (response.code == 100) {
            if (item.category_id == 1) {
              this.$router.push({ name: 'Praxis', query: { log_id: response.data, resources_id: item.id } })
            }
            if (item.category_id == 2) {
              this.$router.push({ name: 'ReadPraxis', query: { log_id: response.data, resources_id: item.id } })
            }
            if (item.category_id == 4) {
              this.$router.push({ name: 'WritePraxis', query: { log_id: response.data, resources_id: item.id } })
            }
          }
        } else {
          console.error('用户未登录或没有找到sid')
        }
      } catch (error) {
        console.error('Login Failed:', error)
      }
    },
    async to_notice_list() {
      this.$router.push({ name: 'NoticeList' })
    },
    async to_study_list(index){
      this.$router.push({ name: 'StudyList', query: { now_status: index } })
    },
    to_calendar(){
      this.$router.push({ name: 'Calendar' })
    },
    async NoticeList() {
      try {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user && user.sid) {
          const response = await NoticeList({
            sid: user.sid,
            page: 1,
            size: 1,
            type: 'common',
          })
          this.notice_text = response.data.list[0].title + ": " + response.data.list[0].content
        } else {
          console.error('用户未登录或没有找到sid')
        }
      } catch (error) {
        console.error('Login Failed:', error)
      }
    },
    async getUserStudy() {
      try {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user && user.sid) {
          const response = await getUserStudy(user.sid)
          const active_res = await getActiveList(user.sid)
          const data = response.data
          this.active_info = active_res.data[0]
          this.countTask = {
            total_finish_count: data.total_finish_count,
            total_task_count: data.total_task_count,
            today_minutes: data.today_minutes,
            plan_minutes: data.plan_minutes
          }
        } else {
          console.error('用户未登录或没有找到sid')
        }
      } catch (error) {
        console.error('Login Failed:', error)
      }
    },
    async getNewUserResourceList() {
      try {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user && user.sid) {
          const response = await getNewUserResourceList(user.sid)
          const data = response.data
          this.NewResouceList = data
        } else {
          console.error('用户未登录或没有找到sid')
        }
      } catch (error) {
        console.error('Login Failed:', error)
      }
    },
    async getHotResouceList() {
      try {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user && user.sid) {
          const response = await HotResouceList(user.sid)
          const data = response.data
          this.HotResouceList = data
        } else {
          console.error('用户未登录或没有找到sid')
        }
      } catch (error) {
        console.error('Login Failed:', error)
      }
    },
    async getDateList() {
      try {
        const user = JSON.parse(localStorage.getItem('user'))
        const now = new Date()
        var now_month = now.getMonth() + 1;
        if (now_month < 10) {
          now_month = '0' + now_month
        }
        let now_date = now.getFullYear() + '' + now_month
        if (user && user.sid) {
          const response = await getDateList({ sid: user.sid, dates: now_date })
          const data = response.data
          this.countSign = data.length
        } else {
          console.error('用户未登录或没有找到sid')
        }
      } catch (error) {
        console.error('Login Failed:', error)
      }
    },
    async getMenuList() {
      try {
        const response = await getMenuList()
        const data = response.data
        this.fast_list = data

      } catch (error) {
        console.error('Login Failed:', error)
      }
    },
    async to_page(url) {
      this.$router.push(url)
    },
    async to_active(active_id) {
      this.$router.push({ name: 'ActiveDetail', query: { active_id: active_id } })
    }
  },
  mounted() {
    // 在组件挂载后立即获取任务数据
    this.getUserStudy()
    this.getDateList()
    this.getMenuList()
    this.getNewUserResourceList()
    this.getHotResouceList()
    this.NoticeList()
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      this.error = '当前浏览器不支持录音功能';
      return;
    }

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(() => {
        console.log('麦克风权限已获取');
      })
      .catch((err) => {
        this.error = '麦克风权限获取失败: ' + err.message;
      });
  }
}
</script>

<style scoped>
.head {
  width: 100%;
  height: 180px;
  background-color: #E2F2FF;
}

.head-left {
  width: 169px;
  padding: 49px 0 0 25px;
  vertical-align: top;
  display: inline-block;
  text-align: left;
}

.head-left-title {
  width: 169px;
  color: #030303;
  font-weight: bold;
  font-size: 22px;
  height: 29px;
  line-height: 29px;
  margin-bottom: 5px;
  text-align: left;
}

.head-left-content {
  width: 169px;
  font-size: 12px;
  color: #707070;
  height: 16px;
  line-height: 16px;
  text-align: left;
}

.head-right {
  padding-top: 53px;
  width: 153px;
  height: 53px;
  vertical-align: top;
  display: inline-block;
}

.head-right-img {
  width: 153px;
  height: 106px;
}

.content {
  width: 100%;
  height: auto;
  border-radius: 32px;
  background-color: #FFF;
  position: relative;
  top: -30px;
}

.content-today-count {
  width: 100%;
  height: 70px;
}

.tree-img {
  /* margin: 3px 0 0 0px; */
  vertical-align: top;
  display: inline-block;
  width: 69px;
  height: 90px;
}

.today-count-item {
  width: 80px;
  height: 90px;
  vertical-align: top;
  display: inline-block;
  text-align: center;
  margin-right: 30px;
}

.today-count-item-last {
  width: 45px;
  margin-right: 0px;
}

.count-one {
  color: #000000;
  font-size: 22px;
  display: inline-block;
  text-align: center;
  padding-top: 32px;
}

.count-two {
  color: #000000;
  font-size: 12px;
  display: inline-block;
  text-align: center;
  padding-top: 32px;
}

.count-title {
  color: #5B5B5B;
  font-size: 10px;
  margin-top: 9px;
  line-height: 20px;
  height: 20px;
}

.sign-text {
  display: inline-block;
  text-align: center;
}

.icon-sign {
  height: 20px;
  display: inline-block;
  text-align: center;
  padding-top: 65px;
}

.fast-click {
  width: 295px;
  height: auto;
  margin: 60px auto 0px auto;
}

.fast-item {
  vertical-align: top;
  display: inline-block;
  width: 48px;
  height: 60px;
  text-align: center;
  padding-right: 34px;
  margin-bottom: 20px;
}

.fast-item:nth-child(4n+4) {
  padding-right: 0px;
}

.fast-img {
  width: 43px;
  height: 43px;
}

.fast-img-empty {
  height: 43px;
}

.fast-title {
  width: 48px;
  height: 17px;
  line-height: 17px;
  font-size: 12px;
  color: #000000;
}

.notice {
  width: 325px;
  height: 40px;
  margin: 20px auto;
  border-top: 1px solid #E4E4E4;
  border-bottom: 1px solid #E4E4E4;
  line-height: 40px;
}

.notice-img {
  width: 22px;
  height: 22px;
  margin: 9px 12px 8px 7px;
  vertical-align: top;
  display: inline-block;
}

.notice-title {
  vertical-align: top;
  display: inline-block;
  width: 224px;
  font-size: 12px;
}

.more-info {
  vertical-align: top;
  display: inline-block;
  width: 40px;
  height: 22px;
  font-size: 12px;
  text-align: right;
}

.more-icon {
  vertical-align: top;
  display: inline-block;
  width: 20px;
  height: 22px;
  /* padding-top: 13px; */
  font-size: 12px;
}

.xzds {
  width: 330px;
  height: 87px;
  margin: 0 auto;
  background: url('https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/index/xzds.png');
  background-size: cover;
  text-align: left;
}

.xzds-title {
  padding: 22px 0 0 43px;
  font-size: 18px;
  font-weight: 400;
}

.xzds-time {
  padding-left: 43px;
  font-size: 12px;
  color: #A1A1A1;
}

.learn-model {
  width: 330px;
  height: auto;
  margin: 0 auto;
}

.model-start {
  width: 330px;
  height: auto;
  margin: 0 auto;
  line-height: 40px;
  margin-top: 30px;
}

.model-title {
  vertical-align: top;
  display: inline-block;
  width: 110px;
  text-align: left;
  font-size: 16px;
}

.model-more {
  vertical-align: top;
  display: inline-block;
  width: 200px;
  text-align: right;
  font-size: 12px;
}

.model-icon {
  vertical-align: top;
  display: inline-block;
}

.learn-item {
  width: 330px;
  height: 85px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 1px rgba(80, 143, 206, 0.1);
  border-radius: 20px 20px 20px 20px;
  margin-bottom: 11px;
}

.learn-img {
  vertical-align: top;
  display: inline-block;
  width: 58px;
  height: 58px;
  margin: 14px 10px 13px 13px;
}

.learn-item-info {
  vertical-align: top;
  display: inline-block;
  width: 170px;
  height: 50px;
  line-height: 25px;
  padding: 10px 0;
}

.learn-item-title {
  width: 150px;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 20px;
}

.learn-item-update-time {
  font-size: 12px;
  color: #979797;
  padding-top: 15px;
}

.btn-learn {
  vertical-align: top;
  display: inline-block;
  color: #003F88;
  font-size: 12px;
  height: 85px;
  line-height: 85px;
}

.wall {
  width: 100%;
  height: 100px;
}
</style>
