<template>
    <div class="hello">
        <!--plantPage/pages/map/index.wxml-->
        <div class="main">
            <div class="level7">
                <van-image fit="cover" class="level-img"
                    src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/exam/level7.png" mode="" />
                <div class="level-num">07</div>
            </div>
            <div class="level6">
                <van-image fit="cover" class="level-img"
                    src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/exam/level6.png" mode="" />
                <div class="level-num">06</div>
            </div>
            <div class="level5">
                <van-image fit="cover" class="level-img"
                    src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/exam/level5.png" mode="" />
                <div class="level-num">05</div>
            </div>
            <div class="level4">
                <van-image fit="cover" class="level-img"
                    src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/exam/level4.png" mode="" />
                <div class="level-num">04</div>
            </div>
            <div class="level3">
                <van-image fit="cover" class="level-img"
                    src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/exam/level3.png" mode="" />
                <div class="level-num">03</div>
            </div>
            <div class="level2">
                <van-image fit="cover" class="level-img"
                    src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/exam/level2.png" mode="" />
                <div class="level-num">02</div>
            </div>
            <div class="level1">
                <van-image fit="cover" class="level-img"
                    src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/exam/level1.png" mode="" />
                <div class="level-num">01</div>
            </div>
            <div class="chenghao" v-for="(item, index) in titleList" :key="index">{{ item.lable }}</div>

            <div class="footer-box">
                <div class="left-box">
                    <div class="left-box-info">
                        <van-image fit="cover" class="xunzhang"
                            src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/calendar/xunzhang-gray.png"
                            mode="widthFix" v-if="countTask.total_finish_count < countTask.total_task_count" />
                        <van-image fit="cover" class="xunzhang"
                            src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/calendar/xunzhang.png"
                            mode="widthFix" v-if="countTask.total_finish_count >= countTask.total_task_count" />
                        <div class="task-box">
                            <div class="task-count">{{ countTask.total_finish_count }}/{{ countTask.total_task_count
                                }}个任务
                            </div>
                            <div class="task-status" v-if="countTask.total_finish_count < countTask.total_task_count">
                                未完成</div>
                            <div class="task-status" v-if="countTask.total_finish_count >= countTask.total_task_count">
                                已完成</div>
                        </div>
                    </div>
                </div>
                <div class="right-box">
                    <div class="start-study" bind:tap="to_plant">开始学习</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getUserMapLevel } from '@/api/user'
export default {
    name: 'HelloWorld',
    data() {
        return {
            data: {
                titleList: [{
                    id: 30,
                    lable: '完美收官'
                },
                {
                    id: 29,
                    lable: '志在必得'
                },
                {
                    id: 28,
                    lable: '收获颇丰'
                },
                {
                    id: 27,
                    lable: '步步登高'
                },
                {
                    id: 26,
                    lable: '再踏征途'
                },
                {
                    id: 25,
                    lable: '大功告成'
                },
                {
                    id: 24,
                    lable: '胜利可期'
                },
                {
                    id: 23,
                    lable: '成效显著'
                },
                {
                    id: 22,
                    lable: '日进有功'
                },
                {
                    id: 21,
                    lable: '迈出脚步'
                },
                {
                    id: 20,
                    lable: '凯旋而归'
                },
                {
                    id: 19,
                    lable: '指日可待'
                },
                {
                    id: 18,
                    lable: '成效突出'
                },
                {
                    id: 17,
                    lable: '如鱼得水'
                },
                {
                    id: 16,
                    lable: '扬帆起航'
                },
                {
                    id: 15,
                    lable: '旗开得胜'
                },
                {
                    id: 14,
                    lable: '胜券在握'
                },
                {
                    id: 13,
                    lable: '成绩斐然'
                },
                {
                    id: 12,
                    lable: '竿头直上'
                },
                {
                    id: 11,
                    lable: '崭露头角'
                },
                {
                    id: 10,
                    lable: '马到成功'
                },
                {
                    id: 9,
                    lable: '胜利在握'
                },
                {
                    id: 8,
                    lable: '卓有成效'
                },
                {
                    id: 7,
                    lable: '稳扎稳打'
                },
                {
                    id: 6,
                    lable: '牛刀小试'
                },
                {
                    id: 5,
                    lable: '旗开得胜'
                },
                {
                    id: 4,
                    lable: '胜利在望'
                },
                {
                    id: 3,
                    lable: '略有小成'
                },
                {
                    id: 2,
                    lable: '渐入佳境'
                },
                {
                    id: 1,
                    lable: '初露锋芒'
                },
                ],
                taskList: [],
                scrollTarget: 0,
                now_level: 1,
                now_progress: 30
            },
        }
    },
    methods: {
        async getUserMapLevel() {
            try {
                const user = JSON.parse(localStorage.getItem('user'))
                if (user && user.sid) {
                    const response = await getUserMapLevel(user.sid)
                    const data = response.data
                    if (response.code == 100) {
                        var level = data.level
                        var sub_level = data.sub_level
                        var now_level = (level - 1) * 5 + sub_level

                        this.now_level = data.level
                        this.now_progress = now_level

                        console.log(this.data)
                    }
                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        },
    },
    mounted() {
        // 在组件挂载后立即获取任务数据
        this.getUserMapLevel()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}
</style>
