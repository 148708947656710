<template>
    <div class="praxis">
        <van-nav-bar title="写作练习" left-text="返回" left-arrow @click-left="onClickLeft" />
        <div class="wrap-text" :v-html="resources.content"></div>
        <div class="writing">
            <div class="stu-writing">
                <textarea v-model="writing" maxlength="8000" placeholder="请输入写作内容" />
            </div>
        </div>
        
        <WritePraxisAi :resources_id="resources_id" allow="microphone" />
    </div>
</template>
<script>
import { getJobList, updateUserStudy } from '@/api/listen'
import { writeSubmit } from '@/api/user'
import WritePraxisAi from "@/components/WritePraxisAi";
export default {
    components: { WritePraxisAi },
    name: 'Praxis',
    data() {
        return {
            resources: [],
            cur: {},
            visible: false,
            answer_show: false,
            total_time: '',
            currentTime: '0:00',
            audio_status: 1,
            checkValue: 0,
            title_status: 1,
            resource_id: 0,
            writing: '',
            src: "",
            jobList: [],
            stu_placeholder: "请输入你的疑问...",
            stu_content: '',
            is_speak: 1,
            canuse: 1,
            speaking: false,
            writting_text: '去写作',
            user_answer: ''
        }
    },
    methods: {
        async onClickLeft() {
            history.back()
        },
        async get_resources() {
            try {
                const user = JSON.parse(localStorage.getItem('user'))
                if (user && user.sid) {
                    const response = await getJobList({ sid: user.sid, resource_id: this.resource_id })
                    console.log(response.data.resources)
                    const data = response.data
                    var jobList = response.data.jobList;
                    var user_answer = jobList[jobList.length - 1].user_answer.split(/[}]/)
                    
                    this.resources = data.resources
                    console.log(data)
                    this.jobList = jobList
                    this.user_answer = JSON.parse(user_answer[0] + "}"),
                    this.writing = this.resources.jobList[0].title
                    this.src = data.resources.file_path
                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        },

        async change_card() {
            this.show = !this.show
            console.log(this.jobList)
        },
        async submit_paper() {
            var data = {
                writing: this.writing
            }
            var resource_id = this.resources_id
            var log_id = this.log_id
            const user = JSON.parse(localStorage.getItem('user'))
            console.log(resource_id)
            const response = await writeSubmit({
                result: JSON.stringify(data),
                sid: user.sid,
                log_id: log_id,
                job_id: this.resources.resources.id,
                resource_id: resource_id
            })
            if (response.code == 100) {
                const resp = await updateUserStudy({ id: this.log_id })
                if (resp.code == 100) {
                    this.$router.push({ name: 'WriteReport', query: { resource_id: this.resources_id } })
                }
            }
        },
        async to_write() {
            this.$router.push({ name: 'Write', query: { resource_id: this.resources_id, log_id: this.log_id } })
        },

    },
    mounted() {
        // 在组件挂载后立即获取任务数据
        // 在组件挂载后立即获取任务数据
        this.job_id = this.$route.query.job_id;
        this.resource_id = this.$route.query.resource_id;
        this.writing = localStorage.getItem('userWriting')
        this.get_resources()
    }
}
</script>
<style scoped>
.praxis-item {
    width: 90%;
    height: auto;
    margin: 0 auto;
    text-align: left;
}

.writing {
    width: 350px;
    height: 150px;
    padding: 12px;
    margin-top: 30px;
}

.stu-writing {
    margin-top: 15px;
    border: 1px solid #8B8B8B;
    border-radius: 15px;
    padding: 30px 15px;
    height: 120px;
}

.stu-writing>textarea {
    width: 100%;
    height: 100%;
    border: none;
}

.answer-desc {
    padding-left: 10px;
    font-size: 14px;
    vertical-align: top;
    display: inline-block;
    width: 300px;
}

.wrap-text {
    width: 90%;
    height: auto;
    margin: 0 auto;
    text-align: left;
    padding: 10px 0;
}

.btn-ok {
    width: 144px;
    height: 45px;
    background: #003F88;
    border-radius: 23px;
    margin: 0 auto;
    line-height: 45px;
    text-align: center;
    color: #FFF;
    margin-top: 100px;
    vertical-align: top;
    display: inline-block;
}

.btn-other {
    width: 144px;
    height: 45px;
    background: #b4b4b6;
    border-radius: 23px;
    margin: 0 25px;
    line-height: 45px;
    text-align: center;
    color: #FFF;
    margin-top: 100px;
    vertical-align: top;
    display: inline-block;
}
</style>