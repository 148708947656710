<template>
  <div class="index">
    <van-nav-bar title="笔下生花" left-text="返回" left-arrow @click-left="onClickLeft" />
    <div class="head" v-if="NewUserResource != [] && NewUserResource != null">
      <div class="model-title">上次学习</div>
      <div class="last-learn">
        <img :src="NewUserResource.cover" mode="aspectFill" class="lesson-cover-img" />
        <div class="lesson-info">
          <div class="lesson-name">[写作]{{ NewUserResource.name }}</div>
          <div class="lesson-time ">{{ NewUserResource.create_time }}更新</div>
        </div>
        <div class="con-to-study" @click="to_praxis(NewUserResource)" :data-index="NewUserResource.id">继续学习</div>
      </div>
    </div>
    <div class="theme-listening">
      <div class="listening-top">
        <div class="listening-title">主题列表</div>
        <div class="model-check" @click="changShow">
          筛选
          <van-icon name="list-switch" color="#FFF" />
        </div>
      </div>
      <div class="listen-item" v-for="(item, index) in listData" :key="index" @click="to_learn(item)">
        <img :src="item.cover" mode="aspectFill" class="listen-cover" />
        <div class="listen-name">[写作]{{ item.name }}</div>
        <div class="listen-desc">{{ item.create_time }}|共{{ item.resource_num }}个资源包</div>
        <div class="listen-count">
          <div class="listening-now">
            <div class="count-detail">{{ item.read_num }}人正在学</div>
          </div>
          <div class="listening-svg" v-if="item.study_num > 0">已学{{ item.study_num }}</div>
          <div class="listening-svg can-check" v-if="item.study_num == 0">加入</div>
        </div>
      </div>
    </div>

    <van-popup v-model="show" position="top" style="border-radius: 0 0 40px 40px">
      <div class="check-main">
        <div class="tea-search">
          <van-search v-model="value" placeholder="请输入搜索关键词" />
        </div>
        <div class="check-item">
          <div class="check-item-title">难易度</div>
          <div class="check-item-lables">
            <div bind:tap="check_level" :class="['lable-item', level === 0 ? 'lable-item-check' : '']" :data-index="0">
              全部
            </div>
            <div bind:tap="check_level" :class="['lable-item', level === 1 ? 'lable-item-check' : '']" :data-index="1">
              初级
            </div>
            <div bind:tap="check_level" :class="['lable-item', level === 2 ? 'lable-item-check' : '']" :data-index="2">
              中级
            </div>
            <div bind:tap="check_level" :class="['lable-item', level === 3 ? 'lable-item-check' : '']" :data-index="3">
              高级
            </div>
          </div>
        </div>

        <div class="check-item">
          <div class="check-item-title">发布时间</div>
          <div class="check-item-lables">
            <div bind:tap="check_time_level" :class="['lable-item', time_level === 0 ? 'lable-item-check' : '']"
              :data-index="0">全部</div>
            <div bind:tap="check_time_level" :class="['lable-item', time_level === 1 ? 'lable-item-check' : '']"
              :data-index="1">一天内</div>
            <div bind:tap="check_time_level" :class="['lable-item', time_level === 2 ? 'lable-item-check' : '']"
              :data-index="2">一周内</div>
            <div bind:tap="check_time_level" :class="['lable-item', time_level === 3 ? 'lable-item-check' : '']"
              :data-index="3">一月内</div>
          </div>
        </div>

        <div class="check-item">
          <div class="check-item-title">主题</div>
          <div class="check-item-lables">
            <div bind:tap="check_subject_cate_id"
              :class="['lable-item', subject_cate_id === 0 ? 'lable-item-check' : '']" :data-index="0">全部</div>
            <div bind:tap="check_subject_cate_id"
              :class="['lable-item', subject_cate_id === item.id ? 'lable-item-check' : '']" :data-index="item.id"
              v-for="(item, index) in subjectCate" :key="index">{{ item.name }}</div>
          </div>
        </div>

        <div class="btn-cancel" bind:tap="onVisibleChange">取消</div>
        <div class="btn-ok" bind:tap="change_list">确定</div>
      </div>
    </van-popup>
    <WriteAi allow="microphone" />
  </div>
</template>
<script>
import { UserResource, getList } from '@/api/listen'
import { saveUserStudyResource } from '@/api/user'
import WriteAi from "@/components/WriteAi";
export default {
  components: { WriteAi },
  name: 'Index',
  data() {
    return {
      NewUserResource: [],
      listData: [],
      show: false,
      level: '',
      time_level: '',
      subject_cate_id: '',
      keyword: '',
    }
  },
  methods: {
    async onClickLeft() {
      history.back()
    },
    async to_praxis(item) {
      try {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user && user.sid) {
          console.log(item)
          const response = await saveUserStudyResource({ sid: user.sid, resource_id: item.id })
          if (response.code == 100) {

            this.$router.push({ name: 'WritePraxis', query: { log_id: response.data, resources_id: item.id } })

          }

        } else {
          console.error('用户未登录或没有找到sid')
        }
      } catch (error) {
        console.error('Login Failed:', error)
      }
    },
    async UserResource() {
      try {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user && user.sid) {
          const response = await UserResource({ sid: user.sid, category_id: 4 })
          const data = response.data
          this.NewUserResource = data
        } else {
          console.error('用户未登录或没有找到sid')
        }
      } catch (error) {
        console.error('Login Failed:', error)
      }
    },
    async getList() {
      try {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user && user.sid) {
          const response = await getList({ sid: user.sid, category_id: 4, level: this.level, subject_cate_id: this.subject_cate_id, keyword: this.keyword })
          const data = response.data
          this.listData = data
        } else {
          console.error('用户未登录或没有找到sid')
        }
      } catch (error) {
        console.error('Login Failed:', error)
      }
    },
    async changShow() {
      this.show = !this.show;
    },
    async to_learn(item) {
      this.$router.push({ name: 'WriteLearn', query: { subject_id: item.id, subject_name: item.name } })
    }
  },
  mounted() {
    // 在组件挂载后立即获取任务数据
    this.UserResource()
    this.getList()
  }
}
</script>
<style scoped>
.head {
  width: 100vw;
  height: 150px;
  background: linear-gradient(to bottom, #E2F2FF, #FFF);
}

.model-title {
  padding: 13px 0 11px 24px;
  font-size: 16px;
  text-align: left;
}

.last-learn {
  width: 90vw;
  height: 85px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 1px rgba(80, 143, 206, 0.1);
  border-radius: 20px 20px 20px 20px;
  margin: 0 auto;
}

.lesson-cover-img {
  width: 58px;
  height: 58px;
  padding: 14px 10px 0 13px;
  vertical-align: top;
  display: inline-block;
  border-radius: 5px;
}

.lesson-info {
  vertical-align: top;
  display: inline-block;
  width: 175px;
  height: 44px;
  padding: 10px 5px 20px 0;
  text-align: left;
}

.lesson-name {
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 9px;
  overflow: hidden;
  height: 30px;
}

.lesson-time {
  font-size: 12px;
  color: #979797;
}

.con-to-study {
  width: 60px;
  height: 85px;
  vertical-align: top;
  display: inline-block;
  line-height: 85px;
  font-size: 12px;
  color: #003F88;
}

.theme-listening {
  width: 100vw;
  height: auto;
  text-align: left;
  /* margin: 0 auto; */
}

.listening-title {
  padding: 13px 0 11px 24px;
  font-size: 16px;
  vertical-align: top;
  display: inline-block;
}

.model-check {
  width: 59px;
  height: 24px;
  background: #000000;
  border-radius: 25px;
  opacity: 0.26;
  vertical-align: top;
  display: inline-block;
  margin-left: 183px;
  margin-top: 15px;
  color: #FFF;
  line-height: 24px;
  padding-left: 21px;
  font-size: 14px;
}

.listen-item {
  width: 90vw;
  height: auto;
  margin: 18px auto;
  border-bottom: 1px #E4E4E4 solid;
  padding-bottom: 20px;
}

.listen-cover {
  width: 90vw;
  height: 150px;
  border-radius: 10px;
}

.listen-name {
  font-size: 14px;
  font-weight: bold;
  padding-top: 10px;
}

.listen-desc {
  font-size: 12px;
  color: #979797;
  padding: 5px 23px 0px 0;
}

.listening-now {
  width: 250px;
  vertical-align: top;
  display: inline-block;
  padding-right: 10px;
}

.count-detail {
  vertical-align: top;
  padding: 5px 5px 0rpx 0rpx;
  font-size: 12px;
  display: inline-block;
  vertical-align: top;
  color: #979797;
}

.listening-svg {
  width: 80px;
  height: 29px;
  vertical-align: top;
  display: inline-block;
  line-height: 29px;
  border: #003F88 solid 1px;
  border-radius: 14px;
  text-align: center;
  margin-top: -12px;
}

.can-check {
  background-color: #003F88;
  color: #FFF;
}

.check-main {
  width: 100%;
  height: auto;
  max-height: 600px;
  overflow-y: scroll;
  border-radius: 0 0 40px 40px;
  background-color: #FFF;
  padding-bottom: 25px;
}

.tea-search {
  width: 360px;
  height: 36px;
  background: #000000;
  border-radius: 25px;
  /* opacity: 0.26; */
  margin: 11px auto;
}

.check-item {
  width: 339px;
  margin: 11px auto;
  height: auto;
  text-align: left;
}

.check-item-title {
  font-size: 12px;
  font-weight: bold;
  text-align: left;
}

.check-item-lables {
  margin-top: 15px;
}

.lable-item {
  vertical-align: top;
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 12px;
  border: solid 1px #F4F8FB;
  background-color: #F4F8FB;
  padding: 2px 5px;
  border-radius: 20rpx;
  font-size: 12px;
  color: #989898;
}

.lable-item-check {
  border: #699FDE solid 1px;
  background-color: #E2F9FF;
  color: #579ACE;
}

.btn-cancel {
  width: 152px;
  height: 39px;
  border-radius: 20px;
  border: 1px solid #828282;
  text-align: center;
  line-height: 39px;
  vertical-align: top;
  display: inline-block;
  margin-left: 22px;
}

.btn-ok {
  width: 152px;
  height: 39px;
  border-radius: 20px;
  color: #FFF;
  background-color: #003F88;
  border: 1px solid #828282;
  text-align: center;
  line-height: 39px;
  vertical-align: top;
  display: inline-block;
  margin-left: 22px;
}
</style>