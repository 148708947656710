/* global dd */
import Vue from 'vue'
import VueRouter from 'vue-router'
import TabBarLayout from '@/layouts/TabBarLayout.vue'
import NormalLayout from '@/layouts/NormalLayout.vue'
import Home from '../views/Home.vue'
import Exam from '../views/Exam.vue'
import Ai from '../views/Ai.vue'
import MapIndex from '../views/MapIndex.vue'
import Etalk from '../views/etalk/Etalk.vue'
import Project from '../views/etalk/Project.vue'
import History from '../views/etalk/History.vue'
import Plant from '../views/Plant.vue'
import Mine from '../views/Mine.vue'
import Login from '../views/Login.vue'
import About from '../views/About.vue'
import Index from '../views/listen/Index.vue'
import ListenLearn from '../views/listen/Learn.vue'
import Praxis from '../views/listen/Praxis'
import Detail from '../views/listen/Detail'
import Report from '../views/listen/Report'
import ReadIndex from '../views/read/Index.vue'
import ReadLearn from '../views/read/Learn.vue'
import ReadPraxis from '../views/read/Praxis'
import ReadDetail from '../views/read/Detail'
import ReadReport from '../views/read/Report'
import WriteIndex from '../views/write/Index.vue'
import Write from '../views/write/Write.vue'
import WriteLearn from '../views/write/Learn.vue'
import WritePraxis from '../views/write/Praxis'
import WriteDetail from '../views/write/Detail'
import WriteReport from '../views/write/Report'
import MicraList from '../views/micra_class/Index'
import MicraDetail from '../views/micra_class/Detail'
import ActiveList from '../views/active/List'
import MyActiveList from '../views/active/MyList'
import StudyList from '../views/study/List'
import ActiveDetail from '../views/active/Detail'
import ChatIndex from '../views/chat/chat'
import NoticeList from '../views/notice/List'
import NoticeExamList from '../views/notice/ExamList'
import NoticeDetail from '../views/notice/Detail'
import Calendar from '../views/Calendar'
import MiniPaper from '../views/exam/MiniPaper'
import PaperNotice from '../views/exam/PaperNotice'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    component: TabBarLayout, // 使用 TabBar 页面的布局组件
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: { requiresAuth: true, title: '首页' } // 需要登录才能访问
      },
      {
        path: '/exam',
        name: 'Exam',
        component: Exam,
        meta: { requiresAuth: true, title: '测试' } // 需要登录才能访问
      },
      {
        path: '/ai',
        name: 'Ai',
        component: Ai,
        meta: { requiresAuth: true, title: 'E-Talk' } // 需要登录才能访问
      },
      {
        path: '/plant',
        name: 'Plant',
        component: Plant,
        meta: { requiresAuth: true, title: '计划' } // 需要登录才能访问
      },
      {
        path: '/mine',
        name: 'Mine',
        component: Mine,
        meta: { requiresAuth: true, title: '我的' } // 需要登录才能访问
      },
    ]
  },
  {
    path: '/listen',
    component: NormalLayout, // 使用 TabBar 页面的布局组件
    children: [
      {
        path: '/listen/index',
        name: 'Index',
        component: Index,
        meta: { requiresAuth: true } // 需要登录才能访问
      },
      {
        path: '/listen/learn',
        name: 'ListenLearn',
        component: ListenLearn,
        meta: { requiresAuth: true } // 需要登录才能访问
      },
      {
        path: '/listen/praxis',
        name: 'Praxis',
        component: Praxis,
        meta: { requiresAuth: true } // 需要登录才能访问
      },
      {
        path: '/listen/detail',
        name: 'Detail',
        component: Detail,
        meta: { requiresAuth: true } // 需要登录才能访问
      },
      {
        path: '/listen/report',
        name: 'Report',
        component: Report,
        meta: { requiresAuth: true } // 需要登录才能访问
      }
    ]
  },
  {
    path: '/read',
    component: NormalLayout, // 使用 TabBar 页面的布局组件
    children: [
      {
        path: '/read/index',
        name: 'Index',
        component: ReadIndex,
        meta: { requiresAuth: true } // 需要登录才能访问
      },
      {
        path: '/read/learn',
        name: 'ReadLearn',
        component: ReadLearn,
        meta: { requiresAuth: true } // 需要登录才能访问
      },
      {
        path: '/read/praxis',
        name: 'ReadPraxis',
        component: ReadPraxis,
        meta: { requiresAuth: true } // 需要登录才能访问
      },
      {
        path: '/read/detail',
        name: 'ReadDetail',
        component: ReadDetail,
        meta: { requiresAuth: true } // 需要登录才能访问
      },
      {
        path: '/read/report',
        name: 'ReadReport',
        component: ReadReport,
        meta: { requiresAuth: true } // 需要登录才能访问
      }
    ]
  },
  {
    path: '/write',
    component: NormalLayout, // 使用 TabBar 页面的布局组件
    children: [
      {
        path: '/write/index',
        name: 'Index',
        component: WriteIndex,
        meta: { requiresAuth: true } // 需要登录才能访问
      },
      {
        path: '/write/write',
        name: 'Write',
        component: Write,
        meta: { requiresAuth: true } // 需要登录才能访问
      },
      {
        path: '/write/learn',
        name: 'WriteLearn',
        component: WriteLearn,
        meta: { requiresAuth: true } // 需要登录才能访问
      },
      {
        path: '/write/praxis',
        name: 'WritePraxis',
        component: WritePraxis,
        meta: { requiresAuth: true, title: '首页' } // 需要登录才能访问
      },
      {
        path: '/write/detail',
        name: 'WriteDetail',
        component: WriteDetail,
        meta: { requiresAuth: true } // 需要登录才能访问
      },
      {
        path: '/write/report',
        name: 'WriteReport',
        component: WriteReport,
        meta: { requiresAuth: true } // 需要登录才能访问
      }
    ]
  },
  {
    path: '/micra_class',
    component: NormalLayout, // 使用 TabBar 页面的布局组件
    children: [
      {
        path: '/micra_class/index',
        name: 'MicraList',
        component: MicraList,
        meta: { requiresAuth: true } // 需要登录才能访问
      },
      {
        path: '/micra_class/detail',
        name: 'MicraDetail',
        component: MicraDetail,
        meta: { requiresAuth: true } // 需要登录才能访问
      },
    ]
  },
  {
    path: '/studymap',
    component: NormalLayout, // 使用 TabBar 页面的布局组件
    children: [
      {
        path: '/studymap/index',
        name: 'MapIndex',
        component: MapIndex,
        meta: { requiresAuth: true } // 需要登录才能访问
      }
    ]
  },
  {
    path: '/active',
    component: NormalLayout, // 使用 TabBar 页面的布局组件
    children: [
      {
        path: '/active/list',
        name: 'ActiveList',
        component: ActiveList,
        meta: { requiresAuth: true } // 需要登录才能访问
      },
      {
        path: '/active/mylist',
        name: 'MyActiveList',
        component: MyActiveList,
        meta: { requiresAuth: true } // 需要登录才能访问
      },
      {
        path: '/active/detail',
        name: 'ActiveDetail',
        component: ActiveDetail,
        meta: { requiresAuth: true } // 需要登录才能访问
      },
    ]
  },
  {
    path: '/study',
    component: NormalLayout, // 使用 TabBar 页面的布局组件
    children: [
      {
        path: '/study/list',
        name: 'StudyList',
        component: StudyList,
        meta: { requiresAuth: true } // 需要登录才能访问
      }
    ]
  },
  {
    path: '/notice',
    component: NormalLayout, // 使用 TabBar 页面的布局组件
    children: [
      {
        path: '/notice/list',
        name: 'NoticeList',
        component: NoticeList,
        meta: { requiresAuth: true } // 需要登录才能访问
      },
      {
        path: '/notice/examlist',
        name: 'NoticeExamList',
        component: NoticeExamList,
        meta: { requiresAuth: true } // 需要登录才能访问
      },
      {
        path: '/notice/detail',
        name: 'NoticeDetail',
        component: NoticeDetail,
        meta: { requiresAuth: true } // 需要登录才能访问
      },
    ]
  },
  {
    path: '/exam',
    component: NormalLayout, // 使用 TabBar 页面的布局组件
    children: [
      {
        path: '/exam/mini_paper',
        name: 'MiniPaper',
        component: MiniPaper,
        meta: { requiresAuth: true } // 需要登录才能访问
      },
      {
        path: '/exam/mini_notice',
        name: 'PaperNotice',
        component: PaperNotice,
        meta: { requiresAuth: true } // 需要登录才能访问
      },
    ]
  },
  {
    path: '/chat/index',
    component: NormalLayout, // 使用 TabBar 页面的布局组件
    children: [
      {
        path: '/chat/chat',
        name: 'ChatIndex',
        component: ChatIndex,
        meta: { requiresAuth: true } // 需要登录才能访问
      },
    ]
  },
  {
    path: '/calendar',
    component: NormalLayout, // 使用 TabBar 页面的布局组件
    children: [
      {
        path: '/calendar',
        name: 'Calendar',
        component: Calendar,
        meta: { requiresAuth: true } // 需要登录才能访问
      },
    ]
  },
  {
    path: '/about',
    component: NormalLayout, // 使用 TabBar 页面的布局组件
    children: [
      {
        path: '/about',
        name: 'About',
        component: About,
        meta: { requiresAuth: true } // 需要登录才能访问
      },
    ]
  },
  {
    path: '/etalk',
    component: NormalLayout, // 使用 TabBar 页面的布局组件
    children: [
      {
        path: '/etalk/index',
        name: 'Etalk',
        component: Etalk,
        meta: { requiresAuth: true } // 需要登录才能访问
      },
      {
        path: '/etalk/project',
        name: 'Project',
        component: Project,
        meta: { requiresAuth: true } // 需要登录才能访问
      },
      {
        path: '/etalk/history',
        name: 'History',
        component: History,
        meta: { requiresAuth: true } // 需要登录才能访问
      },
    ]
  },
  {
    path: '/',
    component: NormalLayout, // 使用普通页面的布局组件
    children: [
      {
        path: '',
        name: 'Login',
        component: Login,
        meta: { title: '登录' }
      }
    ]
  }
]

const router = new VueRouter({
  // mode: 'history', // 使用history模式
  routes
})
function setDingTalkTitle(title) {
  if (window.dd) {
    dd.ready(function () {
      dd.biz.navigation.setTitle({
        title: title
      });
    });
  } else {
    document.title = title;
  }
}
// 全局导航守卫
router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user')

  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    // 如果目标路由需要认证而用户未登录，则跳转到登录页面
    next('/login')
  } else {
    if (to.meta.title) {
      setDingTalkTitle(to.meta.title)
    }
    // 否则继续前往目标路由
    next()
  }
})
export default router
