<!-- src/layouts/TabBarLayout.vue -->

<template>
    <div>
        <van-nav-bar :title="title" left-text="返回" left-arrow @click-left="onClickLeft" />
        <van-nav-bar v-if="msg_list.length == 0" title="下拉加载历史..." />
        <van-pull-refresh v-model="isLoading" @refresh="get_audio">

            <div style="display: none;"><audio controls="controls" :src="new_audio" ref="audioPlayer" autoplay></audio>
            </div>


            <div class=" chat-box" id="chat-box">
                <div class="chat-item" v-for="(item, index) in msg_list" :key="index">
                    <div class="question-box" v-if="item.type == 0 && item.question != ''">{{ item.question }}</div>
                    <div class="question-box" v-if="item.type == 1 && item.lang_time > 0"
                        @click="playSound(item.filepath)">
                        <img src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/audio-msg.png"
                            mode="widthFix" class="start-img" />
                        <span style="font-size: 12px;padding-left: 10px;">{{ item.lang_time }}s</span>
                    </div>
                    <div class="answer-box-text" :id="'chat' + (index + 1)">
                        <div style="margin-bottom: 10px;">{{ item.answer }}</div>
                        <div class="answer-box" v-if="total_count != (index + 1)">
                            <div class="restart-create" @click="to_restart_chat(item)">重新生成</div>
                            <img @click="copyText(item.answer)"
                                src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/listen/copy.png"
                                mode="widthFix" class="copy-text" />
                            <img @click="enCnText(item.answer, index)"
                                src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/fy.png" mode="widthFix"
                                class="copy-text" />
                            <img @click="readText(item.answer)"
                                src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/listen/read_audio.png"
                                mode="widthFix" class="read-audio" />
                        </div>
                        <div class="answer-box" id="myElement" style="height: 360rpx;" v-else>
                            <div class="restart-create" @click="to_restart_chat(item)">重新生成</div>
                            <img @click="copyText(item.answer)"
                                src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/listen/copy.png"
                                mode="widthFix" class="copy-text" />
                            <img @click="enCnText(item.answer, index)"
                                src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/fy.png" mode="widthFix"
                                class="copy-text" />
                            <img @click="readText(item.answer)"
                                src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/listen/read_audio.png"
                                mode="widthFix" class="read-audio" />
                        </div>
                    </div>


                </div>
            </div>
        </van-pull-refresh>
        <!-- 输入框和录音按钮 -->
        <div class="input-box" v-if="is_speak == 1">
            <input class="question" maxlength="-1" v-model="stu_content" @input="inputText"
                placeholder-class="placeholder" :placeholder="stu_placeholder" type="text" />
        </div>
        <div class="input-box" v-else>
            <button class="button-default"
                :style="speaking == true ? 'background: linear-gradient(134deg, #4499ee 0%, #9ce4ff 100%);color: #fff;' : ''"
                @touchstart="recStart" @touchend="recStop">{{ stu_placeholder }}</button>
        </div>
        <!-- 发送信息和切换语音输入的按钮 -->
        <div class="say-box" id="end" v-if="speaking === false">
            <img v-if="stu_content !== '' && canuse == 1 && is_speak === 1" @click="chat" class="send-img"
                src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/send_msg.png" mode="aspectFill" />
            <img v-if="stu_content === '' && is_speak === 1" @click="chang_speak" data-is-speak="0" class="other-img"
                src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/audio.png" mode="scaleToFill" />
            <img v-if="stu_content === '' && is_speak === 0 && speaking === false" @click="chang_hand" data-is-speak="1"
                class="other-img" src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/hand.png"
                mode="scaleToFill" />
        </div>
    </div>
</template>

<script>
import { toChat, en2cn, longtext2voice, longtextSearch, voice2text, voice2textSearch } from '@/api/home'
import { getAIHistory } from '@/api/user'
//必须引入的核心
import Recorder from 'recorder-core'


//引入mp3格式支持文件；如果需要多个格式支持，把这些格式的编码引擎js文件放到后面统统引入进来即可
import 'recorder-core/src/engine/mp3'
import 'recorder-core/src/engine/mp3-engine'
//录制wav格式的用这一句就行
import 'recorder-core/src/engine/wav'

//可选的插件支持项，这个是波形可视化插件

//ts import 提示：npm包内已自带了.d.ts声明文件（不过是any类型）
export default {
    name: 'Ai',
    data() {
        return {
            title: '聊天',
            new_audio: '',
            content: '',
            system: '',
            is_show_ai: false,
            stu_placeholder: '请输入你的疑问...',
            stu_content: '',
            is_speak: 1,
            canuse: 1,
            speaking: false,
            recorder: null,
            mediaStream: null,
            audioChunks: [],
            audioDetails: null,// 新增用于存储音频文件详细信息的状态，
            msg_list: [],
            type: 0,
            stype: 'speak_free',
            lang_time: 0,
            is_history: 0,
            FilePath: '',
            total_count: 0,
            from: 'speak',
            currentSoundIndex: null,
            pageSize: 1,
            pageLimit: 10,
            project_id: 0
        }
    },
    watch: {
        '$route.path': function (newPath) {
            this.active = newPath // 路由变化时更新激活的路由
        }
    },
    methods: {
        async onRefresh() {
            setTimeout(() => {
                alert('刷新成功');
                this.isLoading = false;
                this.pageSize++
                this.get_history()
            }, 1000);

        },
        async to_restart_chat(item) {
            this.content = item.content///语音文本内容
            this.filepath = item.filePath//语音文件地址
            this.type = item.type //消息类型
            this.lang_time = item.lang_time
            this.chat()
        },
        async copyText(item) {
            try {
                await navigator.clipboard.writeText(item);
                alert('文本已复制到剪贴板');
            } catch (err) {
                console.error('复制到剪贴板失败', err);
            }
        },
        async enCnText(item, index) {

            try {
                const response = await en2cn({ userinput: item })
                const data = response.data
                var msg_list = this.msg_list
                if (response.code == 100) {
                    msg_list.forEach((val, key) => {
                        if (key === index) {
                            val.answer += "\n" + data.content
                        }
                    })

                }
                this.msg_list = msg_list

            } catch (error) {
                console.error('Login Failed:', error)
            }
        },
        async readText(item) {
            this.$refs.audioPlayer.pause();
            this.get_audio(item)
        },
        async playSound(url) {
            // 停止当前播放的语音
            if (this.currentSoundIndex !== null && this.currentSoundIndex !== url) {
                this.stopSound(url);
            }
            // 播放新的语音
            const sound = url;
            const audio = new Audio(sound);
            audio.play();
            // 更新当前播放
            console.log(url)
            this.currentSoundIndex = url;
        },
        async stopSound(sound) {
            // 停止语音播放的逻辑
            const audio = new Audio(sound);
            audio.pause();
        },
        async onClickLeft() {
            history.back()
        },
        async inputText() {
            this.content = this.stu_content
            this.type = 0
        },
        async chat() {
            try {
                this.canuse = 0
                const user = JSON.parse(localStorage.getItem('user'))
                if (user && user.sid) {
                    // const response = await toChat({ sid: user.sid, system: this.system, content: this.content })
                    if(this.type == 1){
                        await this.startRecognition()
                    }else{
                        this.filePath = ''
                    }
                    const response = await toChat({
                        userinput: this.content + "Please answer my question in English", //语音文本内容
                        filepath: this.filePath, //语音文件地址
                        type: this.type, //消息类型
                        lang_time: this.lang_time,
                        etalk_cate_id: this.project_id,
                        stype: this.stype, //消息类型
                        from: this.from, //聊天来源
                        sid: user.sid, //学生sid
                        res_id: this.res_id
                    })

                    const data = response.data
                    this.$set(this.msg_list, this.msg_list.length, {
                        answer: data.content,
                        type: this.type,
                        question: this.content,
                        lang_time: this.lang_time,
                        filepath: this.filePath
                    })
                    this.canuse = 1
                    this.total_count = this.msg_list.length
                    console.log(this.msg_list)
                    this.scrollToElement()
                    this.stu_content = ''

                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }

        },
        async scrollToElement() {

            document.getElementById("myElement").scrollIntoView()


        },
        async to_free_talk() { },
        async to_ai_write() { },
        async to_script_talk() { },
        async chang_speak() {
            await this.recOpen();
            this.is_speak = 0;
            this.stu_placeholder = "按住说话...";
            // 确保更新视图
            this.$forceUpdate();
        },

        async chang_hand() {
            this.is_speak = 1;
            this.stu_placeholder = "请输入你的疑问...";
            // 确保更新视图
            this.$forceUpdate();
        },

        change_show_ai() {
            this.is_show_ai = !this.is_show_ai
        },

        async recOpen() {
            //创建录音对象
            this.rec = Recorder({
                type: "mp3" //录音格式，可以换成wav等其他格式
                , sampleRate: 16000 //录音的采样率，越大细节越丰富越细腻
                , bitRate: 16 //录音的比特率，越大音质越好
                , onProcess: (buffers, powerLevel, bufferSampleRate) => {
                    //录音实时回调，大约1秒调用12次本回调
                    //可实时绘制波形，实时上传（发送）数据
                    if (this.wave) this.wave.input(buffers[buffers.length - 1], powerLevel, bufferSampleRate);
                }
            });

            //打开录音，获得权限
            this.rec.open(() => {
                console.log("录音已打开");
                if (this.$refs.recwave) {//创建音频可视化图形绘制对象
                    this.wave = Recorder.WaveDiv({ elem: this.$refs.recwave });
                }
            }, (msg, isUserNotAllow) => {
                //用户拒绝了录音权限，或者浏览器不支持录音
                console.log((isUserNotAllow ? "UserNotAllow，" : "") + "无法录音:" + msg);
            });
            // this.startRecognition(); // 启动语音识别
        },
        async recStart() {
            if (!this.rec) { console.error("未打开录音"); return }
            this.rec.start();
            this.stu_placeholder = '正在说话';
            this.speaking = true;
            console.log("已开始录音");
            if (this.recognition) {
                this.recognition.start(); // 启动语音识别
            }
        },
        async recStop() {
            if (!this.rec) { console.error("未打开录音"); return }
            this.rec.stop((blob, duration) => {
                //blob就是我们要的录音文件对象，可以上传，或者本地播放
                this.recBlob = blob;
                //简单利用URL生成本地文件地址，此地址只能本地使用，比如赋值给audio.src进行播放，赋值给a.href然后a.click()进行下载（a需提供download="xxx.mp3"属性）
                var localUrl = (window.URL).createObjectURL(blob);
                console.log("录音成功", blob, localUrl, "时长:" + duration + "ms");
                this.stu_placeholder = '按住说话';
                this.lang_time = (duration / 1000).toFixed()
                this.type = 1
                this.speaking = false;
                this.upload(blob);//把blob文件上传到服务器
                if (this.recognition) {
                    this.recognition.stop(); // 停止语音识别
                }
                // this.rec.close();//关闭录音，释放录音资源，当然可以不释放，后面可以连续调用start
                // this.rec = null;
            }, (err) => {
                console.error("结束录音出错：" + err);
                // this.rec.close();//关闭录音，释放录音资源，当然可以不释放，后面可以连续调用start
                // this.rec = null;
            });
        },
        // startRecognition() {
        //     const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        //     if (SpeechRecognition) {
        //         this.recognition = new SpeechRecognition();
        //         // 配置和使用 recognition 对象
        //     } else {
        //         console.error('该浏览器不支持语音识别');
        //     }

        //     this.recognition.continuous = true;
        //     this.recognition.interimResults = true;
        //     this.recognition.lang = 'en-US';

        //     this.recognition.onresult = (event) => {
        //         let interimTranscript = '';
        //         let finalTranscript = '';

        //         for (let i = event.resultIndex; i < event.results.length; i++) {
        //             if (event.results[i].isFinal) {
        //                 finalTranscript += event.results[i][0].transcript;
        //             } else {
        //                 interimTranscript += event.results[i][0].transcript;
        //             }
        //         }

        //         this.content = finalTranscript || interimTranscript;
        //     };

        //     this.recognition.onerror = (event) => {
        //         console.error('识别错误: ', event.error);
        //     };

        //     this.recognition.onend = () => {
        //         console.log('识别结束' + this.content);
        //         this.type = 1; //消息类型

        //         this.chat()
        //     };
        // },
        async startRecognition() {
            const res = await voice2text({ url: this.filePath })
            let taskId = res.data.data.taskId;
            let response1;
            let status = 0;
            while (status !== 2) {
                response1 = await voice2textSearch({ task_id: taskId });
                status = response1.data.data.status;

                if (status !== 2) {
                    // 如果 status 还没有变为 2，可以选择添加一个延迟，避免过于频繁的请求
                    await new Promise(resolve => setTimeout(resolve, 1000)); // 延迟1秒
                }
            }
            let templateString = response1.data.data.result;
            let resultString = templateString.replace(/\[[^\]]+\]/g, "");
            this.type = 1; //消息类型
            this.content = resultString
        },
        async upload(blob) {
            try {
                const user = JSON.parse(localStorage.getItem('user'))
                if (user && user.sid) {
                    var form = new FormData();
                    form.append("file", blob, user.sid + "-" + new Date().getTime() + ".mp3"); //和普通form表单并无二致，后端接收到upfile参数的文件，文件名为recorder.mp3
                    form.append("key", "value"); //其他参数

                    var xhr = new XMLHttpRequest();
                    // xhr.open("POST", "https://elang.zju.edu.cn:8082/mobileupload/upload");
                    xhr.open("POST", "https://zju.3dease.com/fcapi/mobileupload/upload");
                    xhr.onreadystatechange = () => {
                        if (xhr.readyState === 4) {
                            if (xhr.status === 200) {
                                // 请求成功，解析响应
                                var response = JSON.parse(xhr.responseText);
                                console.log("上传成功，服务器返回的数据:", response);
                                this.filePath = response.data.url
                                this.chat()
                                // 在这里处理服务器返回的数据
                            } else {
                                // 请求失败，处理错误
                                console.error("上传失败，状态码:", xhr.status);
                                console.error("错误信息:", xhr.statusText);
                            }
                        }
                    };
                    xhr.send(form);

                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        },
        async recPlay() {
            //本地播放录音试听，可以直接用URL把blob转换成本地播放地址，用audio进行播放
            var localUrl = URL.createObjectURL(this.recBlob);
            var audio = document.createElement("audio");
            audio.controls = true;
            document.body.appendChild(audio);
            audio.src = localUrl;
            audio.play(); //这样就能播放了

            //注意不用了时需要revokeObjectURL，否则霸占内存
            setTimeout(function () { URL.revokeObjectURL(audio.src) }, 5000);
        },
        async get_history() {
            try {
                const user = JSON.parse(localStorage.getItem('user'))
                if (user && user.sid) {
                    const response = await getAIHistory({ sid: user.sid, from: this.from, page: this.pageSize, size: this.pageLimit })
                    const data = response.data
                    var msg_list = this.msg_list
                    var history_list = data.list.reverse()
                    var new_msg = history_list.concat(msg_list)
                    this.msg_list = new_msg
                    console.log(this.msg_list)
                    this.totalScroll = 0
                    this.total_count = new_msg.length
                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        },
        /**
* desc: base64对象转blob文件对象
* @param base64  ：数据的base64对象
* @param fileType  ：文件类型 mp3等;
* @returns {Blob}：Blob文件对象
*/
        async base64ToBlob(base64, fileType) {
            var type = ''
            let typeHeader = 'data:application/' + fileType + ';base64,'; // 定义base64 头部文件类型
            let audioSrc = typeHeader + base64; // 拼接最终的base64
            let arr = audioSrc.split(',');
            let array = arr[0].match(/:(.*?);/);
            let mime = (array && array.length > 1 ? array[1] : type) || type;
            // 去掉url的头，并转化为byte
            let bytes = window.atob(arr[1]);
            // 处理异常,将ascii码小于0的转换为大于0
            let ab = new ArrayBuffer(bytes.length);
            // 生成视图（直接针对内存）：8位无符号整数，长度1个字节
            let ia = new Uint8Array(ab);
            for (let i = 0; i < bytes.length; i++) {
                ia[i] = bytes.charCodeAt(i);
            }
            return new Blob([ab], {
                type: mime
            });
        },

        async get_audio(str) {
            const response = await longtext2voice({ userinput: str })

            console.log(response.data.data.taskId)
            // 轮询调用，直到 status 为 2
            let taskId = response.data.data.taskId;
            let response1;
            let status = 0;
            while (status !== 2) {
                response1 = await longtextSearch({ task_id: taskId });
                status = response1.data.data.status;

                if (status !== 2) {
                    // 如果 status 还没有变为 2，可以选择添加一个延迟，避免过于频繁的请求
                    await new Promise(resolve => setTimeout(resolve, 1000)); // 延迟1秒
                }
            }
            this.new_audio = response1.data.data.resultUrl
            console.log(response1)
            // this.new_audio = "data:audio/mp3;base64," + response.data.audio
            // this.$refs.audioPlayer.play();
            // let audioBlob = this.base64ToBlob(response.data.audio, "mp3");
            // let audio = document.getElementById("audioId");
            // audio.src = window.URL.createObjectURL(audioBlob);
            // audio.addEventListener("canplay", () => {
            //     window.URL.revokeObjectURL(audio.src);
            // });
        }
    },
    mounted() {
        // 在组件挂载后立即获取任务数据
        // 检查浏览器是否支持 SpeechRecognition API
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (!SpeechRecognition) {
            alert('抱歉，你的浏览器不支持语音识别功能。');
        }
        this.system = this.$route.query.system;
        this.stu_content = this.$route.query.content;
        this.title = this.$route.query.chat_title;
        this.from = this.$route.query.from;
        this.stype = this.$route.query.stype;
        this.type = this.$route.query.type;
        this.filePath = this.$route.query.filePath;
        this.lang_time = this.$route.query.lang_time;
        this.res_id = this.$route.query.res_id;
        this.is_history = this.$route.query.is_history;
        this.project_id = this.$route.query.project_id
        if (this.is_history == 1) {
            this.get_history()
        } else {
            this.chat()
        }
    }
}
</script>


<style scoped>
.chat-box {
    width: 100vw;
    height: 90vh;
    overflow-y: scroll;
    padding-bottom: 20px;
}

.question-box {
    height: auto;
    max-width: 280px;
    min-width: 100px;
    min-height: 30px;
    background: linear-gradient(118deg, #4499EE 0%, #9CE4FF 100%);
    border-radius: 20px 0px 20px 20px;
    font-size: 14px;
    color: #FFF;
    padding: 5px 10px;
    line-height: 30px;
    text-align: center;
    float: right;
    margin: 20px;
}

.answer-box-text {
    height: auto;
    max-width: 280px;
    min-height: 30px;
    font-size: 14px;
    padding: 5px 10px;
    line-height: 1.5;
    text-align: left;
    float: left;
    background-color: #F6F6F6;
    margin-left: 20px;
    border-radius: 10px;
}

.answer-box {
    padding-top: 10px;
    border-top: 1px solid #e4e3e3;
    width: 100%;
    height: 100rpx;
    line-height: 50px;
}

.restart-create {
    width: 20%;
    font-size: 12px;
    height: 40px;
    vertical-align: top;
    display: inline-block;
    margin-right: 45%;
    line-height: 40px;
}

.copy-text {
    width: 14px;
    height: 15px;
    padding-top: 10px;
    vertical-align: top;
    display: inline-block;
    padding-right: 13px;
}

.read-audio {
    width: 18px;
    height: 15px;
    padding-top: 10px;
    vertical-align: top;
    display: inline-block;
}

.ai-ques-item {
    width: auto;
    height: 30px;
    border-radius: 15px;
    border: 1rpx solid rgb(221, 219, 219);
    line-height: 30px;
    text-align: center;
    padding: 0 10px;
    font-size: 12px;
    margin-bottom: 10px;
}

.start-img {
    width: 20px;
    height: 20px;
    vertical-align: top;
    display: inline-block;
    padding-top: 5px;
}

.input-box {
    width: 80vw;
    height: 42px;
    background: #ffffff;
    border-radius: 16px;
    position: fixed;
    bottom: 0;
    left: 10vw;
    border: none;
}

.question {
    padding: 10px 60px 10px 15px;
    font-size: 14px;
    color: #000;
    border: none;
}

.placeholder {
    font-size: 14px;
    color: #989898;
    padding: 3px 15px;
}

.say-box {
    width: 60px;
    height: 42px;
    background: #ffffff;
    border-radius: 15px;
    position: fixed;
    bottom: 0;
    left: 293px;
}

.say-img {
    width: 10px;
    height: 15px;
    padding: 7px 25px;
}

.send-img {
    width: 53px;
    height: 30px;
    position: fixed;
    bottom: 10px;
    left: 293px;
}

.other-img {
    width: 25px;
    height: 25px;
    position: fixed;
    bottom: 10px;
    left: 326px;
}

.button-default {
    background: #fff;
    /* color: #000000; */
    height: 42px;
    width: 323px;
    line-height: 25px;
    text-align: center;
    border-radius: 21;
    font-size: 14px;
    font-weight: bold;
    border: none;
    position: fixed;
    bottom: 10px;
    left: 10vw;
    border-radius: 16px;
}

.button-speaking {
    background: linear-gradient(134deg, #4499ee 0%, #9ce4ff 100%);
    color: #fff;
    height: 42px;
    width: 333px;
    line-height: 25px;
    /* text-align: center; */
    border-radius: 21px;
    font-size: 14px;
    font-weight: bold;
    z-index: 100;
    border: none;
    position: fixed;
    bottom: 45px;
    left: 10vw;
    border-radius: 16px;
}

.btn-test {
    position: absolute;
    top: 0;
}

.btn-test2 {
    position: absolute;
    left: 0;
    top: 0;
}
</style>
