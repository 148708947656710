<template>
    <div class="ai">
        <van-nav-bar title="E-Talk" left-text="返回" left-arrow @click-left="onClickLeft" />
        <div class="main">
            <div class="wall"></div>
            <div class="project-box">
                <div class="box-info">
                    <div class="item-box" @click="to_ai('speak_free', '自由对话')">
                        <div class="item-title">自由对话</div>
                        <div class="item-title-en">Dialogue is free</div>
                    </div>
                </div>
                <div class="box-info1">
                    <div class="item-box" @click="to_project">
                        <div class="item-title">主题对话</div>
                        <div class="item-title-en">Thematic conversations</div>
                    </div>
                </div>
                <div class="box-info2">
                    <div class="item-box" @click="to_ai('speak_free', '朗读练习')">
                        <div class="item-title">朗读练习</div>
                        <div class="item-title-en">Reading aloud practice</div>
                    </div>
                </div>
            </div>

            <div class="jiluhuifang" @click="to_history">记录回放</div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'Ai',
    data() {
        return {

        }
    },
    methods: {
        async onClickLeft() {
            history.back()
        },
        async to_ai(stype, chat_title){
            this.$router.push({ name: 'ChatIndex', query: { project_id: 0, is_history: 0, lang_time: 0, filePath: '', type: 0, stype: stype, from: 'speak_free', chat_title: chat_title, system: '假如你是一名语文老师', content: '' } })
        },
        to_project(){
            this.$router.push({ name: 'Project'})
        },
        to_history(){
            this.$router.push({ name: 'History'})
        }
    },
    mounted() {
        // 在组件挂载后立即获取任务数据

    }
}
</script>
<style scoped>
/* pages/etalk/index.wxss */
.main {
    background: url(https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/index/etalk-bg.png);
    width: 100vw;
    height: 100vh;
    background-size: cover;
}



.project-box {
    width: 90vw;
    height: 330px;
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 3px 6px 1px #abe1f3;
    padding: 25px 0 25px 0;
    border-radius: 32px;
    margin: 0 auto 20px auto;
    position: relative;
}

.box-info {
    width: 320px;
    height: 110px;
    margin: 0px auto 5px auto;
    background: url('https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/auto-talk-bg.png?v1');
    background-size: 100% 100%;
    /* 拉伸图片以填满容器 */
    background-repeat: no-repeat;
}

.box-info1 {
    width: 320px;
    height: 110px;
    margin: 5px auto;
    background: url('https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/project-talk-bg.png?v1');
    background-size: 100% 100%;
    /* 拉伸图片以填满容器 */
    background-repeat: no-repeat;
}

.box-info2 {
    width: 320px;
    height: 110px;
    margin: 5px auto 0px auto;
    background: url('https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/script-talk-bg.png?v1');
    background-size: 100% 100%;
    /* 拉伸图片以填满容器 */
    background-repeat: no-repeat;
}

.item-box {
    width: 320px;
    height: 110px;
    z-index: 1000;
    line-height: 25px;
    padding-top: 25px;
    text-align: left;
}

.item-title {
    color: #000;
    font-size: 16px;
    font-weight: bold;
    padding-left: 32px;
}

.item-title-en {
    color: #000000;
    font-size: 12px;
    padding-left: 32px;
}

.jiluhuifang {
    width: 100vw;
    height: 25px;
    text-align: center;
    color: #3B3B3B;
    font-size: 14px;
    line-height: 25px;
}
</style>