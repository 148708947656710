import request from './index'

// 获取统计信息
export function getPaperList(sid) {
    return request({
        url: '/paper/getUserMiniPaperList',
        method: 'get',
        params: { sid }
    })
}

// 获取统计信息
export function getDateList(data) {
    return request({
        url: '/user_sign/getDateList',
        method: 'post',
        data
    })
}

// 获取首页快捷菜单
export function getMenuList() {
    return request({
        url: '/subject/getCategoryList',
        method: 'get',
    })
}


// 获取首页正在学
export function getNewUserResourceList(sid) {
    return request({
        url: '/resources/getNewUserResourceList',
        method: 'get',
        params: { sid }
    })
}

// 获取首页推荐学
export function HotResouceList(sid) {
    return request({
        url: '/resources/getHotUserResourceList',
        method: 'get',
        params: { sid }
    })
}

//获取mini试卷
export function getMiniPaperByListen(sid) {
    // paper/getMiniPaperByListen
    return request({
        url: '/paper/getMiniPaperByListen',
        method: 'get',
        params: { sid }
    })
}

//获取mini试卷
export function StartMiniPaper(sid) {
    // paper/getMiniPaperByListen
    return request({
        url: '/paper/getStartMiniPaper',
        method: 'get',
        params: { sid }
    })
}
//开始考试
export function StartMiniPaperTo(sid) {
    // paper/getMiniPaperByListen
    return request({
        url: '/paper/startMiniPaper',
        method: 'get',
        params: { sid }
    })
}
//获取mini试卷
export function getMiniPaperByRead(sid) {
    // paper/getMiniPaperByListen
    return request({
        url: '/paper/getMiniPaperByRead',
        method: 'get',
        params: { sid }
    })
}

//提交听力答案
export function ListenMiniSubmit(data) {
    return request({
        url: '/paper/paperListenMiniSubmit',
        method: 'post',
        data
    })
}

//提交阅读答案
export function ReadMiniSubmit(data) {
    return request({
        url: '/paper/paperReadMiniSubmit',
        method: 'post',
        data
    })
}