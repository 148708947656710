<template>
    <div class="ai">
        <van-nav-bar title="记录回放" left-text="返回" left-arrow @click-left="onClickLeft" />
        <div class="main">
            <div class="title" v-if="InfoList.length > 0">今天</div>
            <div class="item" v-for="(item, index) in InfoList" :key="index" @click="to_ai_project(item)">
                <img class="img" :src="item.icon_img" mode="widthFix" />
                <div class="item-center">
                    <div class="item-title">{{ item.name }}</div>
                    <div class="item-history">{{ item.question }}</div>
                </div>
                <div class="history-time">{{ item.create_time }}</div>
            </div>


            <div class="history-title" v-if="OldInfoList.length > 0">更早记录</div>
            <div class="item" v-for="(item, index) in OldInfoList" :key="index" @click="to_ai_project(item)">
                <img class="img" :src="item.icon_img" mode="widthFix" />
                <div class="item-center">
                    <div class="item-title">{{ item.name }}</div>
                    <div class="item-history">{{ item.question }}</div>
                </div>
                <div class="history-time">{{ item.create_time }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import { getOldInfoList, getNewInfoList } from '@/api/user'
export default {
    name: 'Ai',
    data() {
        return {
            InfoList: [],
            OldInfoList: []
        }
    },
    methods: {
        async onClickLeft() {
            history.back()
        },
        async to_ai_project(item) {
            this.$router.push({ name: 'ChatIndex', query: { project_id: 0, is_history: 1, lang_time: 0, filePath: '', type: 0, stype: item.from, from: item.from, chat_title: '主题对话', system: '假如你是一名语文老师', content: '' } })
        },
        async getOldInfoList() {
            try {
                const user = JSON.parse(localStorage.getItem('user'))
                if (user && user.sid) {
                    const response = await getOldInfoList({ sid: user.sid })
                    const data = response.data
                    this.OldInfoList = data
                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        },
        async getNewInfoList() {
            try {
                const user = JSON.parse(localStorage.getItem('user'))
                if (user && user.sid) {
                    const response = await getNewInfoList({ sid: user.sid })
                    const data = response.data
                    this.InfoList = data
                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        },
    },
    mounted() {
        // 在组件挂载后立即获取任务数据
        this.getNewInfoList()
        this.getOldInfoList()
    }
}
</script>
<style scoped>
/* pages/etalk/index.wxss */
/* otherPage/pages/etalk/project.wxss */

.ai {
    width: 100vw;
    height: 100vh;
    background-color: #F8F8F8;
    align-items: left;
}

.main {
    width: 90vw;
    height: 100vh;
    background-color: #F8F8F8;
    align-items: left;
    margin: 20px auto;
}

.title {
    color: #3D3D3D;
    font-size: 16px;
    font-weight: bold;
    padding-left: 10px;
    text-align: left;
    margin-top: 10px;
}

.history-title {
    color: #3D3D3D;
    font-size: 16px;
    font-weight: bold;
    padding-left: 10px;
    margin-top: 15px;
    text-align: left;
}

.item {
    width: 90vw;
    height: 70px;
    background: #FFFFFF;
    border-radius: 10px;
    margin-top: 10px;
}

.img {
    width: 30px;
    height: 30px;
    padding: 21px 12px 0 15px;
    vertical-align: top;
    display: inline-block;
}

.item-center {
    vertical-align: top;
    display: inline-block;
    width: 200px;
    padding-top: 15px;
}

.item-title {
    font-size: 14px;
    padding-bottom: 4px;
    text-align: left;
}

.item-history {
    font-size: 12px;
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.history-time {
    vertical-align: top;
    display: inline-block;
    width: 73px;
    text-align: left;
    font-size: 12px;
    line-height: 70px;
}
</style>