<template>
    <div class="plant">
        <van-nav-bar title="学习日历" left-text="返回" left-arrow @click-left="onClickLeft" />
        <div class="can">
            <div class="calendar-box">
                <div class="calendar-top">
                    <div class="study-info">{{ countTask.today_minutes }}<span class="study-time">/{{
                        countTask.plan_minutes }}min</span></div>
                    <div class="study-other">
                        <div class="study-status">
                            学习目标（
                            <span v-if="countTask.today_minutes < countTask.plan_minutes">未完成</span>
                            <span v-if="countTask.today_minutes >= countTask.plan_minutes">完成</span>
                            ）
                        </div>
                        <!-- <div class="share">分享</div> -->
                    </div>
                </div>
                <div class="calendar-info">
                    <div class="calendar">
                        <div class="calendar-header">
                            <div class="chevron-left">
                                <van-icon name="arrow-left" color="#606060" @click="prevMonth" />
                            </div>
                            <span style="font-size: 12px;">{{ year }}年{{ month }}月</span>
                            <div class="chevron-left">
                                <van-icon name="arrow" color="#606060" @click="nextMonth" />
                            </div>
                        </div>
                        <div class="calendar-body">
                            <div class="weekdays">
                                <span>日</span>
                                <span>一</span>
                                <span>二</span>
                                <span>三</span>
                                <span>四</span>
                                <span>五</span>
                                <span>六</span>
                            </div>
                            <div class="days">
                                <block v-for="(item, index) in days" :key="index">
                                    <div bindtap="signIn" class="day">
                                        <span style="width: 100%;height: 100%;" v-if="!item.is_today">{{ item.day
                                            }}</span>
                                        <span style="width: 100%;height: 100%;color: #0099FF;font-size: 12px;"
                                            v-if="item.is_today">今天</span>
                                        <div class="check" v-if="item.signed">√</div>
                                        <div class="check" v-if="!item.signed"></div>
                                    </div>
                                </block>
                            </div>
                        </div>
                    </div>
                    <div class="calendar-bottom">
                        <image src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/calendar/calendar.png"
                            mode="widthFix" class="calendar-img" />
                        <div class="signIn">本月已连续打卡{{ maxContinuousDays }}天，累计打卡{{ countSign }}天</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 今日任务 -->
        <div class="learn-model" v-if="taskList.length > 0">
            <div class="model-start">
                <div class="model-title">今日任务</div>
            </div>

            <div class="learn-item" v-for="(item, index) in taskList" :key="index">
                <van-image fit="contain" :src="item.cover" class="learn-img" />
                <div class="learn-item-info">
                    <div class="learn-item-title">
                        <span v-if="item.category_id == 1">[听力]</span>
                        <span v-if="item.category_id == 2">[阅读]</span>
                        <span v-if="item.category_id == 4">[写作]</span>
                        {{ item.name }}
                    </div>
                    <div class="learn-item-update-time">{{ item.create_time }}更新</div>
                </div>
                <div class="btn-learn" @click="to_praxis(item)">进入学习</div>
            </div>
        </div>
        <!-- 今日任务 -->
    </div>
</template>
<script>
import { getTaskList, getDateList, getUserStudy, saveUserStudyResource } from '@/api/user'
export default {
    name: 'Plant',
    data() {
        return {
            year: 2024,
            month: 1,
            days: [],
            day: 0,
            signedDays: [], // 已签到的天数，
            maxContinuousDays: 0,
            taskList: [],
            countTask: [],
            countSign: 0
        }
    },
    methods: {
        async getTask() {
            try {
                const user = JSON.parse(localStorage.getItem('user'))
                if (user && user.sid) {
                    this.userInfo = user
                    const response = await getTaskList({ sid: user.sid })
                    this.taskList = response.data
                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        },
        async getSignedDays() {

            try {
                const user = JSON.parse(localStorage.getItem('user'))
                if (user && user.sid) {
                    let now_date = this.year + '' + this.month
                    const response = await getDateList({ sid: user.sid, dates: now_date })
                    this.signedDays = response.data
                    this.countSign = response.data.length
                    this.calculateContinuousDays(this.signedDays)
                    this.generateCalendar();
                    // 确保更新视图
                    this.$forceUpdate();
                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                this.signedDays = []
                this.countSign = 0
                this.$forceUpdate();
                console.error('Login Failed:', error)
            }
        },
        async getUserStudy() {
            try {
                const user = JSON.parse(localStorage.getItem('user'))
                if (user && user.sid) {
                    const response = await getUserStudy({ sid: user.sid })
                    this.countTask = response.data
                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        },
        async generateCalendar() {
            let year = this.year
            let month = this.month
            let signedDays = this.signedDays
            const now = new Date()
            var day = now.getDate()
            var now_month = now.getMonth() + 1;
            if (now_month < 10) {
                now_month = '0' + now_month
            }
            if (day < 10) {
                day = '0' + day
            }
            let now_date = now.getFullYear() + '-' + now_month + '-' + day

            const firstDay = new Date(year, month - 1, 1).getDay();
            const daysInMonth = new Date(year, month, 0).getDate();
            let days = [];
            for (let i = 0; i < firstDay; i++) {
                days.push({
                    day: '',
                    signed: false
                });
            }
            console.log(signedDays)
            for (let i = 1; i <= daysInMonth; i++) {
                if (i < 10) {
                    i = '0' + i
                }
                const date = `${year}-${month}-${i}`;
                let is_today = false
                if (now_date === date) {
                    is_today = true
                }
                days.push({
                    day: i,
                    date,
                    signed: signedDays.includes(date),
                    is_today: is_today
                });
            }
            console.log(days)
            this.days = days
            // 确保更新视图
            this.$forceUpdate();
        },
        //计算连续打卡次数
        async calculateContinuousDays(checkinDates) {
            checkinDates.sort((a, b) => new Date(a) - new Date(b));
            let continuousDays = 1;
            let maxContinuousDays = 1;
            for (let i = 1; i < checkinDates.length; i++) {
                const prevDate = new Date(checkinDates[i - 1]);
                const currentDate = new Date(checkinDates[i]);
                const diff = (currentDate - prevDate) / (1000 * 60 * 60 * 24);
                if (diff === 1) {
                    continuousDays += 1;
                    maxContinuousDays = Math.max(maxContinuousDays, continuousDays);
                } else if (diff > 1) {
                    continuousDays = 1;
                }
            }
            this.maxContinuousDays = maxContinuousDays

        },
        async prevMonth() {
            let year = this.year;
            let month = this.month;
            month--;
            if (month < 1) {
                month = 12;
                year--;
            }
            if (month < 10) {

                month = '0' + month

            }

            this.year = year
            this.month = month

            this.getSignedDays();
            // 确保更新视图
            this.$forceUpdate();
        },
        async nextMonth() {
            let year = this.year;
            let month = this.month;
            month++;
            if (month > 12) {
                month = 1;
                year++;
            }
            if (month < 10) {

                month = '0' + month

            }
            this.year = year
            this.month = month
            this.getSignedDays();
            // 确保更新视图
            this.$forceUpdate();

        },
        async onClickLeft() {
            history.back()
        },
        async to_praxis(item) {
            try {
                const user = JSON.parse(localStorage.getItem('user'))
                if (user && user.sid) {
                    console.log(item)
                    const response = await saveUserStudyResource({ sid: user.sid, resource_id: item.resource_id })
                    if (response.code == 100) {
                        if (item.category_id == 1) {
                            this.$router.push({ name: 'Praxis', query: { log_id: response.data, resources_id: item.resource_id } })
                        }
                        if (item.category_id == 2) {
                            this.$router.push({ name: 'ReadPraxis', query: { log_id: response.data, resources_id: item.resource_id } })
                        }
                        if (item.category_id == 4) {
                            this.$router.push({ name: 'WritePraxis', query: { log_id: response.data, resources_id: item.resource_id } })
                        }
                    }

                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        }

    },
    mounted() {
        // 在组件挂载后立即获取任务数据
        var now = new Date();
        var year = now.getFullYear(); // 获取当前年份
        var month = now.getMonth() + 1; // 获取当前月份
        if (month < 10) {
            month = '0' + month
        }
        this.year = year
        this.month = month
        this.getTask()
        this.getSignedDays();
        this.getUserStudy()
    }
}
</script>
<style scoped>
.can {
    width: 100vw;
    height: 405px;
    background: linear-gradient(180deg, #E2F2FF 0%, #FFFFFF 100%);
}

.calendar-box {
    background-image: url('https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/calendar/sign_calendar.png?v=1');
    background-repeat: no-repeat;
    background-size: cover;
    width: 340px;
    height: 404px;
    margin: 0 auto;
    z-index: 100;
}

.calendar-top {
    width: 322px;
    height: 61px;
    padding: 51px 24px 0 27px;
    text-align: left;
}

.study-info {
    width: 322px;
    font-size: 22px;
    height: 29px;
    font-weight: bold;
    color: #FFFFFF;
    padding-top: 8px;
}

.study-other {
    width: 322px;
    font-size: 14px;
    height: 23px;
    color: #FFFFFF;
    line-height: 23px;
}

.study-status,
.share {
    vertical-align: top;
    display: inline-block;
}

.study-status {
    width: 257px;
}

.study-time {
    font-size: 12px;
    font-weight: normal;
}

.calendar-info {
    width: 322px;
    height: 270px;
    background: #FFFFFF;
    border-radius: 12px;
    margin: 10px auto;
    overflow: hidden;
}

.calendar {
    width: 282px;
    padding: 14px 20px 0px 20px;
    border-radius: 5px;
    height: 230px;
}

.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.chevron-left {
    width: 100px;
}

.calendar-body {
    display: flex;
    flex-direction: column;
}

.weekdays {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    text-align: center;
}

.days {
    display: flex;
    flex-wrap: wrap;
    /* 使天数自动换行 */
}

.day {
    width: 40px;
    /* 每个天数占一周的1/7 */
    padding: 3px 0;
    height: 24px;
    text-align: center;
    cursor: pointer;
    /* 包括内边距和边框在内 */
    font-size: 12px;
}

.day.signed {
    color: #69DC60;
    border-radius: 50%;
}

.check {
    color: #69DC60;
    width: 100%;
    height: 15px;
    font-size: 12px;
    font-weight: bold;
}

.calendar-bottom {
    width: 282px;
    height: 20px;
    position: relative;
    bottom: 5px;
    left: 25px;
}

.calendar-img {
    width: 14px;
    vertical-align: top;
    display: inline-block;
    padding-top: 3px;
}

.signIn {
    width: 265px;
    height: 20px;
    line-height: 20px;
    color: #686868;
    font-size: 12px;
    vertical-align: top;
    display: inline-block;
    padding-left: 3px;
}


.learn-model {
    width: 330px;
    height: auto;
    padding: 0 22px;
    padding-bottom: 50px;
}

.model-start {
    width: 330px;
    height: auto;
    margin: 0 auto;
    line-height: 40px;
    margin-top: 30px;
    text-align: left;
}

.model-title {
    vertical-align: top;
    display: inline-block;
    width: 110px;
    text-align: left;
    font-size: 16px;
}

.model-more {
    vertical-align: top;
    display: inline-block;
    width: 200px;
    text-align: right;
    font-size: 12px;
}

.model-icon {
    vertical-align: top;
    display: inline-block;
    padding-top: 12px;
}


.learn-item {
    width: 330px;
    height: 85px;
    border-style: solid;
    box-shadow: 0px 2px 10px 1px rgba(80, 143, 206, 0.1);
    border-width: 0px;
    margin: 11px 0;
    border-radius: 20px;
    display: flex;
    background-color: #FFFFFF;
}

.learn-img {
    vertical-align: top;
    display: inline-block;
    width: 58px;
    height: 58px;
    margin: 14px 10px 13px 13px;
}

.learn-item-info {
    vertical-align: top;
    display: inline-block;
    width: 190px;
    height: 50px;
    line-height: 25px;
    padding: 10px 0;
}

.learn-item-title {
    font-size: 14px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 20px;
}

.learn-item-update-time {
    font-size: 12px;
    color: #979797;
    padding-top: 15px;
}

.btn-learn {
    vertical-align: top;
    display: inline-block;
    color: #003F88;
    font-size: 12px;
    height: 85px;
    line-height: 85px;
}
</style>