<template>
  <div class="main">
    <div class="plant">
      <div class="top-title">提升计划</div>
      <div class="now-stu-level" v-if="is_has_plant == 2">
        <div class="now-stu_level">LV.{{ level }}</div>
        <div class="level-line">
          <div class="has-finish" style="width: 20%;"></div>
          <div class="no-finish"></div>
        </div>
        <div class="to-stu_level">LV.{{ aim_level }}</div>
      </div>

      <!-- 统计数据开始 -->
      <div class="content-today-count" v-if="is_has_plant == 2">
        <div class="today-count-item">
          <div class="count-one">{{ countTask.total_finish_count }}</div>
          <div class="count-two">/{{ countTask.total_task_count }}个</div>
          <div class="count-title">完成计划</div>
        </div>
        <div class="today-count-item">
          <div class="count-one">{{ countTask.today_minutes }}</div>
          <div class="count-two">/{{ countTask.plan_minutes }}min</div>
          <div class="count-title">今日时长</div>
        </div>
        <div class="today-count-item">
          <div class="count-one">{{ countTask.month_minutes }}</div>
          <div class="count-two">h</div>
          <div class="count-title">累计时长</div>
        </div>
      </div>
      <!-- 统计数据结束 -->
      <div class="second-lable" v-if="is_has_plant == 1">慧学见证你的每一个进步瞬间</div>
      <div class="plant-step" v-if="is_has_plant == 1">
        <div class="first-step first-step-ed">1</div>
        <div class="center-line center-line-ed"></div>
        <div class="first-step first-step-ed">2</div>
        <div class="center-line"></div>
        <div class="first-step">3</div>
      </div>
      <div class="step-name" v-if="is_has_plant == 1">
        <div class="step-item">等级测评</div>
        <div class="step-item">设置目标</div>
        <div class="step-item">获取计划</div>
      </div>
    </div>
    <div class="level-info" v-if="is_has_plant == 1">
      <div class="level-info-top">
        <div class="now-level">当前等级：Lv.0</div>
        <div class="restart-exam">等级不准，重新测评</div>
      </div>
      <div class="level-has">
        <div class="level-has-item">
          <div class="level-lable">√</div>
          <div class="has-info">基本的日常生活沟通以及简单例行的工作</div>
        </div>
        <div class="level-has-item">
          <div class="level-lable">√</div>
          <div class="has-info">基本的日常生活沟通以及简单例行的工作</div>
        </div>
        <div class="level-has-item">
          <div class="level-lable">√</div>
          <div class="has-info">基本的日常生活沟通以及简单例行的工作</div>
        </div>
      </div>
      <div class="set-object" bind:tap="set_plant_second">设定目标</div>
    </div>
    <div class="today-task" v-if="is_has_plant == 2">
      <div class="level-info-top">
        <div class="now-level" style="font-size: 16px;">
          今日任务
          <span style="font-size: 16px;font-weight: normal;" v-if="countTask.today_minutes < countTask.plan_minutes">
            (未完成)
          </span>
          <span style="font-size: 16px;font-weight: normal;"
            v-if="countTask.today_minutes >= countTask.plan_minutes">(已完成)
          </span>
          <div style="color:#848484;font-size:12px;font-weight:normal">
            {{ countTask.today_minutes }}min/{{ countTask.plan_minutes }}min</div>
        </div>
        <div class="restart-exam plant-manger" style="width: 90px;" bind:tap="to_set">计划管理</div>
      </div>
      <div class="level-has">
        <div class="left">
          <div class="left-title">视频课</div>
          <div class="left-num">5</div>
          <div class="left-bottom"></div>
        </div>
        <div class="right">
          <div class="left-title">习题</div>
          <div class="left-num">6</div>
          <div class="right-bottom"></div>
        </div>
      </div>
      <div class="set-object">去学习</div>
    </div>
    <div class="learn-bottom" v-if="is_has_plant==2">
      <div class="model-title">慧学相伴</div>
      <div class="learn-item">
        <div class="item-left">
          <div class="item-left-title">知识地图</div>
          <div class="item-left-finish">已解锁知识点：35%</div>
          <div class="item-left-finish-box">
            <div class="has-finish" style="width: 35%;"></div>
            <div class="no-finish"></div>
          </div>
        </div>
        <img src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/map.png" mode="widthFix"
          class="item-right-img" />
      </div>
      <div class="learn-item">
        <div class="item-left">
          <div class="item-left-title" style="color: #C4C4C4;">自适应测试</div>
          <div class="item-left-finish">距下次测试还有13个学时</div>
          <div class="item-left-finish-box">
            <div class="has-finish" style="width: 35%;"></div>
            <div class="no-finish"></div>
          </div>
        </div>

        <div class="item-right-btn">去测评</div>
      </div>
    </div>
  </div>
</template>
<script>
import { getPlant } from '@/api/plant'
import { getUserStudy } from '@/api/home'
export default {
  name: 'Plant',
  data() {
    return {
      is_has_plant: 1,
      level: 1,
      aim_level: 1,
      countTask: {
        total_finish_count: 1,
        total_task_count: 1,
        today_minutes: 0,
        plan_minutes: 0
      }
    }
  },
  methods: {
    async getPlant() {
      try {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user && user.sid) {
          const response = await getPlant(user.sid)
          const data = response.data
          this.is_has_plant = 2
          this.level = data.level
          this.aim_level = data.aim_level
        } else {
          console.error('用户未登录或没有找到sid')
        }
      } catch (error) {
        console.error('Login Failed:', error)
      }
    },
    async getUserStudy() {
      try {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user && user.sid) {
          const response = await getUserStudy(user.sid)
          const data = response.data
          this.countTask = {
            total_finish_count: data.total_finish_count,
            total_task_count: data.total_task_count,
            today_minutes: data.today_minutes,
            plan_minutes: data.plan_minutes
          }
        } else {
          console.error('用户未登录或没有找到sid')
        }
      } catch (error) {
        console.error('Login Failed:', error)
      }
    },
  },
  mounted() {
    // 在组件挂载后立即获取任务数据
    this.getPlant()
    this.getUserStudy()
  }
}
</script>
<style scoped>
.plant {
  width: 100%;
  height: 280px;
  background-image: url('https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/index/plant.png');
  background-size: cover;
  overflow: hidden;
  position: absolute;
  top: 0;
}

.top-title {
  width: 93%;
  font-size: 22px;
  font-weight: bold;
  text-align: left;
  padding: 49px 0 0 14px;
  height: 50px;
}

.now-stu-level {
  width: 80%;
  height: 30px;
  text-align: left;
  margin-left: 10%;
}

.now-stu_level {
  width: 44px;
  height: 14px;
  background: -webkit-linear-gradient(left, #E2CCA6, #B29360);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0px 0 0 0px;
  font-size: 14px;
  font-weight: bold;
  vertical-align: top;
  display: inline-block;
  text-align: left;
}

.to-stu_level {
  width: 43px;
  height: 14px;
  padding: 93px 0 0 0rpx;
  color: #C2C2C2;
  font-size: 14px;
  font-weight: bold;
  vertical-align: top;
  display: inline-block;
  text-align: center;
}

.level-line {
  width: 148px;
  height: 5px;
  background: rgba(5, 5, 5, 0.14);
  border-radius: 6px;
  vertical-align: top;
  display: inline-block;
  margin: 5px 0 0 0;
}

.has-finish {
  height: 5px;
  background: linear-gradient(90deg, #E2CCA6 0%, #B29360 100%);
  vertical-align: top;
  display: inline-block;
}

.no-finish {
  height: 5px;
  vertical-align: top;
  display: inline-block;
}

.second-lable {
  position: fixed;
  top: 83px;
  left: 24px;
  color: #707070;
  font-size: 12px;
}

.plant-step {
  width: 300px;
  position: fixed;
  top: 108px;
}

.first-step {
  vertical-align: top;
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: #B4B4B4;
  border-radius: 18px;
  color: #FFF;
  text-align: center;
  line-height: 18px;
}

.first-step-ed {
  background-color: #003F88;
}

.step-name {
  width: 300px;
  position: fixed;
  top: 133px;
}

.step-item:first-child {
  text-align: left;
}

.step-item:last-child {
  text-align: right;
}

.step-item {
  width: 75px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  vertical-align: top;
  display: inline-block;
}

.center-line {
  vertical-align: top;
  display: inline-block;
  width: 71px;
  height: 3px;
  background: #B4B4B4;
  border-radius: 0px;
  margin-top: 9px;
}

.center-line-ed {
  background: #003F88;
}

.level-info {
  width: 93%;
  height: 264px;
  background: #FFFFFF;
  border-radius: 32px;
  position: absolute;
  top: 190px;
  left: 13px;
}
.level-info-top{
  width: 93%;
  height: 64px;
  background: #FFFFFF;
  border-radius: 32px;
}
.now-level {
  width: 160px;
  font-size: 20px;
  font-weight: bold;
  padding: 38px 0 0 23px;
  vertical-align: top;
  display: inline-block;
}

.restart-exam {
  width: 125px;
  height: 22px;
  background: #EFEFEF;
  border-radius: 11px 0px 0px 11px;
  vertical-align: top;
  display: inline-block;
  margin: 40px 0 0 60px;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
}

.level-has {
  width: 264px;
  height: auto;
  margin: 33px auto;
}

.level-has-item {
  width: 264px;
  height: 20px;
}

.level-lable {
  vertical-align: top;
  display: inline-block;
  color: #FFF;
  background-color: #26C541;
  width: 13px;
  height: 13px;
  border-radius: 13px;
  font-size: 13px;
  text-align: center;
  line-height: 13px;
  margin: 3px 5px 0 0;
}

.has-info {
  vertical-align: top;
  display: inline-block;
  font-size: 12px;
  text-align: left;
  line-height: 20px;
}

.set-object {
  width: 288px;
  height: 45px;
  background: #003F88;
  border-radius: 23px;
  color: #FFF;
  margin: 0 auto;
  text-align: center;
  line-height: 45px;
  font-size: 14px;
}

.today-task {
  width: 93%;
  height: 273px;
  background: #FFFFFF;
  border-radius: 32px;
  background: #FFFFFF;
  position: absolute;
  top: 196px;
  left: 13px;
}

.content-today-count {
  width: 60%;
  height: 70px;
  padding-left: 25px;
}

.today-count-item {
  width: 75px;
  height: 89px;
  vertical-align: top;
  display: inline-block;
  text-align: center;
}

.count-one {
  color: #000000;
  font-size: 22px;
  display: inline-block;
  text-align: center;
  /* padding-top: 64rpx; */
}

.count-two {
  color: #000000;
  font-size: 12px;
  display: inline-block;
  text-align: center;
  padding-top: 16px;
}

.count-title {
  color: #5B5B5B;
  font-size: 10px;
  /* text-align: center; */
  /* margin-top: 18rpx; */
}

.left {
  width: 50px;
  vertical-align: top;
  display: inline-block;
  margin-right: 115px;
}

.right {
  width: 50px;
  vertical-align: top;
  display: inline-block;
}

.left-title,
.right-title {
  color: #959595;
  font-size: 12px;
  text-align: left;
}

.left-num {
  color: #000;
  font-size: 25px;
  font-weight: bold;
  padding: 4px 0;
  text-align: left;
}

.left-bottom {
  width: 36px;
  height: 5px;
  background: #D0F0FC;
  border-radius: 0px;
}

.right-bottom {
  width: 36px;
  height: 5px;
  background: #F6D8CB;
  border-radius: 0px;
}
.learn-bottom{
  width: 100%;
  height: auto;
  position: absolute;
  top: 490px;
  text-align: left;
}
.model-title {
  vertical-align: top;
  display: inline-block;
  width: 110px;
  text-align: left;
  font-size: 16px;
  padding-left: 23px;
}
.learn-item{
  background: linear-gradient(to right, #FFF, #DEEFFF);
  width: 330px;
  height: 85px;
  position: relative;
  /* top: 500px; */
  margin: 45px auto;
  border-radius: 40px;
}
.item-left{
  vertical-align: top;
  display: inline-block;
  width: 175px;
  height: 85px;
}
.item-left-title{
  font-size: 18px;
  font-weight: bold;
  color: #003F88;
  padding: 18px 0 0 36px;
}
.item-left-finish{
  font-size: 10px;
  color: #BEA272;
  padding: 4px 0 0 36px;
}
.item-left-finish-box{
  background-color: #CECECE;
  width: 140px;
  height: 5px;
  margin: 4px 0 0 36px;
}
.item-right-img{
  width: 55px;
  height: 55px;
  padding-top: 17px;
  padding-left: 80px;
  vertical-align: top;
  display: inline-block;
}
.item-right-btn{
  width: 82px;
  height: 29px;
  margin-top: 29px;
  margin-left: 60px;
  vertical-align: top;
  display: inline-block;
  background-color: #B8B8B8;
  border-radius: 31px;
  text-align: center;
  line-height: 29px;
  color: #FFF;
  font-size: 12px;
}

</style>