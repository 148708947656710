import request from './index'

// 获取统计信息
export function getPlant(sid) {
    return request({
        url: '/plan/get',
        method: 'get',
        params: { sid }
    })
}

// 获取统计信息
export function getDateList(data) {
    return request({
        url: '/user_sign/getDateList',
        method: 'post',
        data
    })
}

// 获取首页快捷菜单
export function getMenuList() {
    return request({
        url: '/subject/getCategoryList',
        method: 'get',
    })
}


// 获取首页正在学
export function getNewUserResourceList(sid) {
    return request({
        url: '/resources/getNewUserResourceList',
        method: 'get',
        params: { sid }
    })
}

// 获取首页推荐学
export function HotResouceList(sid) {
    return request({
        url: '/resources/getHotUserResourceList',
        method: 'get',
        params: { sid }
    })
}