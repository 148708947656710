import { render, staticRenderFns } from "./WritePraxisAi.vue?vue&type=template&id=dcccf04a&scoped=true"
import script from "./WritePraxisAi.vue?vue&type=script&lang=js"
export * from "./WritePraxisAi.vue?vue&type=script&lang=js"
import style0 from "./WritePraxisAi.vue?vue&type=style&index=0&id=dcccf04a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcccf04a",
  null
  
)

export default component.exports