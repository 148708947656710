<template>
    <div class="active_list">
        <van-nav-bar title="学习列表" left-text="返回" left-arrow @click-left="onClickLeft" />
        <van-tabs v-model="active" color="#6FDBFF">
            <van-tab title="正在学">
                <div class="model-start">

                    <div class="learn-item" v-for="(item, index) in NewResouceList" :key="index"
                        @click="to_active(item)">
                        <img :src="item.cover" mode="aspectFill" class="learn-img" />
                        <div class="learn-item-info">
                            <div class="learn-item-title">
                                <span v-if="item.category_id == 1">[听力]</span>
                                <span v-if="item.category_id == 2">[阅读]</span>
                                <span v-if="item.category_id == 4">[写作]</span>
                                {{ item.name }}
                            </div>
                            <div class="learn-item-update-time">{{ item.create_time }}更新</div>
                        </div>
                        <div class="btn-learn">继续学习</div>
                    </div>
                </div>

            </van-tab>
            <van-tab title="推荐学">
                <div class="model-start">
                    <div class="learn-item" v-for="(item, index) in HotResouceList" :key="index">
                        <img :src="item.cover" mode="aspectFill" class="learn-img" />
                        <div class="learn-item-info">
                            <div class="learn-item-title">
                                <span v-if="item.category_id == 1">[听力]</span>
                                <span v-if="item.category_id == 2">[阅读]</span>
                                <span v-if="item.category_id == 4">[写作]</span>
                                {{ item.name }}
                            </div>
                            <div class="learn-item-update-time">{{ item.create_time }}更新</div>
                        </div>
                        <div class="btn-learn">进入学习</div>
                    </div>
                </div>
            </van-tab>
            <van-tab title="已完成">
                <div class="model-start">
                    <div class="learn-item" v-for="(item, index) in NewResouceList" :key="index"
                        @click="to_active(item)">
                        <img :src="item.cover" mode="aspectFill" class="learn-img" />
                        <div class="learn-item-info">
                            <div class="learn-item-title">
                                <span v-if="item.category_id == 1">[听力]</span>
                                <span v-if="item.category_id == 2">[阅读]</span>
                                <span v-if="item.category_id == 4">[写作]</span>
                                {{ item.name }}
                            </div>
                            <div class="learn-item-update-time">{{ item.create_time }}更新</div>
                        </div>
                        <div class="btn-learn">继续学习</div>
                    </div>
                </div>
            </van-tab>
        </van-tabs>
    </div>
</template>
<script>
import { getNewUserResourceList, getHotUserResourceList } from '@/api/user'
export default {
    name: 'ActiveList',
    data() {
        return {
            HotResouceList: [],
            NewResouceList: [],
            check_type: 1,
            active: 0
        }
    },

    methods: {
        async onClickLeft() {
            history.back()
        },
        async to_active(item) {
            this.$router.push({ name: 'ActiveDetail', query: { active_id: item.active_id } })
        },
        async getHotUserResourceList() {
            try {
                const user = JSON.parse(localStorage.getItem('user'))
                console.log(user)
                if (user && user.sid) {

                    const response = await getHotUserResourceList({ sid: user.sid })
                    const data = response.data
                    this.HotResouceList = data
                    this.userInfo = user

                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        },

        async getNewUserResourceList() {
            try {
                const user = JSON.parse(localStorage.getItem('user'))
                console.log(user)
                if (user && user.sid) {
                    const response = await getNewUserResourceList({ sid: user.sid })
                    const data = response.data
                    this.NewResouceList = data

                } else {
                    console.error('用户未登录或没有找到sid')
                }
            } catch (error) {
                console.error('Login Failed:', error)
            }
        },

    },
    mounted() {
        this.active = this.$route.query.now_status;
        this.getNewUserResourceList()
        this.getHotUserResourceList()
    }
}
</script>
<style scoped>
.learn-item {
    width: 330px;
    height: 85px;
    border-style: solid;
    box-shadow: 0px 2px 10px 1px rgba(80, 143, 206, 0.1);
    border-width: 0px;
    margin: 11px auto;
    border-radius: 20px;
    background-color: #FFF;
}

.learn-img {
    vertical-align: top;
    display: inline-block;
    width: 58px;
    height: 58px;
    margin: 14x 10px 13px 13px;
}

.learn-item-info {
    vertical-align: top;
    display: inline-block;
    width: 190px;
    height: 50px;
    line-height: 25px;
    padding-top: 21px;
}

.learn-item-title {
    font-size: 14px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 20px;
}

.learn-item-update-time {
    font-size: 12px;
    color: #979797;
}

.btn-learn {
    vertical-align: top;
    display: inline-block;
    color: #003F88;
    font-size: 12px;
    height: 85px;
    line-height: 85px;
}
</style>