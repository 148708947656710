<template>
  <div class="report">
    <van-nav-bar title="写作报告" left-text="返回" left-arrow @click-left="onClickLeft" />

    <div class="answer-box">
      <div class="box-top">
        <div class="right-num">100</div>
        <div class="total-num">/{{ answer_info.jobAnswerList.score * 100 }}</div>
        <div class="report-desc">共100分，获得{{ answer_info.jobAnswerList.score * 100 }}</div>
      </div>
      <div class="bottom-line"></div>
      <div class="second-box">
        <div class="submit-time">交卷时间：{{ jobAnswerList.create_time }}</div>
        <div class="result">正确率：{{ right_bfb }}%</div>
      </div>
      <div class="result-box">
        <div class="result-item" @click="to_detail(0)">
          1
        </div>
      </div>
      <div class="box-bottom">
        <div class="wrong-desc">点击题目去看解析</div>
        <div class="lable-desc">
          <div class="cicle"></div>
          <div class="desc">未答</div>
        </div>
        <div class="lable-desc">
          <div class="cicle" style="background-color: #3CC57C;"></div>
          <div class="desc">答对</div>
        </div>
        <div class="lable-desc">
          <div class="cicle" style="background-color: #F85353;"></div>
          <div class="desc">答错</div>
        </div>
      </div>
    </div>
    <RelList :resource_id="resource_id" />
  </div>
</template>
<script>
import { jobAnswer } from '@/api/listen'
import RelList from "../../components/RelList";

export default {
  components: { RelList },
  name: 'Report',
  data() {
    return {
      answer_info: [],
      jobAnswerList: [],
      RelList: [],
      right_bfb: 0,
      resource_id: 0
    }
  },
  methods: {
    async onClickLeft() {
      history.back()
    },
    async getJobAnswer() {
      try {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user && user.sid) {
          const response = await jobAnswer({ sid: user.sid, resource_id: 1193 })
          const data = response.data
          var countTotal = data.countTotal
          var rightCountTotal = data.rightCountTotal
          var right_bfb = (rightCountTotal / countTotal) * 100
          right_bfb = right_bfb.toFixed(0)
          this.answer_info = data
          this.jobAnswerList = data.jobAnswerList[0]
          this.right_bfb = right_bfb
        } else {
          console.error('用户未登录或没有找到sid')
        }
      } catch (error) {
        console.error('Login Failed:', error)
      }
    },

    async to_detail(index) {
      this.$router.push({ name: 'WriteDetail', params: { resource_id: this.resource_id, job_id: index } })
    }
  },
  mounted() {
    // 在组件挂载后立即获取任务数据
    this.getJobAnswer()
    this.resource_id = this.$route.query.resource_id;
  }
}
</script>
<style scoped>
.report {
  width: 100vw;
  height: auto;
  background: linear-gradient(to bottom, #E2F2FF, #FFF);
}

.main-top {
  width: 100vw;
  height: auto;
  text-align: left;
}

.reback {
  padding: 20px 0 0 12px;
  vertical-align: top;
  display: inline-block;
}

.pagetitle {
  padding: 20px 0 0 150px;
  vertical-align: top;
  display: inline-block;
  font-size: 16px;
  color: #030303;
}

.answer-box {
  width: 90vw;
  height: auto;
  background: #FFFFFF;
  box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  margin: 30px auto;
  padding: 20px 0;
}

.box-top {
  width: 90vw;
  height: 50px;
  text-align: left;
}

.right-num {
  padding: 11px 0 0 30px;
  font-size: 30px;
  font-weight: bold;
  vertical-align: top;
  display: inline-block;
}

.total-num,
.report-desc {
  padding: 29px 0 0 0px;
  font-size: 12px;
  vertical-align: top;
  display: inline-block;
}

.report-desc {
  padding-left: 28px;
}

.bottom-line {
  width: 340px;
  height: 1px;
  margin: 13px auto;
  background-color: #E4E4E4;
}

.second-box {
  width: 320px;
  height: 25px;
  margin: 0 auto;
  font-size: 12px;
  color: #656565;
}

.submit-time {
  width: 240px;
  vertical-align: top;
  display: inline-block;
  text-align: left;
}

.result {
  width: 80px;
  vertical-align: top;
  display: inline-block;
  text-align: right;
}

.result-box {
  width: 320px;
  height: auto;
  margin: 0 auto;
  font-size: 12px;
  color: #656565;
  text-align: left;
}

.result-item,
.result-item-wrong {
  vertical-align: top;
  display: inline-block;
  width: 34px;
  height: 34px;
  border-radius: 34px;
  margin: 5px;
  background-color: #3CC57C;
  line-height: 34px;
  text-align: center;
  font-size: 14px;
  color: #FFF;
  font-weight: 400;
}

.result-item-wrong {
  background-color: #F85353;
}

.box-bottom {
  width: 320px;
  height: auto;
  margin: 12px auto;
  font-size: 12px;
  color: #656565;
  padding-bottom: 40rpx;
  text-align: left;
}

.wrong-desc {
  vertical-align: top;
  display: inline-block;
  padding-right: 30px;
}

.lable-desc {
  width: 60px;
  vertical-align: top;
  display: inline-block;
}

.cicle {
  vertical-align: top;
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #CBCBCB;
  border-radius: 12px;
}

.desc {
  /* width: 12px; */
  color: #656565;
  font-size: 10px;
  line-height: 15px;
  padding: 0 15px 0 5px;
  vertical-align: top;
  display: inline-block;
}

.about-send {
  width: 90vw;
  height: auto;
  margin: 28px auto 0 auto;
  text-align: left;
}

.title {
  font-size: 16px;
  padding-bottom: 7px;
}

.about-item {
  width: 100%;
  height: 85px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 1px rgba(80, 143, 206, 0.1);
  border-radius: 20px;
  margin-bottom: 10px;
}

.item-cover {
  vertical-align: top;
  display: inline-block;
  width: 58px;
  height: 58px;
  border-radius: 10px;
  margin: 14px 6px 0 12px;
}

.item-info {
  width: 220px;
  vertical-align: top;
  display: inline-block;
  padding-top: 21px;
}

.info-title {
  font-size: 14px;
  font-weight: bold;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.info-time {
  color: #979797;
  font-size: 12px;
  padding-top: 15px;
}

.con-to-study {
  width: 60px;
  height: 85px;
  vertical-align: top;
  display: inline-block;
  line-height: 85px;
  font-size: 12px;
  color: #003F88;
  text-align: center;
}
</style>