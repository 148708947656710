<template>
  <div class="ai">
    <div class="top-title">AI伴学</div>
    <div class="second-lable">慧学见证你的每一个进步瞬间</div>
    <div class="etalk-click" @click="to_etalk">
      <van-image fit="contain" src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/e-talk/e-talk.png" />
    </div>
    <div class="eread-click" @click="to_read">
      <van-image fit="contain" src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/e-talk/e-read.png" />
    </div>
    <div class="ewrite-click" @click="to_write">
      <van-image fit="contain" src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/e-talk/e-write.png" />
    </div>
    <div class="elisten-click" @click="to_listen">
      <van-image fit="contain" src="https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/e-talk/e-listen.png" />
    </div>
  </div>
</template>
<script>

export default {
  name: 'Ai',
  data() {
    return {

    }
  },
  methods: {
    async to_etalk() {
      this.$router.push('/etalk/index')
    },
    async to_read() {
      this.$router.push('/read/index')
    },
    async to_write() {
      this.$router.push('/write/index')
    },
    async to_listen() {
      this.$router.push('/listen/index')
    }
  },
  mounted() {
    // 在组件挂载后立即获取任务数据

  }
}
</script>
<style scoped>
/* pages/etalk/index.wxss */
.ai {
  width: 100vw;
  height: 100vh;
  background-image: url('https://a-news.oss-cn-hangzhou.aliyuncs.com/zheda/public/img/index/ai-study.png?v2');
  background-size: cover;
  /* overflow: hidden; */
  background-repeat: no-repeat;
  /* padding-bottom: 300rpx; */
}

.top-title {
  position: fixed;
  top: 49px;
  left: 25px;
  font-size: 22px;
  font-weight: bold;
}

.second-lable {
  position: fixed;
  top: 83px;
  left: 24px;
  color: #707070;
  font-size: 12px;
}

.etalk-click {
  width: 120px;
  height: 120px;
  /* background-color: lightblue; */
  position: fixed;
  top: 240px;
  left: 60px;
  border-radius: 120px;
}

.eread-click {
  width: 70px;
  height: 70px;
  /* background-color: rgb(124, 233, 35); */
  position: fixed;
  top: 465px;
  left: 50px;
  border-radius: 70px;
}

.ewrite-click {
  width: 70px;
  height: 70px;
  /* background-color: rgb(143, 25, 240); */
  position: fixed;
  top: 600px;
  left: 40px;
  border-radius: 70px;
}

.elisten-click {
  width: 70px;
  height: 70px;
  /* background-color: lightblue; */
  position: fixed;
  top: 710px;
  left: 100px;
  border-radius: 70px;
}
</style>