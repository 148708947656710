<!-- src/layouts/NormalLayout.vue -->

<template>
    <div class="normal-layout">
      <!-- 主内容区域 -->
      <router-view></router-view>
    </div>
  </template>
  
  <script>
  export default {
    // 可以在此添加普通页面布局的逻辑和样式
  }
  </script>
  
  <style scoped>
  .normal-layout {
    position: relative;
    height: 100%;
  }
  
  /* 可以添加普通页面布局的样式 */
  </style>
  